import React, { useState, useEffect, useRef } from "react";
import {
  Div,
  Button,
  Table,
  TableHead,
  TableHeadCell,
  TableBody,
  TableBodyCell,
  Input,
  SpanTag,
  SearchIconSVG,
  Tablerow,
  Image,
  Spinner,
  SpinnerWrapper,
  DropDownDiv,
  DropdownOptions,
  SelectInput,
} from "../../StyledComponents";
import { TableDiv, DropdownImage } from "./styles";
import { CSVLink } from "react-csv";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { AiOutlineSearch, AiOutlineMail } from "react-icons/ai";
import { BsTelephoneFill, BsFillCameraVideoFill } from "react-icons/bs";
import { BiMessage } from "react-icons/bi";
import Pagination from "../../StyledComponents/Pagination/Pagination";
import UpandDownArrow from "../../../Assets/images/UpandDownArrow.png";
import AscendingArrow from "../../../Assets/images/AscendingArrow.png";
import DescendingArrow from "../../../Assets/images/DescendingArrow.png";
import Emp_data_pic from "../../../Assets/images/No_Data_Img.png";
import Logo from "../../../Assets/images/Logo.png";
import ChatPopup from "../../Popup/ChatPopup/ChatPopup";
import {
  GetPatDetails,
  GetSinglePatDetail,
  SetSinglePatDetail,
  getExportStatus,
} from "../../../StateManagement/Reducers/PatientState";
import { emptyPreviewDowload } from "../../../StateManagement/Reducers/PatientDemoState";
import { Decrypt_Value } from "../../../MiddleWare/EncryptDecrypt";
import { PaginDiv } from "./styles";
import dropdownDownicon from "../../../Assets/images/dropdownDownicon.png";
import AlertModal from "../../StyledComponents/AlertModal/AlertModal";
import UploadDocument from "../../StyledComponents/UploadDocument";

const PatientDemographicsSheet = () => {
  //-------------------------------------------State and Variables Start-------------------------------------------------------- //

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const listRef = useRef();
  const [chatShow, setChatShow] = useState(false);
  const [patSearchValue, setPatSearchValue] = useState("");
  const [ShowOptions, setShowOptions] = useState(false);
  const [listvalue, setListvalue] = useState("");
  const [active, setActive] = useState(2);
  const [arrowImg, setArrowImg] = useState(() => {
    const initialImg = UpandDownArrow;
    const imgCount = 7;
    const initialImgState = {};

    for (let i = 1; i <= imgCount; i++) {
      initialImgState[`img_${i}`] = initialImg;
    }

    return initialImgState;
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [dataLoaded, setDataLoaded] = useState(false);

  const patientList = useSelector((state) => state.Patient.PatientDetails);
  const isLoading = useSelector((state) => state.Patient.Loading);
  const [chatDetails, setChatDetails] = useState(null);
  const [currentPosts, setCurrentPost] = useState([]);
  const [howManyPages, setHowManyPages] = useState(0);
  const [patColumnName, setPatColumnName] = useState("");
  const [patSortName, setPatSortName] = useState("");
  const [modalMsg, setModalMsg] = useState("");
  const [ModalAlerShow, setModalAlerShow] = useState(false);
  const [status, setStatus] = useState("");
  const [impCsvShow, setImpCsvShow] = useState(false);
  const [sortState, setSortState] = useState("");
  const provider_id =
    localStorage.getItem("user_id") &&
    Decrypt_Value(localStorage.getItem("user_id"), "vozorpm");

  const providerName = useSelector(
    (state) => state.Login.ProviderDetails["providerName"]
  );
  const providerImg = useSelector(
    (state) => state.Login.ProviderDetails["img_url"]
  );
  const permissions =
    localStorage.getItem("permissions") &&
    Decrypt_Value(localStorage.getItem("permissions"), "vozorpm");
  //-------------------------------------------State and Variables End-------------------------------------------------------- //

  // ------------------------------------------ Functions Start--------------------------------------------------------- //
  const location = useLocation();
  const handleMessage = (e, details) => {
    if (!permissions.includes("message_access")) {
      accessDeniedFunc(e);
    } else {
      setChatShow((prevState) => !prevState);
      setChatDetails(details);
    }
  };
  const export_patientdata = () => {
    dispatch(getExportStatus({ active: active, uid: provider_id }));
  };

  const handleCloseChat = () => {
    setChatShow(false);
    dispatch(emptyPreviewDowload(""));
  };

  const handleButtonClick = (pid, status) => {
    localStorage.removeItem("current_patientdemo_timer");
    dispatch(GetSinglePatDetail(pid));
    setTimeout(() => {
      switch (status) {
        case "New":
          navigate(`/PatientEligibility/${pid}`);
          break;
        case "Verification Pending":
          navigate(`/PatientConsent/${pid}`);
          break;
        case "Verification Pending Setup":
          navigate(`/PatientPortalSetup/${pid}`);
          break;
        case "Enrolled":
          navigate(`/PatientDemo/${pid}`);
          break;
        case "Newportal":
          navigate(`/PatientEnrollment/${pid}`);
          break;
        case "Unenrolled":
          navigate(`/PatientDemo/${pid}`);
          break;
        case "Skipverify":
          navigate(`/PatientEligibility/${pid}`);
        default:
          break;
      }
    }, 500);
  };

  const openEnrollment = () => {
    navigate(`/PatientEnrollment/${"0"}`);
    dispatch(SetSinglePatDetail({}));
  };

  const handlePatientSearch = (event) => {
    setCurrentPage(1);
    let value = event.target.value;
    setPatSearchValue(value);
    setArrowImg({
      ...arrowImg,
      ...resetImagesToInitialState(),
    });
  };

  const resetImagesToInitialState = () => {
    const initialImgState = {};

    for (let i = 1; i <= 7; i++) {
      initialImgState[`img_${i}`] = UpandDownArrow;
    }

    return initialImgState;
  };

  const hanldeArrowImg = (index, columnName) => {
    setPatColumnName(columnName);
    let sortOrder = "";

    if (arrowImg[`img_${index}`] === UpandDownArrow) {
      setArrowImg({
        ...arrowImg,

        ...resetImagesToInitialState(),
        [`img_${index}`]: AscendingArrow,
      });
      setPatSortName("asc");
      sortOrder = "asc";
    } else if (arrowImg[`img_${index}`] === AscendingArrow) {
      setArrowImg({
        ...arrowImg,

        ...resetImagesToInitialState(),
        [`img_${index}`]: DescendingArrow,
      });
      sortOrder = "desc";
      setPatSortName("desc");
    } else if (arrowImg[`img_${index}`] === DescendingArrow) {
      setArrowImg({
        ...arrowImg,

        ...resetImagesToInitialState(),
        [`img_${index}`]: UpandDownArrow,
      });
    }
    setSortState(sortOrder);

    dispatch(
      GetPatDetails({
        column_name: columnName,
        sort_order: sortOrder,
        search_key: patSearchValue,
        page: currentPage,
        pageSize: 10,
        active: active,
        uid: provider_id,
      })
    );
  };

  const listpatient = (value) => {
    localStorage.removeItem("fromNavigation");
    setCurrentPage(1);
    switch (value) {
      case 1:
        setActive(1);
        setListvalue("Active patients");
        setShowOptions(false);
        break;
      case 2:
        setActive(0);
        setListvalue("Inactive patients");
        setShowOptions(false);
        break;
      case 3:
        setActive(2);
        setListvalue("All patients");
        setShowOptions(false);
        break;
      case 4:
        setActive(3);
        setListvalue("My patients");
        setShowOptions(false);
        break;
      default:
        setActive(2);
        setListvalue("All patients");
        setShowOptions(false);
    }
  };

  const accessDeniedFunc = (e) => {
    e.preventDefault();
    setModalMsg("Permission Denied");
    setStatus("Failed");
    setModalAlerShow(true);
    setTimeout(() => {
      setModalAlerShow(false);
      setStatus("");
      setModalMsg("");
    }, 1500);
  };

  const handleImpCsvClose = () => {
    setImpCsvShow(false);
  };

  // ----------------------------------------- Functions End--------------------------------------------------------- //

  // ----------------------------------------- UseEffect Start ------------------------------------------------------- //

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      const fromNavigation = localStorage.getItem("fromNavigation") === "true";
      if (fromNavigation) {
        dispatch(
          GetPatDetails({
            column_name: "",
            sort_order: "",
            search_key: "",
            page: currentPage,
            pageSize: 10,
            active: 3,
            uid: provider_id,
          })
        );
        setListvalue("My patients");
      } else {
        dispatch(
          GetPatDetails({
            column_name: patColumnName,
            sort_order: patSortName,
            search_key: patSearchValue,
            page: currentPage,
            pageSize: 10,
            active: active,
            uid: provider_id,
          })
        );
      }
    }, 300);
    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
      clearTimeout(delayDebounceFn);
    };
  }, [location, patSearchValue, currentPage, active]);

  const handleBeforeUnload = () => {
    localStorage.removeItem("fromNavigation");
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    setTimeout(() => {
      setDataLoaded(true);
    }, 1000);
  }, []);

  useEffect(() => {
    if (patientList) {
      setCurrentPost(patientList.data ?? []);
      setHowManyPages(patientList.totalPages ?? 0);
    }
  }, [patientList]);
  useEffect(() => {
    const handleOutsideClick = async (event) => {
      const container = listRef.current;
      if (container && !container.contains(event.target)) {
        if (ShowOptions === true) {
          setShowOptions(false);
          event.stopPropagation();
        }
      }
    };
    document.addEventListener("click", handleOutsideClick, true);
    return () => {
      document.removeEventListener("click", handleOutsideClick, true);
    };
  }, [ShowOptions]);

  // ----------------------------------------- UseEffect End ------------------------------------------------------- //

  return (
    // isLoading ?
    //   <SpinnerWrapper>
    //     <Spinner />
    //   </SpinnerWrapper>
    // ) :

    <>
      <Div
        display="flex"
        marginTop="-4px"
        paddingBottom="16px"
        backgroundColor="#F1F5F8"
        className="PatListShow"
        fontFamily="Open Sans, sans-serif"
        fontWeight="600"
        fontSize="18px"
        alignItems="center"
      >
        <Div>Patient List</Div>

        <Button
          cursor="pointer"
          marginLeft="auto"
          padding="8px"
          border="1px solid #2C7BE5"
          borderRadius="4px"
          color="#FFFFFF"
          background="#2C7BE5"
          fontFamily="Open Sans, sans-serif"
          fontWeight="600"
          fontSize="14px"
          onClick={openEnrollment}
          backgroundColor="#2C7BE5"
          hoverBackColor="#005FB2"
          activeBackgroundColor="rgba(44, 123, 229, 0.75)"
        >
          Add Patient
        </Button>
      </Div>
      <Div backgroundColor="#FFFFFF" borderRadius="8px">
        <Div
          display="flex"
          paddingTop="26px"
          paddingBottom="16px"
          paddingLeft="20px"
          paddingRight="20px"
          border="1px solid #E2E8ED"
          borderBottom="0px"
          position="relative"
          borderTopLeftRadius="8px"
          borderTopRightRadius="8px"
        >
          <Div display="flex" position="relative" width="70%">
            <SearchIconSVG>
              <AiOutlineSearch color="rgba(46, 46, 46, 0.5)" fontSize="18px" />
            </SearchIconSVG>

            <Input
              type="search"
              name="search"
              placeholder="Search for Patient"
              padding="10px"
              border="1px solid rgba(46, 46, 46, 0.25)"
              borderRadius="4px"
              width="39%"
              paddingLeft="32px"
              color="rgba(46, 46, 46, 0.5)"
              fontFamily="Open Sans, sans-serif"
              fontWeight="400"
              fontSize="14px"
              position="absolute"
              value={patSearchValue}
              onChange={handlePatientSearch}
              autoComplete
              className={patSearchValue ? "hide-icon" : ""}
            />
            <Div position="relative" ref={listRef}>
              <SelectInput
                placeholder=" 'Select '"
                width="200px"
                height="36px"
                background="#FFFFFF"
                fontSize="15px"
                fontFamily="'Open Sans',sans-serif"
                border="1px solid rgba(46, 46, 46, 0.25)"
                borderRadius="4px"
                color="#2E2E2E"
                paddingTop="8px"
                pl="10px"
                padding="6px 9px 6px 15px"
                margin="0px 0px 0px 24px"
                onClick={() => setShowOptions(!ShowOptions)}
              >
                {listvalue}
              </SelectInput>
              <DropdownImage
                top="16px"
                right="10px"
                src={dropdownDownicon}
              ></DropdownImage>
              {ShowOptions && (
                <DropDownDiv
                  width="198px"
                  height="auto"
                  background="#FFFFFF"
                  fontFamily="'Open Sans',sans-serif"
                  border=" 1px solid rgba(46, 46, 46, 0.25)"
                  borderRadius="4px"
                  margin="5px 0 0 26px"
                >
                  <DropdownOptions
                    fontFamily="'Open Sans',sans-serif"
                    color="#2E2E2E"
                    fontSize="14px"
                    lineHeight="21.643px"
                    padding="7px 8px 8px 15px"
                    onClick={() => listpatient(4)}
                  >
                    My patients
                  </DropdownOptions>
                  <DropdownOptions
                    fontFamily="'Open Sans',sans-serif"
                    color="#2E2E2E"
                    fontSize="14px"
                    lineHeight="21.643px"
                    padding="7px 8px 8px 15px"
                    onClick={() => listpatient(3)}
                  >
                    All patients
                  </DropdownOptions>
                  <DropdownOptions
                    fontFamily="'Open Sans',sans-serif"
                    color="#2E2E2E"
                    fontSize="14px"
                    lineHeight="21.643px"
                    padding="8px 8px 8px 15px"
                    onClick={() => listpatient(1)}
                  >
                    Active patients
                  </DropdownOptions>
                  <DropdownOptions
                    fontFamily="'Open Sans',sans-serif"
                    color="#2E2E2E"
                    fontSize="14px"
                    lineHeight="21.643px"
                    padding="8px 8px 14px 15px"
                    onClick={() => listpatient(2)}
                  >
                    Inactive patients
                  </DropdownOptions>
                </DropDownDiv>
              )}
            </Div>
          </Div>
          <Div marginLeft="auto" display="flex" marginRight="0px">
            <Button
              cursor="pointer"
              color="#2C7BE5"
              border="1px solid #2C7BE5"
              borderRadius="4px"
              background="#FFFFFF"
              fontFamily="Open Sans, sans-serif"
              fontWeight="600"
              fontSize="14px"
              padding="6px"
              height="36px"
              marginRight="20px"
              display="flex"
              alignItems="center"
              backgroundColor="#FFF"
              hoverBackColor="rgba(244, 246, 249, 0.75)"
              activeBackgroundColor="rgba(44, 123, 229, 0.10)"
              onClick={(e) =>
                !permissions.includes("export_tool")
                  ? accessDeniedFunc(e)
                  : export_patientdata()
              }
            >
              Export csv
            </Button>
            <Button
              cursor="pointer"
              color="#2C7BE5"
              border="1px solid #2C7BE5"
              borderRadius="4px"
              background="#FFFFFF"
              fontFamily="Open Sans, sans-serif"
              fontWeight="600"
              fontSize="14px"
              padding="6px"
              height="36px"
              display="flex"
              alignItems="center"
              backgroundColor="#FFF"
              hoverBackColor="rgba(244, 246, 249, 0.75)"
              activeBackgroundColor="rgba(44, 123, 229, 0.10)"
              onClick={(e) =>
                !permissions.includes("export_tool")
                  ? accessDeniedFunc(e)
                  : setImpCsvShow(true)
              }
            >
              Import csv
            </Button>
          </Div>
        </Div>

        <TableDiv overflowX="auto" className="TableLayout">
          <Table
            borderBottomLeftRadius={
              currentPosts.length === 0 || howManyPages === 1 ? "8px" : "0px"
            }
            borderBottomRightRadius={
              currentPosts.length === 0 || howManyPages === 1 ? "8px" : "0px"
            }
          >
            <TableHead background="#F1F5F8">
              <Tablerow
                color="#2C7BE5"
                fontFamily="Open Sans, sans-serif"
                fontWeight="400"
                fontSize="14px"
              >
                <TableHeadCell>
                  <SpanTag
                    display="flex"
                    paddingLeft="16px"
                    width="128px"
                    gap="4px"
                  >
                    Patient Name
                    <Image
                      color="#2C7BE5"
                      paddingLeft="2px"
                      cursor="pointer"
                      onClick={() => hanldeArrowImg(1, "fname")}
                      src={arrowImg.img_1}
                      alt="arrow"
                    />
                  </SpanTag>
                </TableHeadCell>
                <TableHeadCell>
                  <SpanTag display="flex" width="118px" gap="4px">
                    Patient ID
                    <Image
                      color="#2C7BE5"
                      paddingLeft="2px"
                      cursor="pointer"
                      onClick={() => hanldeArrowImg(2, "id")}
                      src={arrowImg.img_2}
                      alt="arrow"
                    />
                  </SpanTag>
                </TableHeadCell>
                <TableHeadCell>
                  <SpanTag display="flex" width="118px" gap="4px">
                    DOB
                    <Image
                      color="#2C7BE5"
                      cursor="pointer"
                      onClick={() => hanldeArrowImg(3, "DOB")}
                      src={arrowImg.img_3}
                      alt="arrow"
                    />
                  </SpanTag>
                </TableHeadCell>
                <TableHeadCell width="160px" paddingLeft="2px">
                  <SpanTag
                    display="flex"
                    paddingLeft="0px"
                    width="138px"
                    gap="4px"
                  >
                    Phone Number
                    <Image
                      color="#2C7BE5"
                      paddingLeft="2px"
                      cursor="pointer"
                      onClick={() => hanldeArrowImg(4, "phone_cell")}
                      src={arrowImg.img_4}
                      alt="arrow"
                    />
                  </SpanTag>
                </TableHeadCell>
                <TableHeadCell paddingLeft="2px">
                  <SpanTag
                    display="flex"
                    paddingLeft="0px"
                    width="118px"
                    gap="4px"
                  >
                    Email
                    <Image
                      color="#2C7BE5"
                      paddingLeft="3px"
                      cursor="pointer"
                      onClick={() => hanldeArrowImg(5, "email")}
                      src={arrowImg.img_5}
                      alt="arrow"
                    />
                  </SpanTag>
                </TableHeadCell>
                <TableHeadCell paddingLeft="36px">
                  <SpanTag display="flex" width="110px" gap="4px">
                    Program
                    <Image
                      color="#2C7BE5"
                      paddingLeft="3px"
                      cursor="pointer"
                      onClick={(program) => hanldeArrowImg(6, "")}
                      src={arrowImg.img_6}
                      alt="arrow"
                    />
                  </SpanTag>
                </TableHeadCell>
                <TableHeadCell paddingLeft="4px">
                  <SpanTag display="flex" width="152px" gap="4px">
                    Enrollment Status
                    <Image
                      color="#2C7BE5"
                      cursor="pointer"
                      onClick={() => hanldeArrowImg(7, "e_status")}
                      src={arrowImg.img_7}
                      alt="arrow"
                    />
                  </SpanTag>
                </TableHeadCell>
              </Tablerow>
            </TableHead>
            <TableBody>
              {currentPosts.length !== 0 ? (
                currentPosts.map((details, index) => (
                  <Tablerow
                    borderBottom="1px solid #E2E8ED"
                    fontFamily="Open Sans, sans-serif"
                    fontWeight="400"
                    fontSize="14px"
                    isHovered={true}
                    key={index}
                  >
                    <TableBodyCell
                      color="#2C7BE5"
                      onClick={() =>
                        handleButtonClick(details.pid, details.e_status)
                      }
                      paddingTop="20px"
                      paddingBottom="20px"
                      paddingLeft="36px"
                    >
                      {details.patname}
                    </TableBodyCell>
                    <TableBodyCell padding="20px 2px 20px 20px">
                      {details.patid}
                    </TableBodyCell>
                    <TableBodyCell padding="20px 2px 20px 20px">
                      {details.DOB}
                    </TableBodyCell>
                    <TableBodyCell padding="20px 2px 20px 0px">
                      {details.phone_cell}
                    </TableBodyCell>
                    <TableBodyCell padding="20px 0px 20px 0px">
                      {details.email}
                    </TableBodyCell>
                    <TableBodyCell padding="20px 2px 20px 36px">
                      RPM
                    </TableBodyCell>
                    <TableBodyCell padding="20px 2px 20px 4px">
                      <SpanTag
                        color={
                          details.e_status === "Unenrolled"
                            ? "rgba(46, 46, 46, 0.75)"
                            : details.e_status === "Enrolled"
                            ? "#198C55"
                            : details.e_status === "New" ||
                              details.e_status === "Newportal" ||
                              details.e_status === "Skipverify"
                            ? "#F24822"
                            : details.e_status === "Verification Pending" ||
                              "Verification Pending Setup"
                            ? "#FFC700"
                            : "-"
                        }
                      >
                        {details.e_status === "Newportal" ||
                        details.e_status === "Skipverify"
                          ? "New"
                          : details.e_status === "Verification Pending Setup"
                          ? "Verification Pending"
                          : details.e_status}
                      </SpanTag>
                    </TableBodyCell>
                  </Tablerow>
                ))
              ) : dataLoaded && currentPosts.length === 0 ? (
                <>
                  <Tablerow>
                    <TableBodyCell textAlign="center" colSpan="7">
                      <Div>
                        <Image
                          height="133px"
                          width="133px"
                          src={Emp_data_pic}
                        ></Image>
                      </Div>
                    </TableBodyCell>
                  </Tablerow>
                  <Tablerow>
                    <TableBodyCell
                      textAlign="center"
                      colSpan="8"
                      paddingBottom="40px"
                    >
                      No patients has been added to show in the patient list
                    </TableBodyCell>
                  </Tablerow>
                </>
              ) : (
                <SpinnerWrapper>
                  <Spinner></Spinner>
                  <Image
                    width="40px"
                    height="40px"
                    position="absolute"
                    src={Logo}
                  />
                </SpinnerWrapper>
              )}
            </TableBody>
          </Table>
        </TableDiv>
        <PaginDiv
          hidePagination={currentPosts.length === 0 || howManyPages === 1}
        >
          {currentPosts.length === 0 || howManyPages === 1 ? (
            ""
          ) : (
            <Pagination
              current={currentPage}
              pages={howManyPages}
              setCurrentPage={setCurrentPage}
            />
          )}
        </PaginDiv>
      </Div>
      {impCsvShow && (
        <UploadDocument
          show={impCsvShow}
          close={handleImpCsvClose}
          column_name={patColumnName}
          sort_order={sortState}
          search_key={patSearchValue}
          page={currentPage}
          active={active}
        />
      )}
      {chatShow && (
        <ChatPopup
          patpic={chatDetails && chatDetails.img_url}
          patName={chatDetails && chatDetails.patname}
          pid={chatDetails && chatDetails.pid}
          Provider_Id={provider_id}
          profileimage={providerImg}
          providerName={providerName}
          handleCloseChat={handleCloseChat}
          chatShow={chatShow}
        />
      )}
      {ModalAlerShow && (
        <AlertModal show={ModalAlerShow} msg={modalMsg} status={status} />
      )}
    </>
  );
};
export default PatientDemographicsSheet;
