import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import Messages from "../Messages";
import { DropdownContainer, DowloadBtn } from "../Messages/styles";
import Chat from "../Chat";
import { Decrypt_Value } from "../../../MiddleWare/EncryptDecrypt";
import {
  getMessageList,
  sendMessage,
  getPreviewFile,
  getSingleMessage,
  clearPreview,
  setSingleMessage,
} from "../../../StateManagement/Reducers/MessengerState.js";
import { GetPatDetails } from "../../../StateManagement/Reducers/PatientState";

// import SendIcon from "../../assets/images/SendButton.png";
import Microphone_Start from "../../../Assets/images/Microphone_Start.png";
import Microphone_Pause from "../../../Assets/images/MicroPhone_Pause.png";
import Microphone_End from "../../../Assets/images/MicroPhone_End.png";
import audioPreviewButton from "../../../Assets/images/Circled Play.png";
import { FiTrash2 } from "react-icons/fi";
import {
  Row,
  Image,
  Text,
  Close,
  SearchInputText,
  Attach,
  Circle,
  FiSendIcon,
  AtSymbol,
} from "../Messages/styles";
import DocType from "../../../Assets/images/docType.png";
import AlertModal from "../../StyledComponents/AlertModal/AlertModal";
import RecordingStatus from "./RecordingStatus.js";
import webmFixDuration from "webm-duration-fix";
import { Div, Tooltip, TooltipText } from "./styles.js";

const SecureMessage = ({ popupRef, setShowValue }) => {
  // ----------------------------State and Variables ---------------------------------------------------///
  const dispatch = useDispatch();
  const user_id =
    localStorage.getItem("user_id") &&
    Decrypt_Value(localStorage.getItem("user_id"), "vozorpm");
  const hiddenFileInput = React.useRef(null);

  const [chat, setChat] = useState(false);
  const [pid, setPid] = useState("");
  const [patName, setPatName] = useState("James Marry ");
  const [patpic, setPatpic] = useState();
  const [selectedFile, setSelectedFile] = useState();
  const [imgPre, setImgPre] = useState(false);
  const [value, setValue] = useState("");
  const [viewType, setViewType] = useState("");
  const [ImgUrl, setImgUrl] = useState("");
  const [fileName, setFileName] = useState(null);
  const [preview, setPreview] = useState();
  const messageList = useSelector((state) => state.Messenger.messageList);
  const previewFile = useSelector((state) => state.Messenger.previewFile);
  const [secMsgData, setSecMsgData] = useState([]);
  const [Refresh, setRefresh] = useState(false);
  const ProviderDetails = useSelector((state) => state.Login.ProviderDetails);
  const [PreviewDownload, setPreviewDownload] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [status, setStatus] = useState("");
  const [modalMsg, setModalMsg] = useState("");

  const [isRecording, setIsRecording] = useState(false);
  const [audioURLs, setAudioURLs] = useState([]);
  const audioContext = useRef(null);
  const audioContext1 = useRef(null);
  const mediaRecorder = useRef(null);
  const mediaRecorder1 = useRef(null);
  const audioChunks = useRef([]);

  const audioChunks1 = useRef([]);
  const audioStream = useRef(null);
  const audioStream1 = useRef(null);
  const [isRecordingPaused, setIsRecordingPaused] = useState(false);
  const [audioURLs1, setAudioURLs1] = useState([]);
  const [recordingKey, setRecordingKey] = useState(0);
  const [one, setOne] = useState(false);
  const [two, setTwo] = useState(true);
  const [three, setThree] = useState(false);
  const [record, compRecord] = useState(false);
  const [delClick, setDelClick] = useState(false);

  // -------------------------State and Variables End ---------------------------------------------------///
  // -------------------------- Functions Start-------------------------------------------------------------//

  const startRecording = async () => {
    try {
      compRecord(false);
      if (!audioStream.current) {
        audioStream.current = await navigator.mediaDevices.getUserMedia({
          audio: true,
        });
      }
      audioChunks.current = [];
      audioContext.current = new (window.AudioContext ||
        window.webkitAudioContext)();
      // const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      mediaRecorder.current = new MediaRecorder(audioStream.current);
      setRecordingKey((prevKey) => prevKey + 1);
      mediaRecorder.current.ondataavailable = (event) => {
        if (event.data.size > 0) {
          audioChunks.current.push(event.data);
        }
      };
      const audioBlobs = [];
      mediaRecorder.current.onstop = async () => {
        const duration = Date.now();
        const audioBlob = new Blob(audioChunks.current, { type: "audio/wav" });
        audioBlobs.push(audioBlob);
        const fixedBlob = await webmFixDuration(audioBlob, duration);
        setAudioURLs1(URL.createObjectURL(audioBlob));

        audioChunks.current = [];
        handleOnSend(fixedBlob);
      };

      mediaRecorder.current.start();
      setIsRecording(true);
    } catch (error) {
      console.error("Error starting recording:", error);
    }
  };

  const startRecording1 = async () => {
    try {
      if (!audioStream1.current) {
        audioStream1.current = await navigator.mediaDevices.getUserMedia({
          audio: true,
        });
      }

      audioContext1.current = new (window.AudioContext ||
        window.webkitAudioContext)();
      // const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      mediaRecorder1.current = new MediaRecorder(audioStream1.current);

      mediaRecorder1.current.ondataavailable = (event) => {
        if (event.data.size > 0) {
          audioChunks1.current.push(event.data);
        }
      };

      mediaRecorder1.current.onstop = () => {
        const audioBlob = new Blob(audioChunks1.current, { type: "audio/wav" });

        const audioURL = URL.createObjectURL(audioBlob);
        setAudioURLs((prevAudioURLs) => [...prevAudioURLs, audioURL]);

        audioChunks1.current = [];
      };

      mediaRecorder1.current.start();

      setThree(false);
    } catch (error) {
      console.error("Error accessing microphone:", error);
    }
  };
  const EmptforAnotherProv = () => {
    setAudioURLs([]);
    setAudioURLs1([]);
    setOne(false);
    setTwo(true);
    setIsRecording(false);
    setIsRecordingPaused(false);
    setDelClick(true);
    audioContext.current = null;
    audioContext1.current = null;
    mediaRecorder.current = null;
    mediaRecorder1.current = null;
    audioChunks.current = [];
    audioChunks1.current = [];
    audioStream.current = null;
    audioStream1.current = null;
  };

  const pauseRecording = () => {
    if (mediaRecorder.current && mediaRecorder.current.state === "recording") {
      mediaRecorder.current.pause();
      setTwo(true);
      setIsRecording(false);
      setIsRecordingPaused(true);
    }
  };

  const pauseRecording1 = () => {
    if (
      mediaRecorder1.current &&
      mediaRecorder1.current.state === "recording"
    ) {
      mediaRecorder1.current.pause();
    }
  };

  const resumeRecording = () => {
    if (mediaRecorder.current && mediaRecorder.current.state === "paused") {
      mediaRecorder.current.resume();
      setIsRecording(true);
      setIsRecordingPaused(false);
    }
  };

  const resumeRecording1 = () => {
    if (mediaRecorder1.current && mediaRecorder1.current.state === "paused") {
      mediaRecorder1.current.resume();
    }
  };

  const stopRecording1 = () => {
    setThree(true);
    setOne(true);
    compRecord(true);
    if (
      mediaRecorder1.current &&
      (mediaRecorder1.current.state === "recording" ||
        mediaRecorder1.current.state === "paused")
    ) {
      mediaRecorder1.current.stop();
      audioStream1.current.getTracks().forEach((track) => {
        track.stop();
      });
      audioStream1.current = null;
      audioContext1.current.close();
    }
  };

  const stopRecording = () => {
    compRecord(true);
    if (three && audioURLs.length === 0) {
      return;
    }

    if (delClick) {
      audioStream.current = null;
    }
    if (
      mediaRecorder.current &&
      (mediaRecorder.current.state === "recording" ||
        mediaRecorder.current.state === "paused")
    ) {
      mediaRecorder.current.stop();
      audioStream.current !== null &&
        audioStream.current.getTracks().forEach((track) => {
          track.stop();
        });
      audioStream.current = null;
      audioContext.current.close();
      setIsRecording(false);
      setIsRecordingPaused(false);
    }
    setTimeout(() => {
      // setAudioURLs([]);
      setOne(false);
    }, 1500);
  };

  function getAudioBlobDuration(audioBlob) {
    return new Promise((resolve, reject) => {
      const audioContext = new (window.AudioContext ||
        window.webkitAudioContext)();
      const fileReader = new FileReader();
      fileReader.onload = function (event) {
        const arrayBuffer = event.target.result;
        audioContext.decodeAudioData(arrayBuffer, function (buffer) {
          const duration = Math.floor(buffer.duration);
          resolve(duration);
        });
      };
      if (audioBlob instanceof Blob) {
        fileReader.readAsArrayBuffer(audioBlob);
      } else {
        reject("Not a Blob");
      }
    });
  }

  const valSetfunc = (e) => setValue(e.target.value);

  const handleDownload = (event) => {
    setShowValue(true);
    event.preventDefault();
    event.stopPropagation();
    console.log("here click....");
    let data = {
      file: ImgUrl,
    };

    dispatch(getPreviewFile(data));
  };

  const toggleImg = () => {
    setTwo(false);
  };

  const toDelete = () => {
    if (window.confirm("Are you sure you want to delete?")) {
      setAudioURLs([]);
      setAudioURLs1([]);
      setOne(false);
      setTwo(true);
      setIsRecording(false);
      setIsRecordingPaused(false);
      setDelClick(true);
    }
  };

  const imgPreFls = () => setImgPre(false);
  const handlePreview = (item) => {
    setImgPre(true);
    setImgUrl(item);
    let getName = item.split("/");
    setFileName(getName[getName.length - 1]);
  };
  const handleChange = (event) => {
    const ext = event.target.files[0].type.split("/")[1];
    switch (ext) {
      case "jpg":
      case "jpeg":
      case "bmp":
      case "png":
      case "pdf":
        setSelectedFile(event.target.files[0]);
        break;
      default:
        setSelectedFile();
        setShowAlert(true);
        setModalMsg("unsupported file format");
        setStatus("fail");
        setTimeout(() => {
          setShowAlert(false);
          setStatus("");
          setModalMsg("");
        }, 1500);
    }
  };
  const filtInputAttach = () => {
    hiddenFileInput.current.value = "";
    hiddenFileInput.current.click();
  };
  const selFileNull = () => setSelectedFile(null);

  const handleOnSend = async (audioBlob) => {
    try {
      let duration1 = null;

      if (audioBlob !== undefined && audioBlob.size > 0) {
        duration1 = await getAudioBlobDuration(audioBlob);
      }

      if (!record) {
        if (three && audioURLs.length === 0) {
          return;
        }

        if (delClick) {
          audioStream.current = null;
        }
        if (
          mediaRecorder.current &&
          (mediaRecorder.current.state === "recording" ||
            mediaRecorder.current.state === "paused")
        ) {
          mediaRecorder.current.stop();
          audioStream.current !== null &&
            audioStream.current.getTracks().forEach((track) => {
              track.stop();
            });
          audioStream.current = null;
          audioContext.current.close();
          setIsRecording(false);
          setIsRecordingPaused(false);
        }
      }

      const filedata = new FormData();
      let article = {
        message: value,
        ipAddress: "127.0.0.1",
        sender_id: user_id,
        sender: "Provider",
        recip_id: ProviderDetails["providerName"],
        receive_id: pid.toString(),
        status: 1,
        message_type: "text",
        timerVal: duration1,
      };

      if (audioBlob !== undefined && audioBlob.size > 0) {
        filedata.append("audio", audioBlob, "recorded_audio.wav");
      } else if (selectedFile) {
        filedata.append("fileupload", selectedFile);
      } else if (value === "") {
        return false;
      }

      let body = {
        Provider_Id: user_id,
        Pid: pid,
      };

      let data = {
        article,
        body,
      };

      if (audioBlob !== undefined && audioBlob.size > 0) {
        data.file = filedata;
      } else if (selectedFile && selectedFile !== "" && selectedFile !== null) {
        data.file = filedata;
      }

      Promise.resolve()
        .then(() => {
          dispatch(sendMessage([data, selectedFile, audioBlob]));
        })
        .then(() => {
          setSelectedFile(null);
          setRefresh(!Refresh);
          refreshMessage();
          setValue("");
          setAudioURLs([]);
          setAudioURLs1([]);
        });
    } catch (error) {
      console.log(error.message);
    }
  };
  const refreshMessage = () => {
    dispatch(getMessageList(user_id));
  };
  const shareChatMod = (val) => {
    setChat(!chat);
  };
  const handleChat = (val) => {
    setChat(!chat);
    if (val === 0) {
      setImgPre(false);
      setValue("");
      setSelectedFile(null);
    } else if (val.length !== 0) {
      setPid(val.pid);
      setPatName(val.PatName);
      setPatpic(val.img_url);
    }

    dispatch(setSingleMessage([]));
    refreshMessage();
  };
  // -------------------------- Functions End-------------------------------------------------------------//
  // -------------------------- useEffect Start-------------------------------------------------------------//
  useEffect(() => {
    if (!selectedFile) {
      setPreview(undefined);
      return;
    }
    setFileName(selectedFile.name);
    var checkType = selectedFile.type.split("/");

    if (checkType[0] === "image") {
      setViewType("image");
    } else {
      setViewType("doc");
    }

    const objectUrl = URL.createObjectURL(selectedFile);
    setPreview(objectUrl);

    return () => URL.revokeObjectURL(objectUrl);
  }, [selectedFile]);

  useEffect(() => {
    dispatch(
      GetPatDetails({
        column_name: "",
        sort_order: "",
        search_key: "",
      })
    );
    let body = {
      Provider_Id: user_id,
      Pid: pid,
    };
    dispatch(getSingleMessage(body));
  }, [chat, Refresh]);

  useEffect(() => {
    refreshMessage();
  }, []);

  useEffect(() => {
    setSecMsgData(messageList);
  }, [messageList]);

  useEffect(() => {
    if (PreviewDownload !== "") {
      var tag = document.createElement("a");
      tag.href = PreviewDownload.file;
      tag.download = PreviewDownload.filename;
      document.body.appendChild(tag);
      tag.click();
      document.body.removeChild(tag);
      setPreviewDownload("");
      dispatch(clearPreview(""));
    }
  }, [PreviewDownload]);

  useEffect(() => {
    if (previewFile !== "")
      setPreviewDownload(() => {
        return previewFile;
      });
  }, [previewFile]);
  // --------------------------useEffect End-------------------------------------------------------------//
  return (
    <>
      <DropdownContainer ref={popupRef}>
        {chat ? (
          <>
            <Chat
              handleChat={handleChat}
              patName={patName}
              patpic={patpic}
              refresh={Refresh}
              setViewType={setViewType}
              handlePreview={handlePreview}
              profileimage={ProviderDetails["img_url"]}
              providerName={ProviderDetails["providerName"]}
              setSelectedFile={setSelectedFile}
              pid={pid}
            />
            {selectedFile && (
              <Row
                position="absolute"
                top="65px"
                width="100%"
                height="100%"
                backgroundColor="rgba(244, 244, 244, 0.9)"
                display="flex"
                flexDirection="column"
                padding="20px"
                radius="4px"
                maxWidth="371px"
                maxHeight="387px"
              >
                <Row
                  dis="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Text
                    fontSize="12px"
                    lineHeight="18px"
                    color="#91929e"
                    MaxWidth="313px"
                    textOverflow="ellipsis"
                    whiteSpace="nowrap"
                    overflow="hidden"
                  >
                    {fileName}
                  </Text>
                  <Close onClick={selFileNull} />
                </Row>
                {viewType === "image" && (
                  <Row
                    position="relative"
                    dis="flex"
                    flexDirection="row"
                    justifyContent="center"
                    alignItems="center"
                    height="100%"
                    width="100%"
                    overFlow="hidden"
                  >
                    <Image
                      src={preview}
                      width="max-content"
                      height="max-content"
                      MaxHeight="100%"
                      MaxWidth="100%"
                      margin="auto"
                      objectFit="contain"
                    />
                  </Row>
                )}
                {viewType === "doc" && (
                  <Row
                    position="relative"
                    dis="flex"
                    flexDirection="row"
                    justifyContent="center"
                    alignItems="center"
                    height="100%"
                    width="100%"
                    overFlow="hidden"
                  >
                    <Image
                      src={DocType}
                      width="max-content"
                      height="max-content"
                      MaxHeight="220px"
                      MaxWidth="220px"
                      margin="auto"
                    />
                  </Row>
                )}
              </Row>
            )}
            {imgPre && (
              <Row
                position="absolute"
                top="65px"
                width="100%"
                height="100%"
                backgroundColor="rgba(244, 244, 244, 0.9)"
                display="flex"
                flexDirection="column"
                padding="20px"
                borderRadius="4px"
                overFlow="hidden"
                maxWidth="371px"
                maxHeight="387px"
              >
                <Row
                  dis="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  padding="0 0 8px"
                >
                  <Row dis="flex">
                    <Text
                      fontSize="12px"
                      lineHeight="18px"
                      color="#91929e"
                      MaxWidth="283px"
                      textOverflow="ellipsis"
                      whiteSpace="nowrap"
                      overflow="hidden"
                    >
                      {fileName}
                    </Text>
                  </Row>
                  <Row dis="flex" overflow="hidden" id="wrapperofDownClose">
                    <DowloadBtn onClick={handleDownload} />{" "}
                    <Close onClick={imgPreFls} />
                  </Row>
                </Row>
                {viewType === "image" && (
                  <Row
                    position="relative"
                    dis="flex"
                    flexDirection="row"
                    justifyContent="center"
                    alignItems="center"
                    height="95%"
                    width="100%"
                    overFlow="hidden"
                  >
                    <Image
                      src={ImgUrl}
                      objectFit="contain"
                      width="max-content"
                      height="max-content"
                      MaxHeight="100%"
                      MaxWidth="100%"
                    />
                  </Row>
                )}
                {viewType === "doc" && (
                  <iframe
                    title="img"
                    src={ImgUrl}
                    width="100%"
                    height="95%"
                    frameBorder="0"
                    display="block"
                  />
                )}
              </Row>
            )}
            {!imgPre && (
              <Row
                dis="flex"
                position="relative"
                margin="0px 20px 16px 20px"
                background="#ffffff"
                border="1px solid #EAEAEA"
                boxShadow="0px 1px 2px rgba(184, 200, 224, 0.222055)"
                radius="8px"
                marginLeft="20px"
                display="flex"
                maxHeight="fit-content"
                padding="8px 12px 8px 17px"
                alignItems="center"
                justifyContent="center"
                textAlign="center"
              >
                <SearchInputText
                  type="file"
                  accept=".pdf,.png,.jpg,.bmp,.jpeg"
                  ref={hiddenFileInput}
                  onChange={(e) => handleChange(e)}
                  display="none"
                />

                {!isRecording && !isRecordingPaused ? (
                  <>
                    <Attach onClick={filtInputAttach} />
                    <AtSymbol onClick={() => setValue(value + "@")} />
                    <SearchInputText
                      placeholder="Type your message here…"
                      value={value}
                      paddingBottom="2px"
                      width="100%"
                      onChange={(e) => valSetfunc(e)}
                      onKeyPress={(e) => e.key === "Enter" && handleOnSend()}
                    />
                  </>
                ) : (
                  <Div marginRight="20px">
                    <RecordingStatus
                      isRecording={isRecording}
                      isRecordingPaused={isRecordingPaused}
                      audioURLs1={audioURLs1}
                      audioURLs={audioURLs}
                      recordingKey={recordingKey}
                      one={one}
                    />
                  </Div>
                )}
                <Div display="flex" alignItems="center">
                  {!isRecording && !isRecordingPaused ? (
                    <Image
                      mr="20px"
                      width="16px"
                      height="20px"
                      color="#fdc748"
                      cursor="pointer"
                      src={Microphone_Start}
                      alt="mike"
                      onClick={() => {
                        startRecording1();
                        startRecording();
                      }}
                    />
                  ) : isRecording && !isRecordingPaused ? (
                    <Div display="flex" alignItems="center">
                      <Image
                        mr="20px"
                        width="20px"
                        height="20px"
                        color="#fdc748"
                        cursor="pointer"
                        src={Microphone_Pause}
                        alt="mike"
                        onClick={() => {
                          pauseRecording();
                          pauseRecording1();
                        }}
                      />
                      <Circle onClick={handleOnSend}>
                        <FiSendIcon />
                      </Circle>{" "}
                      {/* <Image
                        mr="5px"
                        width="22px"
                        height="22px"
                        color="#fdc748"
                        cursor="pointer"
                        src={SendIcon}
                        onClick={handleOnSend}
                      /> */}
                    </Div>
                  ) : (
                    !isRecording &&
                    isRecordingPaused && (
                      <Div display="flex" alignItems="center">
                        {!one && (
                          <Tooltip>
                            <Image
                              mr="12px"
                              width="16px"
                              height="20px"
                              color="#fdc748"
                              cursor="pointer"
                              src={Microphone_End}
                              alt="mike"
                              onClick={() => {
                                resumeRecording();
                                resumeRecording1();
                              }}
                            />
                            <TooltipText className="TooltipText">
                              Resume
                            </TooltipText>
                          </Tooltip>
                        )}
                        <Div
                          // onClick={toggleImg}
                          display="flex"
                          alignItems="center"
                        >
                          {!isRecording && isRecordingPaused && two ? (
                            <Tooltip>
                              <Image
                                width="24px"
                                height="24px"
                                color="#fdc748"
                                cursor="pointer"
                                mr="10px"
                                src={audioPreviewButton}
                                onClick={() => {
                                  stopRecording1();
                                  toggleImg();
                                }}
                              />
                              <TooltipText className="TooltipText">
                                Preview
                              </TooltipText>
                            </Tooltip>
                          ) : (
                            !isRecording &&
                            isRecordingPaused &&
                            !two && (
                              <FiTrash2
                                onClick={toDelete}
                                style={{
                                  cursor: "pointer",
                                  color: "red",
                                  height: "22px",
                                  width: "22px",

                                  marginRight: "10px",
                                }}
                              />
                            )
                          )}

                          {/* <Tooltip>
                            <Image
                              mr="5px"
                              width="24px"
                              height="24px"
                              color="#fdc748"
                              cursor="pointer"
                              src={SendIcon}
                              onClick={stopRecording}
                              mt="1px"
                              ml="10px"
                            />
                            <TooltipText className="TooltipText">
                              send
                            </TooltipText>
                          </Tooltip> */}
                          <Circle
                            onClick={stopRecording}
                            style={
                              !isRecording && isRecordingPaused && two
                                ? { marginBottom: "3px" }
                                : {}
                            }
                          >
                            <FiSendIcon />
                          </Circle>
                        </Div>
                      </Div>
                    )
                  )}
                </Div>
                {!isRecording && !isRecordingPaused && (
                  // <Image
                  //   mr=" 5px"
                  //   ml=" auto"
                  //   width="24px"
                  //   height="24px"
                  //   color="#fdc748"
                  //   cursor="pointer"
                  //   src={SendIcon}
                  //   onClick={handleOnSend}
                  // />

                  <Circle onClick={handleOnSend}>
                    <FiSendIcon />
                  </Circle>
                )}
              </Row>
            )}
          </>
        ) : (
          <Messages
            shareChatMod={shareChatMod}
            handleChat={handleChat}
            secMsgData={secMsgData}
            EmptforAnotherProv={EmptforAnotherProv}
          />
          // "Hello"
        )}
      </DropdownContainer>
      {showAlert && (
        <AlertModal show={showAlert} status={status} msg={modalMsg} />
      )}
    </>
  );
};

export default SecureMessage;
