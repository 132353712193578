import { createSlice } from "@reduxjs/toolkit";

export const PatientSlice = createSlice({
  name: "Patient",
  initialState: {
    CardDetail: [],
    CardLoading: false,
    PatientDetails: [],
    Loading: true,
    SinglePatient: {},
    patientlist: [],
    VitalsNotification: [],
    VitalsNotificationUpdate: "",
    PatientEntrollement: "",
    PatientEnrollmentUpdate: "",
    GenderStatusList: [],
    RaceStatusList: [],
    LanguageStatusList: [],
    AddcardResponse: "",
    UpdatecardResponse: "",
    DeletecardResponse: "",
    MailVerify: "",
    PhoneVerify: "",
    PatientEmail: "",
    PatientUpdateResponse: "",
    SSNVerify: "",
    FeeList: [],
    FeeListCollapse: [],
    unenrollmentupdate: "",
    SetUnenrollresponse: "",
    providerlist: [],
    status: "idle",
    patientImport: "",
    LoadImport: false,
  },
  reducers: {
    AddCardDetails: (data) => {
      return data;
    },
    setAddCardDetailRes: (state, action) => {
      state.AddcardResponse = action.payload;
    },
    setEmptyAddcardResponse: (state) => {
      return state;
    },

    setUpdateCardDetailRes: (state, action) => {
      state.UpdatecardResponse = action.payload;
    },
    setEmptyUpdatecardResponse: (state) => {
      return state;
    },

    setDeleteCardDetailRes: (state, action) => {
      state.DeletecardResponse = action.payload;
    },
    setEmptyDeletecardResponse: (state) => {
      return state;
    },

    GetCardDetails: (data) => {
      return data;
    },
    SetCardDetails: (state, action) => {
      state.CardDetail = action.payload;
    },

    GetVitalsNotification: (data) => {
      return data;
    },
    SetVitalsNotification: (state, action) => {
      state.VitalsNotification = action.payload;
    },
    GetVitalsNotificationUpdate: (data) => {
      return data;
    },
    SetVitalsNotificationUpdate: (state, action) => {
      state.VitalsNotificationUpdate = action.payload;
    },

    GetPatDetails: (state) => {
      state.Loading = true;
    },
    SetPatDetails: (state, action) => {
      state.PatientDetails = action.payload;
      state.Loading = false;
    },
    RemoveCardDetail: (data) => {
      return data;
    },

    EditCardDetails: (data) => {
      return data;
    },

    GetSinglePatDetail: (state) => {
      state.Loading = true;
    },
    SetSinglePatDetail: (state, action) => {
      state.SinglePatient = action.payload;
      state.Loading = false;
    },
    getPatientSearchList: (user) => {
      return user;
    },

    setPatientSearchList: (state, action) => {
      state.patientlist = action.payload;
    },
    GetPatEnrollment: (state) => {
      state.Loading = true;
    },
    GetPatEnrollmentUpdate: (state) => {
      state.Loading = true;
    },
    GetGenderList: (state) => {
      state.Loading = true;
    },
    SetGenderList: (state, action) => {
      state.GenderStatusList = action.payload;
      state.Loading = false;
    },

    GetRaceList: (state) => {
      state.Loading = true;
    },
    SetRaceList: (state, action) => {
      state.RaceStatusList = action.payload;
      state.Loading = false;
    },

    GetLanguageList: (state) => {
      state.Loading = true;
    },
    SetLanguageList: (state, action) => {
      state.LanguageStatusList = action.payload;
      state.Loading = false;
    },
    GetMailVerify: (state) => {
      // state.Loading = true;
      return state;
    },
    SetMailVerify: (state, action) => {
      state.MailVerify = action.payload;
      // state.Loading = false;
    },
    GetPhoneVerify: (state) => {
      // state.Loading = true;
      return state;
    },
    SetPhoneVerify: (state, action) => {
      state.PhoneVerify = action.payload;
      // state.Loading = false;
    },
    setPatientUpdate: (state, action) => {
      state.PatientUpdateResponse = action.payload;
    },
    setEmptyPatUpdateResponse: (state) => {
      return state;
    },
    GetSSNVerify: (state) => {
      // state.Loading = true;
      return state;
    },
    SetSSNVerify: (state, action) => {
      state.SSNVerify = action.payload;
      // state.Loading = false;
    },
    GetFeeList: (state) => {
      state.Loading = true;
    },
    SetFeeList: (state, action) => {
      state.FeeList = action.payload;
      state.Loading = false;
    },
    GetFeeListCollapse: (state) => {
      return state;
    },
    SetFeeListCollapse: (state, action) => {
      state.FeeListCollapse = action.payload;
    },
    GetFeeDelete: (state) => {
      return state;
    },
    GetFeeCollapseDelete: (state) => {
      return state;
    },
    setEmptyMailResponse: (state) => {
      return state;
    },
    GetPatientEmail: (user) => {
      return user;
    },
    SetPatientEmail: (state, action) => {
      state.PatientEmail = action.payload;
    },
    GetUnenrollmentupdate: (data) => {
      return data;
    },
    SetUnenrollmentupdate: (state, action) => {
      state.unenrollmentupdate = action.payload;
    },
    SetUnenrollresponse: (state, action) => {
      state.UnenrollEmptyResponse = action.payload;
    },
    getProviderSearchList: (user) => {
      return user;
    },

    setProviderSearchList: (state, action) => {
      state.providerlist = action.payload;
    },
    getExportStatus: (data) => {
      return data;
    },
    setExportStatus: (state, action) => {
      state.status = action.payload.status;
      state.error = action.payload.error ? action.payload.error : null;
    },
    getPatientImport: (user) => {
      user.LoadImport = true;
      return user;
    },
    setPatientImport: (state, action) => {
      state.patientImport = action.payload;
      state.LoadImport = false;
    },
  },
});

export const {
  GetCardDetails,
  SetCardDetails,
  GetVitalsNotification,
  SetVitalsNotification,
  GetVitalsNotificationUpdate,
  SetVitalsNotificationUpdate,
  AddCardDetails,
  setAddCardDetailRes,
  setEmptyAddcardResponse,
  setUpdateCardDetailRes,
  setEmptyUpdatecardResponse,
  setDeleteCardDetailRes,
  setEmptyDeletecardResponse,
  PatientDetails,
  GetPatDetails,
  SetPatDetails,
  PatientListSearch,
  GetPatSearch,
  SetPatSearch,
  GetSinglePatDetail,
  SetSinglePatDetail,
  getPatientSearchList,
  setPatientSearchList,
  patientEntrollement,
  GetPatEnrollment,
  PatientEnrollmentUpdate,
  GetPatEnrollmentUpdate,
  GenderStatusList,
  RaceStatusList,
  LanguageStatusList,
  GetGenderList,
  GetRaceList,
  GetLanguageList,
  SetGenderList,
  SetRaceList,
  SetLanguageList,
  RemoveCardDetail,
  EditCardDetails,
  GetMailVerify,
  SetMailVerify,
  MailVerify,
  GetPhoneVerify,
  SetPhoneVerify,
  PhoneVerify,
  setPatientUpdate,
  setEmptyPatUpdateResponse,
  GetSSNVerify,
  SetSSNVerify,
  FeeList,
  GetFeeList,
  SetFeeList,
  FeeListCollapse,
  GetFeeListCollapse,
  SetFeeListCollapse,
  GetFeeDelete,
  GetFeeCollapseDelete,
  setEmptyMailResponse,
  SetPatientEmail,
  GetPatientEmail,
  GetUnenrollmentupdate,
  SetUnenrollmentupdate,
  SetUnenrollresponse,
  getProviderSearchList,
  setProviderSearchList,
  getExportStatus,
  setExportStatus,
  getPatientImport,
  setPatientImport,
} = PatientSlice.actions;

export default PatientSlice.reducer;
