import React, { useState, useEffect, useRef } from "react";
import {
  Modal,
  ModalContainer,
  ModalTitle,
  Modclosebtn,
  ButtonGroup,
  Div,
  Input,
  Span,
  ModalHeader,
  ModalFooter,
  DropdownImage,
  Button,
  Form,
  Select,
  Image,
  SpanTag,
} from "../../StyledComponents";
import DropdownIcon from "../../../Assets/images/dropdownDownicon.png";
import { FaTimes } from "react-icons/fa";
import { ModalDialog } from "../../StyledComponents/AddCard/Styles";
import { ErrorMessage } from "../../Patient/BillandInsurance/styles";
import dropdownDownicon from "../../../Assets/images/dropdownDownicon.png";
import { useSelector, useDispatch } from "react-redux";
import {
  Checkbox,
  CheckboxInput,
  CheckboxCheckmark,
  DropdownContainer,
  DropdownButton,
  DropdownList,
  DropdownItem,
  Label,
  Ptag,
  ModalBody,
  Container,
  StyledSelect,
  SelectButton,
  CustomSelect,
  OptionList,
  OptionItem,
} from "./styles";
import {
  GetSaveProv,
  GetUpdateProv,
  SetEmptyUserListResponse,
  SetEmptyUpdateResponse,
  GetProviderList,
  GetSettingSecurity,
  GetCheckUser,
  EmptyingCheckUser,
  GetCheckEmail,
  GetCheckPhone,
  GetCheckCell,
  EmptyingCheckEmail,
  EmptyingCheckPhone,
  EmptyingCheckCell,
  GetRoles,
} from "../../../StateManagement/Reducers/SettingState";
import CustomDatePicker from "../../StyledComponents/datepicker/CustomDatePicker";
import moment from "moment";
import { Imagecalendar } from "../../Popup/styles";
import calendar_icon_custom from "../../../Assets/images/calendar_icon_custom.png";
import { FiTrash2 } from "react-icons/fi";
import AlertModal from "../../StyledComponents/AlertModal/AlertModal";
import { CheckBoxLabel, TogggleCheck } from "./styles";
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa";
import { Decrypt_Value } from "../../../MiddleWare/EncryptDecrypt";
import axiosInst from "../../../Config";
const AddProvider = ({
  setOpen,
  id,
  show,
  modalRef,
  uid,
  ProvData,
  mainId,
  userData,
  staff,
}) => {
  //-------------------------------------------UseState and Variables Start--------------------------------------------------------//
  const [screenSize, setScreenSize] = useState({ width: 0, height: 0 });
  const cellRef = useRef();
  const phoneRef = useRef();
  const user_id =
    localStorage.getItem("user_id") &&
    Decrypt_Value(localStorage.getItem("user_id"), "vozorpm");
  const [details, setDetails] = useState({
    fname: "",
    lname: "",
    provider: "",
    roles: "",
    group: "",
    email: "",
    userName: "",
    password: "",
    confirmPassword: "",
    homePhone: "",
    cellPhone: "",
    federalTax: "",
    deaNumber: "",
    jobDesc: "",
    taxanomy: "",
    upin: "",
    npi: "",
    speciality: "",
  });
  const [LiceninputList, setLiceninputList] = useState([
    {
      licentype: "",
      licensenum: "",
      licenseExp: "",
      licenseState: "",
      licenseExpCheck: "",
    },
  ]);
  const [LiceninputListErr, setLiceninputListErr] = useState([
    {
      licentypeErr: false,
      licensenumErr: false,
      licenseExpErr: false,
      licenseStateErr: false,
      licenseExpCheckErr: false,
      licenNumberMatchErr: false,
    },
  ]);

  const [Error, setError] = useState({
    fnameErr: false,
    lnameErr: false,
    providerErr: false,
    rolesErr: false,
    emailErr: false,
    userNameErr: false,
    passwordErr: false,
    confirmPasswordErr: false,
    homePhoneErr: false,
    federalTaxErr: false,
    deaNumberErr: false,
    taxanomyErr: false,
    upinErr: false,
    npiErr: false,
    comparePassErr: false,
  });
  const [isChecked, setIsChecked] = useState(false);

  const [isProviderDropdownOpen, setProviderDropdownOpen] = useState(false);
  const [isRolesDropdownOpen, setRolesDropdownOpen] = useState(false);
  const [isSpecialityDropdownOpen, setSpecialityDropdownOpen] = useState(false);
  const [checkUserName, setCheckUserName] = useState(false);
  const [checkEmail, setcheckEmail] = useState(false);
  const [checkPhone, setcheckPhone] = useState(false);
  const [checkPhoneCell, setcheckPhoneCell] = useState(false);
  const [dis, setDis] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpen1, setIsOpen1] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState(null);
  const [selectedOptions1, setSelectedOptions1] = useState(null);
  const handleSelectOption = (option) => {
    setSelectedOptions(option);
    setIsOpen(false);
  };

  const handleSelectOption1 = (option) => {
    setSelectedOptions1(option);
    setIsOpen1(false);
  };
  const [isLiStateDropdownOpenArray, setIsLiStateDropdownOpenArray] = useState(
    Array(LiceninputList.length).fill(false)
  );
  const [isLiTypeDropdownOpenArray, setIsLiTypeDropdownOpenArray] = useState(
    Array(LiceninputList.length).fill(false)
  );
  const [showpicker, setShowPicker] = useState();
  const [validDate, setValidDate] = useState("");
  const [ShowOptions, setShowOptions] = useState(false);
  const [ShowOptions1, setShowOptions1] = useState();
  const [passStrong, setPassStrong] = useState("");
  const [strength, setStrength] = useState(false);
  const [lenErr, setLenErr] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [modalMsg, setModalMsg] = useState("");
  const [status, setStatus] = useState("");
  const [PasswordIS, setPasswordIS] = useState(true);
  const [PasswordIS2, setPasswordIS2] = useState(true);
  const [selectedProviderIdFromBackend, setSelectedProviderIdFromBackend] =
    useState(null);
  const [selectedProviderTitle, setSelectedProviderTitle] =
    useState("Select Provider");
  const dropdownRef = useRef();
  const dropdownRef1 = useRef();
  const dropdownRef3 = useRef();
  const dropdownRefs = useRef([]);
  const dropdownRefs1 = useRef([]);

  const [filterVal, setFilterVal] = useState("");
  const minimum = localStorage.getItem("MinimumPasswordLength")
    ? localStorage.getItem("MinimumPasswordLength")
    : "0";

  const liTypeList = [
    "LMFT",
    "LCSW",
    "LPC",
    "Psy. D",
    "LMHC",
    "LCPC",
    "LICSW",
    "LPCC",
    "LMSW",
    "SLP",
    "RD",
  ];

  const UserResponse = useSelector((state) => state.Setting.UserResponse);
  const UserUpdateResponse = useSelector(
    (state) => state.Setting.UpdateUserResponse
  );
  const ProvList = useSelector((state) => state.Setting.ProviderList);
  const SecurityData = useSelector((state) => state.Setting.SecurtiyData);
  const CheckUserEmail = useSelector((state) => state.Setting.CheckUserEmail);
  const CheckProvName = useSelector((state) => state.Setting.CheckProvName);
  const CheckHomePhone = useSelector((state) => state.Setting.CheckHomePhone);
  const CheckCellPhone = useSelector((state) => state.Setting.CheckCellPhone);
  const Roles = useSelector((state) => state.Setting.Roles);
  const CountryCode = useSelector((state) => state.Setting.CountryCode);

  //-------------------------------------------UseState and Variables End--------------------------------------------------------//

  // ------------------------------------------------- Functions Start-----------------------------------------------------------//
  const updatedCountryCodes = CountryCode.map((country) => ({
    ...country,
    number: `(+${country.number})`,
  }));
  const sortedCountryCode = [...updatedCountryCodes].sort((a, b) => {
    if (a.full_name < b.full_name) {
      return -1;
    }
    if (a.full_name > b.full_name) {
      return 1;
    }
    return 0;
  });

  const toggleDropdown = (dropdownKey, index) => {
    switch (dropdownKey) {
      case "providerDrop":
        setProviderDropdownOpen((prev) => !prev);
        break;
      case "rolesDrop":
        setRolesDropdownOpen((prev) => !prev);
        break;
      case "specialityDrop":
        setSpecialityDropdownOpen((prev) => !prev);
        break;
      case "liStateDrop":
        setIsLiStateDropdownOpenArray((prevState) => {
          const newState = [...prevState];
          newState[index] = !newState[index];
          return newState;
        });
        break;
      case "LicenseTypeDrop":
        setIsLiTypeDropdownOpenArray((prevState) => {
          const newState = [...prevState];
          newState[index] = !newState[index];
          return newState;
        });
        break;

      default:
        break;
    }
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setProviderDropdownOpen(false);
    }

    if (dropdownRef1.current && !dropdownRef1.current.contains(event.target)) {
      setRolesDropdownOpen(false);
    }

    if (dropdownRef3.current && !dropdownRef3.current.contains(event.target)) {
      setSpecialityDropdownOpen(false);
    }

    dropdownRefs.current.forEach((ref, index) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setIsLiStateDropdownOpenArray((prevState) => {
          const newState = [...prevState];
          newState[index] = false;
          return newState;
        });
      }
    });

    dropdownRefs1.current.forEach((ref, index) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setIsLiTypeDropdownOpenArray((prevState) => {
          const newState = [...prevState];
          newState[index] = false;
          return newState;
        });
      }
    });
  };

  const handleSelect = (id, index, value, main_pro) => {
    setDetails({ ...details, provider: id });
    setSelectedProviderIdFromBackend(id);
    setSelectedProviderTitle(value);

    setProviderDropdownOpen(false);
  };

  const handleSelect1 = (value, group_id, index) => {
    setDetails({ ...details, roles: value, group: group_id });
    setRolesDropdownOpen(false);
  };
  const handleSelect3 = (value) => {
    setDetails({ ...details, speciality: value });
    setSpecialityDropdownOpen(false);
  };

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };
  const dispatch = useDispatch();
  const LicenseMatchedErr = (currentIndex) => {
    for (let i = 0; i < currentIndex; i++) {
      if (
        LiceninputList[i].licensenum ===
          LiceninputList[currentIndex].licensenum &&
        LiceninputList[currentIndex].licensenum !== ""
      ) {
        return true;
      }
    }
    return false;
  };

  const HandleSaveProvider = (e) => {
    e.preventDefault();
 
    let err = 0;

    const duplicateIndices = findDuplicateRows();
    if (isChecked && LiceninputList !== undefined) {
      if (duplicateIndices.length > 0) {
        const list = [...LiceninputListErr];
        duplicateIndices.forEach((index) => {
          list[index].licenNumberMatchErr = true;
        });
        setLiceninputListErr(list);
        err = 2;
      }

      for (var j = 0; j < LiceninputList.length; j++) {
        if (LiceninputList[j].licentype === "") {
          const list = [...LiceninputListErr];
          if (list[j] !== undefined) {
            list[j].licentypeErr = true;
          }
          setLiceninputListErr(list);
          err = 2;
        } else {
          const list = [...LiceninputListErr];
          if (list[j] !== undefined) {
            list[j].licentypeErr = false;
          }
          setLiceninputListErr(list);
        }
        if (LiceninputList[j].licensenum === "") {
          const list = [...LiceninputListErr];
          if (list[j] !== undefined) {
            list[j].licensenumErr = true;
          }
          setLiceninputListErr(list);
          err = 2;
        } else {
          const list = [...LiceninputListErr];
          if (list[j] !== undefined) {
            list[j].licensenumErr = false;
          }
          setLiceninputListErr(list);
        }
        if (LiceninputList[j].licenseExp === "") {
          const list = [...LiceninputListErr];
          if (list[j] !== undefined) {
            list[j].licenseExpErr = true;
          }
          setLiceninputListErr(list);
          err = 2;
        }
        if (LiceninputList[j].licenseExpCheck !== undefined) {
          if (currentDate > LiceninputList[j].licenseExpCheck) {
            const list = [...LiceninputListErr];
            if (list[j] !== undefined) {
              list[j].licenseExpCheckErr = true;
            }
            setLiceninputListErr(list);
            err = 2;
          } else {
            const list = [...LiceninputListErr];
            if (list[j] !== undefined) {
              list[j].licenseExpCheckErr = false;
            }
            setLiceninputListErr(list);
          }
        }
        if (LiceninputList[j].licenseState === "") {
          const list = [...LiceninputListErr];
          if (list[j] !== undefined) {
            list[j].licenseStateErr = true;
          }
          setLiceninputListErr(list);
          err = 2;
        } else {
          const list = [...LiceninputListErr];
          if (list[j] !== undefined) {
            list[j].licenseStateErr = false;
          }
          setLiceninputListErr(list);
        }
      }
    }

    if (
      details.password !== "" &&
      details.password !== null &&
      details.password !== undefined
    ) {
      var upper = 0,
        lower = 0,
        number = 0,
        special = 0;
      var format = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;

      for (var i = 0; i < details.password.length; i++) {
        if (!isNaN(details.password[i] * 1)) number++;
        else if (format.test(details.password[i])) special++;
        else if (details.password[i] === details.password[i].toLowerCase())
          lower++;
        else if (details.password[i] === details.password[i].toUpperCase())
          upper++;
      }
    }

    if (details.fname === "") {
      setError((prevError) => ({ ...prevError, fnameErr: true }));
      err = 1;
    }
    if (details.lname === "") {
      setError((prevError) => ({ ...prevError, lnameErr: true }));
      err = 1;
    }
    if (id === 0 && details.provider === "") {
      setError((prevError) => ({ ...prevError, providerErr: true }));
      err = 1;
    }
    if (details.roles === "") {
      setError((prevError) => ({ ...prevError, rolesErr: true }));
      err = 1;
    }
    if (details.email === "") {
      setError((prevError) => ({ ...prevError, emailErr: true }));
      err = 1;
    }
    if (details.userName === "") {
      setError((prevError) => ({ ...prevError, userNameErr: true }));
      err = 1;
    }
    if (details.password === "") {
      setError((prevError) => ({ ...prevError, passwordErr: true }));
      err = 1;
    }
    if (details.confirmPassword === "") {
      setError((prevError) => ({ ...prevError, confirmPasswordErr: true }));
      err = 1;
    }
    if (details.password !== details.confirmPassword) {
      setError((prevError) => ({ ...prevError, comparePassErr: true }));
      err = 1;
    } else if (
      passStrong === "1" &&
      (upper === 0 || lower === 0 || number === 0 || special === 0)
    ) {
      setStrength(true);
      err = 1;
    } else {
      if (details.password !== undefined) {
        if (details.password.length < minimum) {
          setLenErr(true);
          err = 1;
        }
      }
    }
    if (details.homePhone === "") {
      setError((prevError) => ({ ...prevError, homePhoneErr: true }));
      err = 1;
    }
    if (isChecked) {
      if (details.federalTax === "") {
        setError((prevError) => ({ ...prevError, federalTaxErr: true }));
        err = 1;
      }
      if (details.deaNumber === "") {
        setError((prevError) => ({ ...prevError, deaNumberErr: true }));
        err = 1;
      }
      if (details.taxanomy === "") {
        setError((prevError) => ({ ...prevError, taxanomyErr: true }));
        err = 1;
      }
      if (details.upin === "") {
        setError((prevError) => ({ ...prevError, upinErr: true }));
        err = 1;
      }
      if (details.npi === "") {
        setError((prevError) => ({ ...prevError, npiErr: true }));
        err = 1;
      }
    }

    if (checkUserName || checkPhone || checkPhoneCell) {
      err = 2;
    }

    if (checkEmail || Error.emailErr) {
      err = 2;
    }

    if (err > 0) {
      return false;
    } else {
      setDis(true);
      let che = 0;
      isChecked ? (che = 1) : (che = 0);
      if (id <= 0) {
        const phoneNumber = selectedOptions ? selectedOptions : "(+1)";
        const cellNumber = details.cellPhone
          ? selectedOptions1
            ? selectedOptions1
            : "(+1)"
          : "";
        const datas = {
          pro_id: id,
          fname: details.fname,
          lname: details.lname,
          email: details.email,
          username: details.userName,
          phone: `${String(phoneNumber)}${details.homePhone}`,
          phonecell: `${String(cellNumber)}${details.cellPhone}`,
          federaltaxid: details.federalTax,
          title: details.jobDesc,
          taxonomy: details.taxanomy,
          provider_id: details.provider,
          fullupin: details.upin,
          license: LiceninputList,
          special: details.speciality,
          fulldeanumber: details.deaNumber,
          npi: details.npi,
          stat: che,
          passwrd: details.password,
          role: details.group,
          uid: uid,
          accountType: 0,
          trial: null,
        };
        // return;
        Promise.resolve()
          .then(() => {
            dispatch(GetSaveProv({ data: datas, datas: userData }));
          })
          .then(() => {
            setShowAlert(true);
            setModalMsg("Added Successfully");
            setStatus("success");
            setTimeout(() => {
              setShowAlert(false);
              setStatus("");
              setModalMsg("");
              setCheckUserName(false);
              setcheckEmail(false);
              setOpen(false);
              setDis(false);
            }, 1500);
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        const phoneNumber = selectedOptions ? selectedOptions : "(+1)";
        const cellNumber = details.cellPhone
          ? selectedOptions1
            ? selectedOptions1
            : "(+1)"
          : "";
        const datas = {
          fname: details.fname,
          lname: details.lname,
          email: details.email,
          username: details.userName,
          phone: `${String(phoneNumber)}${details.homePhone}`,
          phonecell: `${String(cellNumber)}${details.cellPhone}`,
          federaltaxid: details.federalTax,
          title: details.jobDesc,
          taxonomy: details.taxanomy,
          provider_id: details.provider !== "" ? details.provider : null,
          fullupin: details.upin,
          license: LiceninputList,
          special: details.speciality,
          fulldeanumber: details.deaNumber,
          npi: details.npi,
          stat: che,
          passwrd: details.password,
          role: details.group,
          uid: uid,
          accountType: 0,
          trial: null,
        };
        // return;
        Promise.resolve()
          .then(() => {
            dispatch(GetUpdateProv({ uid: id, data: datas, datas: userData }));
          })
          .then(() => {
            setShowAlert(true);
            setModalMsg("Updated Successfully");
            setStatus("success");
            setTimeout(() => {
              setShowAlert(false);
              setStatus("");
              setModalMsg("");
              setcheckEmail(false);
              setCheckUserName(false);
              setOpen(false);
              setDis(false);
            }, 1500);
          })
          .catch((err) => console.log(err));
      }
    }
  };

  const changeCode = (e) => {
    e.stopPropagation();
    const onlyAlphabets = /^[a-zA-Z ]*$/;

    if (e.target.id === "fname") {
      if (onlyAlphabets.test(e.target.value)) {
        setDetails({ ...details, fname: e.target.value });
      }
      setError((prevError) => ({ ...prevError, fnameErr: false }));
    }
    if (e.target.id === "lname") {
      if (onlyAlphabets.test(e.target.value)) {
        setDetails({ ...details, lname: e.target.value });
      }
      setError((prevError) => ({ ...prevError, lnameErr: false }));
    }
    if (e.target.id === "email") {
      var regex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
      setDetails({ ...details, email: e.target.value });
      if (regex.test(e.target.value)) {
        setError((prevError) => ({ ...prevError, emailErr: false }));
      } else {
        setError((prevError) => ({ ...prevError, emailErr: true }));
      }
      setcheckEmail(false);
    }
    if (e.target.id === "uname") {
      setDetails({ ...details, userName: e.target.value });

      setError((prevError) => ({ ...prevError, userNameErr: false }));
      setCheckUserName(false);
    }
    if (e.target.id === "pass") {
      setStrength(false);
      setLenErr(false);
      setDetails({ ...details, password: e.target.value });
      setError((prevError) => ({
        ...prevError,
        passwordErr: false,
        comparePassErr: false,
      }));
    }
    if (e.target.id === "confirmpass") {
      setStrength(false);
      setLenErr(false);
      setDetails({ ...details, confirmPassword: e.target.value });
      setError((prevError) => ({
        ...prevError,
        confirmPasswordErr: false,
        comparePassErr: false,
      }));
    }
    if (e.target.id === "homephone") {
      setDetails({
        ...details,
        homePhone: e.target.value.replace(/[^0-9]/g, ""),
      });
      setError((prevError) => ({ ...prevError, homePhoneErr: false }));
    }
    if (e.target.id === "cellphone") {
      setDetails({
        ...details,
        cellPhone: e.target.value.replace(/[^0-9]/g, ""),
      });
    }
    if (e.target.id === "fedtax") {
      setDetails({ ...details, federalTax: e.target.value });
      setError((prevError) => ({ ...prevError, federalTaxErr: false }));
    }
    if (e.target.id === "deanumber") {
      setDetails({ ...details, deaNumber: e.target.value });
      setError((prevError) => ({ ...prevError, deaNumberErr: false }));
    }
    if (e.target.id === "jobdesc") {
      setDetails({ ...details, jobDesc: e.target.value });
    }
    if (e.target.id === "tax") {
      setDetails({ ...details, taxanomy: e.target.value });
      setError((prevError) => ({ ...prevError, taxanomyErr: false }));
    }
    if (e.target.id === "upin") {
      setDetails({ ...details, upin: e.target.value });
      setError((prevError) => ({ ...prevError, upinErr: false }));
    }
    if (e.target.id === "npi") {
      setDetails({ ...details, npi: e.target.value });
      setError((prevError) => ({ ...prevError, npiErr: false }));
    }
  };

  const options = [
    {
      label: "- US -",
      values: [
        "AL",
        "AK",
        "AZ",
        "AR",
        "CA",
        "CO",
        "CT",
        "DE",
        "DC",
        "FL",
        "GA",
        "HI",
        "ID",
        "IL",
        "IN",
        "IA",
        "KS",
        "KY",
        "LA",
        "ME",
        "MD",
        "MA",
        "MI",
        "MN",
        "MS",
        "MO",
        "MT",
        "NE",
        "NV",
        "NJ",
        "NM",
        "NY",
        "NC",
        "ND",
        "OH",
        "OK",
        "OR",
        "PA",
        "PR",
        "RI",
        "SC",
        "SD",
        "TN",
        "TX",
        "UT",
        "VT",
        "VA",
        "WA",
        "WV",
        "WI",
        "WY",
        "AA",
        "AE",
        "AP",
        "GU",
        "VI",
      ],
    },

    {
      label: "-CA-",
      values: [
        "AB",
        "BC",
        "MB",
        "NB",
        "NL",
        "NT",
        "NS",
        "NU",
        "ON",
        "PE",
        "QC",
        "SK",
        "YT",
      ],
    },
  ];

  const handlepicker = (e, i) => {
    e.preventDefault();
    if (showpicker === i) {
      setShowPicker();
    } else {
      setShowPicker(i);
    }
  };
  const keyDownExpiry = (event) => {
    const inp = event.target;
    const key = event.keyCode || event.charCode;

    const inputValue = inp.value;
    const numericValue = inputValue.replace(/[^\d]/g, "");
    const caretPosition = inp.selectionStart;

    if (key !== 8 && key !== 46) {
      if (numericValue.length === 4 && caretPosition === 4) {
        inp.value = numericValue + "-";
      } else if (numericValue.length === 6 && caretPosition === 7) {
        inp.value =
          numericValue.slice(0, 4) + "-" + numericValue.slice(4, 6) + "-";
      } else if (numericValue.length > 6 && caretPosition === 10) {
        inp.value =
          numericValue.slice(0, 4) +
          "-" +
          numericValue.slice(4, 6) +
          "-" +
          numericValue.slice(6, 8);
      }
    }
  };

  const handleAddClick = (e) => {
    e.stopPropagation();
    setLiceninputList([
      ...LiceninputList,
      {
        licentype: "",
        licensenum: "",
        licenseExp: "",
        licenseState: "",
        licenseExpCheck: "",
      },
    ]);

    setLiceninputListErr([
      ...LiceninputListErr,
      {
        licentypeErr: false,
        licensenumErr: false,
        licenseExpErr: false,
        licenseStateErr: false,
        licenseExpCheckErr: false,
        licenNumberMatchErr: false,
      },
    ]);
  };
  const closePicker = () => {
    setShowPicker();
  };
  const currentDate = moment().format("YYYY-MM-DD");
  const handleRemoveClick = (index) => {
    const list = [...LiceninputList];
    list.splice(index, 1);
    setLiceninputList(list);

    const listErr = [...LiceninputListErr];
    listErr.splice(index, 1);
    setLiceninputListErr(listErr);
  };

  const handleCustomCalendar = (event) => {
    if (
      event.target.id !== "CustomDatePicker" &&
      event.target.id !== "CustomDatePickerbuttons" &&
      event.target.id !== "date" &&
      event.target.id !== "date1" &&
      event.target.className !== "year"
    ) {
      closePicker();
    }
  };

  const dateSelect = (value, index) => {
    var name = "licenseExp";
    if (Object.prototype.toString.call(value) === "[object Date]") {
      if (value !== "") {
        const list = [...LiceninputList];
        list[index][name] = moment(value).format("YYYY-MM-DD");
        list[index]["licenseExpCheck"] = moment(value).format("YYYY-MM-DD");

        setLiceninputList(list);

        const listErr = [...LiceninputListErr];
        if (listErr !== undefined) {
          listErr[index][name + "Err"] = false;
          listErr[index]["licenseExpCheckErr"] = false;
        }
        setLiceninputListErr(listErr);
      } else {
        const list = [...LiceninputList];
        list[index][name] = value;
        setLiceninputList(list);

        const listErr = [...LiceninputListErr];
        listErr[index][name + "Err"] = true;
        setLiceninputListErr(listErr);
      }

      setValidDate(new Date(value));
    } else {
      setValidDate("");
    }
    closePicker();
  };

  const handleSelectDrop = (value, index, name) => {
    if (name === "licentype") {
      setShowOptions(false);
    }

    if (name === "licenseState") {
      setShowOptions1(false);
    }

    setLiceninputList((prevList) => {
      const newList = prevList.map((item, i) =>
        i === index ? { ...item, [name]: value } : item
      );
      return newList;
    });

    setLiceninputListErr((prevListErr) => {
      const newListErr = prevListErr.map((item, i) =>
        i === index ? { ...item, [name + "Err"]: value === "" } : item
      );
      return newListErr;
    });

    // Close the dropdown after selecting an option
    if (name === "licenseState") {
      setIsLiStateDropdownOpenArray((prevState) => {
        const newState = [...prevState];
        newState[index] = false;
        return newState;
      });
    } else if (name === "licentype") {
      setIsLiTypeDropdownOpenArray((prevState) => {
        const newState = [...prevState];
        newState[index] = false;
        return newState;
      });
    }
  };

  const findDuplicateRows = () => {
    const duplicateRows = LiceninputList.reduce(
      (acc, currentItem, currentIndex) => {
        const isDuplicate =
          currentItem.licensenum !== "" &&
          LiceninputList.findIndex(
            (item, index) =>
              index !== currentIndex &&
              item.licensenum !== "" &&
              item.licensenum === currentItem.licensenum
          ) !== -1;

        if (isDuplicate && !acc.includes(currentIndex)) {
          acc.push(currentIndex);
        }

        return acc;
      },
      []
    );

    return duplicateRows;
  };

  const changeLicenseCode = (e, index) => {
    if (!e.target.value.includes(" ")) {
      const { name, value } = e.target;

      if (value !== "") {
        const list = [...LiceninputList];

        list[index][name] = value;
        setLiceninputList(list);

        const listErr = [...LiceninputListErr];
        listErr[index][name + "Err"] = false;
        listErr[index]["licenNumberMatchErr"] = false;

        setLiceninputListErr(listErr);
      } else {
        const list = [...LiceninputList];
        list[index][name] = value;
        setLiceninputList(list);

        const listErr = [...LiceninputListErr];
        listErr[index][name + "Err"] = true;
        listErr[index]["licenNumberMatchErr"] = false;
        setLiceninputListErr(listErr);
      }
    }
  };

  const handleToggleDropdown = (index, e) => {
    setIsLiStateDropdownOpenArray((prevState) => {
      const newState = [...prevState];
      newState[index] = !newState[index];
      return newState;
    });
  };
  const handlesetPasswordIS = () => {
    setPasswordIS((prevState) => !prevState);
  };

  const handlesetPasswordIS2 = () => {
    setPasswordIS2((prevState) => !prevState);
  };

  const phoneHandleClick = () => {
    setIsOpen(!isOpen);
  };

  const cellHandleClick = () => {
    setIsOpen1(!isOpen1);
  };

  // ------------------------------------------------- Functions End-----------------------------------------------------------//

  // --------------------------------------------------- useEffect Start----------------------------------------------------------- //
  useEffect(() => {
    const prov = "userID=" + uid;
    dispatch(GetProviderList(prov));
    dispatch(GetSettingSecurity());

    let articles =
      "limit=40&offset=0&trial=" +
      true +
      "&account=" +
      3 +
      "&uid=" +
      user_id +
      "&owner_id=" +
      null;
    dispatch(GetRoles(articles));
  }, []);
  useEffect(() => {
    if (SecurityData[3] !== undefined) {
      setPassStrong(SecurityData[3]["gl_value"]);
    }
  }, [Roles]);
  useEffect(() => {
    dropdownRefs.current = Array(LiceninputList.length)
      .fill()
      .map((_, index) => dropdownRefs.current[index] || React.createRef());

    dropdownRefs1.current = Array(LiceninputList.length)
      .fill()
      .map((_, index) => dropdownRefs1.current[index] || React.createRef());
  }, [LiceninputList]);

  useEffect(() => {
    let filteredProv = ProvList.filter(
      (provider) => Number(provider.id) !== Number(id)
    );
    setFilterVal(filteredProv);
  }, [ProvList, show]);

  useEffect(() => {
    dispatch(EmptyingCheckUser());
    dispatch(EmptyingCheckEmail());
    dispatch(EmptyingCheckPhone());
    dispatch(EmptyingCheckCell());
    setSelectedProviderIdFromBackend(mainId);
    document.addEventListener("click", handleCustomCalendar);
    document.addEventListener("click", handleClickOutside);
    const updateScreenSize = () => {
      setScreenSize({ width: window.innerWidth, height: window.innerHeight });
    };
    window.addEventListener("resize", updateScreenSize);
    updateScreenSize();
    return () => {
      window.removeEventListener("resize", updateScreenSize);
      document.removeEventListener("click", handleClickOutside);
      document.removeEventListener("click", handleCustomCalendar);
    };
  }, []);

  useEffect(() => {
    if (UserResponse !== 0) {
      setOpen(true);
    } else {
      setShowAlert(true);
      setModalMsg("Added Successfully");
      setStatus("success");
      setTimeout(() => {
        setShowAlert(false);
        setStatus("");
        setModalMsg("");
        setCheckUserName(false);
        setcheckEmail(false);
        setOpen(false);
      }, 1500);
    }

    dispatch(SetEmptyUserListResponse());
  }, [UserResponse]);

  useEffect(() => {
    if (UserUpdateResponse !== 1) {
      setOpen(true);
    } else {
      setShowAlert(true);
      setModalMsg("Updated Successfully");
      setStatus("success");
      setTimeout(() => {
        setShowAlert(false);
        setStatus("");
        setModalMsg("");
        setcheckEmail(false);
        setCheckUserName(false);
        setOpen(false);
      }, 1500);
    }

    dispatch(SetEmptyUpdateResponse());
  }, [UserUpdateResponse, showAlert]);

  useEffect(() => {
    // dispatch(EmptyingCheckUser());

    if (details.userName !== "") {
      let checkUser =
        "name=username&value=" + details.userName + "&userid=" + id;
      const delayedDispatch = setTimeout(() => {
        dispatch(GetCheckUser(checkUser));
      }, 1000);
      return () => clearTimeout(delayedDispatch);
    }
  }, [details.userName]);

  useEffect(() => {
    if (details.email !== "") {
      let checkUser = "name=email&value=" + details.email + "&userid=" + id;
      const delayedDispatch = setTimeout(() => {
        dispatch(GetCheckEmail(checkUser));
      }, 1000);
      return () => clearTimeout(delayedDispatch);
    }
  }, [details.email]);

  useEffect(() => {
    if (details.homePhone !== "") {
      let checkUser =
        "name=homephone&value=" + details.homePhone + "&userid=" + id;
      const delayedDispatch = setTimeout(() => {
        dispatch(GetCheckPhone(checkUser));
      }, 1000);
      return () => clearTimeout(delayedDispatch);
    }
  }, [details.homePhone]);

  useEffect(() => {
    if (details.cellPhone !== "") {
      let checkUser =
        "name=cellphone&value=" + details.cellPhone + "&userid=" + id;
      const delayedDispatch = setTimeout(() => {
        dispatch(GetCheckCell(checkUser));
      }, 1000);
      return () => clearTimeout(delayedDispatch);
    }
  }, [details.cellPhone]);

  useEffect(() => {
    if (CheckUserEmail === "email already exist") {
      setcheckEmail(true);
    } else {
      setcheckEmail(false);
      dispatch(EmptyingCheckEmail());
    }
    if (details.email === "") {
      setcheckEmail(false);
    }
  }, [CheckUserEmail, details.email]);

  useEffect(() => {
    if (CheckProvName === "username exist") {
      setCheckUserName(true);
    } else {
      setCheckUserName(false);
      dispatch(EmptyingCheckUser());
    }
    if (details.userName === "") {
      setCheckUserName(false);
    }
  }, [CheckProvName, details.userName]);

  useEffect(() => {
    if (CheckHomePhone === "Phone Number already exist") {
      setcheckPhone(true);
    } else {
      setcheckPhone(false);
      dispatch(EmptyingCheckPhone());
    }
    if (details.homePhone === "") {
      setcheckPhone(false);
    }
  }, [CheckHomePhone, details.homePhone]);

  useEffect(() => {
    if (CheckCellPhone === "Cell Number already exist") {
      setcheckPhoneCell(true);
    } else {
      setcheckPhoneCell(false);
      dispatch(EmptyingCheckCell());
    }
    if (details.cellPhone === "") {
      setcheckPhoneCell(false);
    }
  }, [CheckCellPhone, details.cellPhone]);
  useEffect(() => {
    let articles =
      "limit=40&offset=0&trial=" +
      true +
      "&account=" +
      3 +
      "&uid=" +
      user_id +
      "&owner_id=" +
      null;
    dispatch(GetRoles(articles));
  }, [ProvData]);

  useEffect(() => {
    if (ProvData && ProvData !== undefined && ProvData !== null) {
      const phone_countryCodeWithParentheses = ProvData?.phone?.substring(
        0,
        ProvData.phone.indexOf(")") + 1
      );
      const phone_restOfNumber = ProvData?.phone?.substring(
        ProvData.phone.indexOf(")") + 1
      );

      const cell_countryCodeWithParentheses = ProvData?.phonecell?.substring(
        0,
        ProvData?.phonecell?.indexOf(")") + 1
      );
      const cell_restOfNumber = ProvData?.phonecell?.substring(
        ProvData.phonecell.indexOf(")") + 1
      );
      setSelectedOptions(phone_countryCodeWithParentheses);
      setSelectedOptions1(cell_countryCodeWithParentheses);
      setDetails({
        fname: ProvData.fname || "",
        lname: ProvData.lname || "",
        provider: ProvData.main_pro || "",
        group: ProvData.group_id,
        roles:
          ProvData.group_id &&
          (Roles?.find((item) => item.group_id == ProvData.group_id) || {})
            .name,
        email: ProvData.email || "",
        userName: ProvData.username || "",
        homePhone: phone_restOfNumber || "",
        cellPhone: cell_restOfNumber || "",
        federalTax: ProvData.federaltaxid || "",
        deaNumber: ProvData.dea_num || "",
        jobDesc: ProvData.title || "",
        taxanomy: ProvData.taxonomy || "",
        upin: ProvData.upin || "",
        npi: ProvData.npi || "",
        speciality: ProvData.specialty || "",
      });
      if (
        ProvData.license &&
        ProvData.license.length !== 0 &&
        ProvData.license !== undefined
      ) {
        var lengthOfArray = ProvData.license.length;
        var tempArrayOfObjectsData = [];
        var tempArrayOfObjectsErr = [];
        for (let i = 0; i < lengthOfArray; i++) {
          var obj1 = {
            licentype: ProvData.license[i].licentype,
            licensenum: ProvData.license[i].licensenum,
            licenseExp: ProvData.license[i].licenseExp,
            licenseState: ProvData.license[i].licenseState,
          };
          let obj2 = {
            licentypeErr: false,
            licensenumErr: false,
            licenseExpErr: false,
            licenseStateErr: false,
            licenseExpCheckErr: false,
            licenNumberMatchErr: false,
          };
          tempArrayOfObjectsData.push(obj1);
          tempArrayOfObjectsErr.push(obj2);
        }

        setLiceninputList(tempArrayOfObjectsData);
        setLiceninputListErr(tempArrayOfObjectsErr);
        setValidDate(ProvData.license);
      }
      if (ProvData.authorized === "0") {
        setIsChecked(false);
      }
      if (ProvData.authorized === "1") {
        setIsChecked(true);
      }
    }
  }, [ProvData, Roles]);

  useEffect(() => {
    if (SecurityData[3] !== undefined) {
      setPassStrong(SecurityData[3]["gl_value"]);
    }
  }, [SecurityData]);

  useEffect(() => {
    if (selectedProviderIdFromBackend !== null) {
      const selectedOption = ProvList.find(
        (provider) =>
          Number(provider.id) === Number(selectedProviderIdFromBackend)
      );
      if (selectedOption) {
        setSelectedProviderTitle(selectedOption.title);
      }
    }
  }, [selectedProviderIdFromBackend]);

  useEffect(() => {
    const handleClickOutside1 = (event) => {
      if (cellRef.current && !cellRef.current.contains(event.target)) {
        setIsOpen1(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside1);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside1);
    };
  }, [cellRef]);

  useEffect(() => {
    const handleClickOutside2 = (event) => {
      if (phoneRef.current && !phoneRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside2);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside2);
    };
  }, [phoneRef]);

  // ------------------------------ useEffect End----------------------------------------------------------- //

  return (
    <>
      <Div>
        <Modal
          id="modal"
          show={show}
          justifyContent="center"
          style={{
            width: screenSize.width * 1.0,
            height: screenSize.height * 1.0,
          }}
          onClick={(e) => {
            if (e.target.id.includes("modal")) {
              setOpen(false);
              setSelectedProviderTitle("");
            }
          }}
        >
          <ModalContainer
            position="relative"
            justifyContent="center"
            borderRadius="8px"
            overflowX="revert"
            overflowY="revert"
          >
            <ModalDialog width="460px">
              <div id="modaldialog">
                <Form onSubmit={HandleSaveProvider}>
                  <ModalHeader padding="26px 27px 16px 29px">
                    <ModalTitle
                      fontFamily="'Open Sans',sans-serif"
                      fontWeight="600px"
                      fontSize="16px"
                      lineHight="22px"
                      color="#2E2E2E"
                      fontStyle="SemiBold"
                    >
                      {id === 0 ? "Add Users" : "Edit Users"}
                    </ModalTitle>
                    <Modclosebtn
                      type="button"
                      onClick={() => {
                        setOpen(false);
                        setSelectedProviderTitle("");
                      }}
                    >
                      <FaTimes />
                    </Modclosebtn>
                  </ModalHeader>
                  <ModalBody
                    padding="16px 27px 16px 27px"
                    //ref={modalRef}
                    overflowY="auto"
                    style={{
                      maxHeight: "450px",
                      overflowY: "auto",
                      paddingTop: "0px",
                    }}
                  >
                    <Div display="flex">
                      <Div
                        padding="16px 0px 0px 0px"
                        display="flex"
                        flexDirection="column"
                        title={details.fname}
                      >
                        <Div
                          fontFamily="'Open Sans',sans-serif"
                          fontWeight="400px"
                          fontSize="14px"
                          marginBottom="8px !important"
                        >
                          First Name
                        </Div>
                        <Input
                          id="fname"
                          style={{
                            ...(Error.fnameErr
                              ? { border: "1px solid red", borderRadius: "5px" }
                              : { border: "1px solid #cbd5e0" }),

                            fontSize: "14px",
                            borderRadius: "4px",
                            padding: "10px 0 10px 12px",
                            maxWidth: "185px",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            paddingRight: "20px",
                            marginLeft: "0",
                            //color: "rgba(54, 57, 59, 0.55)",
                            float: "left",
                          }}
                          border="1px solid #cbd5e0"
                          fontSize="14px"
                          // color="#718096"
                          borderRadius="4px"
                          padding="10px 0 10px 12px"
                          value={details.fname}
                          onChange={changeCode}
                          maxLength={16}
                        ></Input>
                        {Error.fnameErr && (
                          <ErrorMessage>
                            Please enter the First Name
                          </ErrorMessage>
                        )}
                      </Div>

                      <Div
                        padding="16px 0px 0px 0px"
                        display="flex"
                        flexDirection="column"
                        paddingLeft="20px"
                        title={details.lname}
                      >
                        <Div
                          fontFamily="'Open Sans',sans-serif"
                          fontWeight="400px"
                          fontSize="14px"
                          marginBottom="8px !important"
                        >
                          Last Name
                        </Div>
                        <Input
                          id="lname"
                          style={{
                            ...(Error.fnameErr
                              ? { border: "1px solid red", borderRadius: "5px" }
                              : { border: "1px solid #cbd5e0" }),

                            fontSize: "14px",
                            borderRadius: "4px",
                            padding: "10px 0 10px 12px",
                            maxWidth: "185px",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            paddingRight: "20px",
                            marginLeft: "0",
                            //color: "rgba(54, 57, 59, 0.55)",
                            float: "left",
                          }}
                          border="1px solid #cbd5e0"
                          fontSize="14px"
                          //color="#718096"
                          borderRadius="4px"
                          padding="10px 0 10px 12px"
                          value={details.lname}
                          onChange={changeCode}
                          maxLength={16}
                        />
                        {Error.lnameErr && (
                          <ErrorMessage>
                            Please enter the Last Name
                          </ErrorMessage>
                        )}
                      </Div>
                    </Div>

                    <Div display="flex" paddingTop="20px">
                      <Div display="inline" width="180px">
                        <DropdownContainer ref={dropdownRef}>
                          <Label>Primary Provider</Label>
                          <DropdownButton
                            type="button"
                            disabled={id !== 0 && details.provider === ""}
                            style={
                              id !== 0 && details.provider === ""
                                ? { cursor: "not-allowed" }
                                : Error.providerErr
                                ? {
                                    border: "1px solid red",
                                    borderRadius: "5px",
                                  }
                                : {}
                            }
                            width="184px"
                            onClick={() => {
                              toggleDropdown("providerDrop");
                              setError({ ...Error, providerErr: false });
                            }}
                          >
                            <div title={selectedProviderTitle}>
                              <Ptag
                                style={{
                                  fontSize: "14px",
                                  maxWidth: "120px",
                                  textOverflow: "ellipsis",
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  paddingRight: "20px !important",
                                  marginLeft: " 0 !important",
                                  color:
                                    selectedProviderTitle === "Select Provider"
                                      ? "rgba(54, 57, 59, 0.55)"
                                      : "black",
                                  float: "left",
                                }}
                              >
                                {selectedProviderTitle}
                              </Ptag>
                            </div>

                            <DropdownImage
                              marginLeft="0px"
                              marginTop="0px"
                              top="16px"
                              right="20px"
                              src={dropdownDownicon}
                            ></DropdownImage>
                          </DropdownButton>

                          {isProviderDropdownOpen && (
                            <DropdownList isOpen={isProviderDropdownOpen}>
                              {filterVal.map((option, index) => (
                                <>
                                  <DropdownItem
                                    title={option.title}
                                    key={index}
                                    onClick={() =>
                                      handleSelect(
                                        option.id,
                                        index,
                                        option.title,
                                        details.main_pro
                                      )
                                    }
                                  >
                                    {option.title}
                                  </DropdownItem>
                                </>
                              ))}
                            </DropdownList>
                          )}
                        </DropdownContainer>
                        {Error.providerErr && (
                          <ErrorMessage>
                            Please select the Provider
                          </ErrorMessage>
                        )}
                      </Div>

                      <Div display="inline" paddingLeft="26px">
                        <DropdownContainer ref={dropdownRef1}>
                          <Label>Roles</Label>
                          <DropdownButton
                            type="button"
                            style={
                              Error.rolesErr
                                ? {
                                    border: "1px solid red",
                                    borderRadius: "5px",
                                  }
                                : {}
                            }
                            width="184px"
                            onClick={() => {
                              toggleDropdown("rolesDrop");
                              setError({ ...Error, rolesErr: false });
                            }}
                          >
                            <Ptag
                              style={{
                                fontSize: "14px",
                                maxWidth: "120px",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                paddingRight: "20px !important",
                                marginLeft: " 0 !important",

                                color: details.roles
                                  ? "black"
                                  : "rgba(54, 57, 59, 0.55)",
                                float: "left",
                              }}
                            >
                              {details.roles || "Select Roles"}
                            </Ptag>

                            <DropdownImage
                              marginLeft="0px"
                              marginTop="0px"
                              top="16px"
                              right="20px"
                              src={dropdownDownicon}
                            ></DropdownImage>
                          </DropdownButton>

                          {isRolesDropdownOpen && (
                            <DropdownList isOpen={isRolesDropdownOpen}>
                              {Roles.map((option, index) => (
                                <DropdownItem
                                  key={index}
                                  onClick={() =>
                                    handleSelect1(
                                      option.name,
                                      option.group_id,
                                      index
                                    )
                                  }
                                >
                                  {option.name}
                                </DropdownItem>
                              ))}
                            </DropdownList>
                          )}
                        </DropdownContainer>
                        {Error.rolesErr && (
                          <ErrorMessage>Please select the Roles</ErrorMessage>
                        )}
                      </Div>
                    </Div>

                    <Div display="flex">
                      <Div
                        padding="16px 0px 0px 0px"
                        display="flex"
                        flexDirection="column"
                        title={details.email}
                      >
                        <Div
                          fontFamily="'Open Sans',sans-serif"
                          fontWeight="400px"
                          fontSize="14px"
                          marginBottom="8px !important"
                        >
                          Email Address
                        </Div>
                        <Input
                          id="email"
                          style={{
                            ...(Error.emailErr || checkEmail
                              ? { border: "1px solid red", borderRadius: "5px" }
                              : { border: "1px solid #cbd5e0" }),

                            fontSize: "14px",
                            borderRadius: "4px",
                            padding: "10px 0 10px 12px",
                            maxWidth: "185px",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            paddingRight: "20px",
                            marginLeft: "0",
                            //color: "rgba(54, 57, 59, 0.55)",
                            float: "left",
                          }}
                          border="1px solid #cbd5e0"
                          fontSize="14px"
                          //color="#718096"
                          borderRadius="4px"
                          padding="10px 0 10px 12px"
                          value={details.email}
                          onChange={changeCode}
                        ></Input>
                        {Error.emailErr ? (
                          <ErrorMessage>
                            Please enter the Valid Email
                          </ErrorMessage>
                        ) : (
                          checkEmail && (
                            <ErrorMessage>Email already Exist</ErrorMessage>
                          )
                        )}
                      </Div>

                      <Div
                        padding="16px 0px 0px 0px"
                        display="flex"
                        flexDirection="column"
                        paddingLeft="20px"
                      >
                        <Div
                          fontFamily="'Open Sans',sans-serif"
                          fontWeight="400px"
                          fontSize="14px"
                          marginBottom="8px !important"
                        >
                          Username
                        </Div>
                        <Input
                          id="uname"
                          style={
                            Error.userNameErr || checkUserName
                              ? { border: "1px solid red", borderRadius: "5px" }
                              : {}
                          }
                          border="1px solid #cbd5e0"
                          fontSize="14px"
                          //color="#718096"
                          borderRadius="4px"
                          padding="10px 0 10px 12px"
                          value={details.userName}
                          onChange={changeCode}
                          autoComplete="off"
                          maxLength={16}
                        ></Input>
                        {Error.userNameErr ? (
                          <ErrorMessage>
                            Please enter the User Name
                          </ErrorMessage>
                        ) : (
                          checkUserName && (
                            <ErrorMessage>UserName already Exist</ErrorMessage>
                          )
                        )}
                      </Div>
                    </Div>
                    {id === 0 && (
                      <Div>
                        <Div display="flex">
                          <Div
                            padding="16px 0px 0px 0px"
                            display="flex"
                            flexDirection="column"
                            position="relative"
                          >
                            <Div
                              fontFamily="'Open Sans',sans-serif"
                              fontWeight="400px"
                              fontSize="14px"
                              marginBottom="8px !important"
                            >
                              Password
                            </Div>
                            <Input
                              id="pass"
                              style={
                                Error.passwordErr || lenErr || strength
                                  ? {
                                      border: "1px solid red",
                                      borderRadius: "5px",
                                    }
                                  : {}
                              }
                              border="1px solid #cbd5e0"
                              fontSize="14px"
                              // color="#718096"
                              borderRadius="4px"
                              padding="10px 0 10px 12px"
                              value={details.password}
                              onChange={changeCode}
                              autoComplete
                              type={PasswordIS ? "password" : "text"}
                            ></Input>
                            {PasswordIS ? (
                              <FaRegEye
                                onClick={handlesetPasswordIS}
                                style={{
                                  marginTop: "36px",
                                  position: "absolute",
                                  right: "10%",
                                  cursor: "pointer",
                                  color: "#7d8592",
                                }}
                              />
                            ) : (
                              <FaRegEyeSlash
                                onClick={handlesetPasswordIS}
                                style={{
                                  marginTop: "36px",
                                  position: "absolute",
                                  right: "10%",
                                  cursor: "pointer",
                                  color: "#7d8592",
                                }}
                              />
                            )}
                            {Error.passwordErr ? (
                              <ErrorMessage>
                                Please enter the Password
                              </ErrorMessage>
                            ) : lenErr ? (
                              <ErrorMessage>
                                {`Minimum ${minimum} characters are required`}
                              </ErrorMessage>
                            ) : strength ? (
                              <ErrorMessage>
                                Should contain atleast one uppercase, lowercase,
                                number and special character
                              </ErrorMessage>
                            ) : (
                              ""
                            )}
                          </Div>

                          <Div
                            padding="16px 0px 0px 0px"
                            display="flex"
                            flexDirection="column"
                            paddingLeft="20px"
                            position="relative"
                          >
                            <Div
                              fontFamily="'Open Sans',sans-serif"
                              fontWeight="400px"
                              fontSize="14px"
                              marginBottom="8px !important"
                            >
                              Confirm Password
                            </Div>
                            <Input
                              id="confirmpass"
                              style={
                                Error.confirmPasswordErr ||
                                Error.comparePassErr ||
                                lenErr ||
                                strength
                                  ? {
                                      border: "1px solid red",
                                      borderRadius: "5px",
                                    }
                                  : {}
                              }
                              border="1px solid #cbd5e0"
                              fontSize="14px"
                              // color="#718096"
                              borderRadius="4px"
                              padding="10px 0 10px 12px"
                              value={details.confirmPassword}
                              onChange={changeCode}
                              type={PasswordIS2 ? "password" : "text"}
                              autoComplete="off"
                            ></Input>
                            {PasswordIS2 ? (
                              <FaRegEye
                                onClick={handlesetPasswordIS2}
                                style={{
                                  marginTop: "36px",
                                  position: "absolute",
                                  right: "10%",
                                  cursor: "pointer",
                                  color: "#7d8592",
                                }}
                              />
                            ) : (
                              <FaRegEyeSlash
                                onClick={handlesetPasswordIS2}
                                style={{
                                  marginTop: "36px",
                                  position: "absolute",
                                  right: "10%",
                                  cursor: "pointer",
                                  color: "#7d8592",
                                }}
                              />
                            )}
                            {Error.confirmPasswordErr ? (
                              <ErrorMessage>
                                Please enter the Confirm Password
                              </ErrorMessage>
                            ) : Error.comparePassErr ? (
                              <ErrorMessage>
                                Confirm password should be same as Password
                              </ErrorMessage>
                            ) : (
                              strength && (
                                <ErrorMessage>
                                  Should contain atleast one uppercase,
                                  lowercase, number and special character
                                </ErrorMessage>
                              )
                            )}
                          </Div>
                        </Div>
                        <p
                          style={{
                            paddingLeft: "5px",
                            paddingRight: "5px",
                            fontSize: "10px",
                            marginTop: "5px",
                          }}
                        >
                          {`Requirements: Minimum ${minimum} characters.`}
                          {passStrong === "1" &&
                            "Should contain atleast one uppercase, lowercase, number and special character"}
                        </p>
                      </Div>
                    )}

                    <Div display="flex">
                      <Div
                        padding="16px 0px 0px 0px"
                        display="flex"
                        flexDirection="column"
                      >
                        <Div
                          fontFamily="'Open Sans',sans-serif"
                          fontWeight="400px"
                          fontSize="14px"
                          marginBottom="8px !important"
                        >
                          Home Phone
                        </Div>
                        <Div display="flex">
                          <CustomSelect ref={phoneRef}>
                            <SelectButton type='button' onClick={phoneHandleClick}>
                              <span>
                                {selectedOptions ? selectedOptions : "(+1)"}
                              </span>
                              <Image
                                paddingLeft="7px"
                                src={DropdownIcon}
                                height="8px"
                                width="18px"
                              ></Image>
                            </SelectButton>
                            {isOpen && (
                              <OptionList>
                                {sortedCountryCode.map((option) => (
                                  <OptionItem
                                    key={option.country}
                                    onClick={() =>
                                      handleSelectOption(option.number)
                                    }
                                  >
                                    {option.full_name} {option.number}
                                  </OptionItem>
                                ))}
                              </OptionList>
                            )}
                          </CustomSelect>
                          <Input
                            id="homephone"
                            style={
                              Error.homePhoneErr || checkPhone
                                ? {
                                    border: "1px solid red",
                                    borderRadius: "5px",
                                  }
                                : {}
                            }
                            border="1px solid #cbd5e0"
                            fontSize="14px"
                            // color="#718096"
                            borderRadius="4px"
                            padding="10px 0 10px 12px"
                            value={details.homePhone}
                            onChange={changeCode}
                            maxLength={10}
                            width="65%"
                          />
                        </Div>

                        {Error.homePhoneErr ? (
                          <ErrorMessage>
                            Please enter the Home Phone
                          </ErrorMessage>
                        ) : (
                          checkPhone && (
                            <ErrorMessage>
                              Home Phone Already Exist
                            </ErrorMessage>
                          )
                        )}
                      </Div>

                      <Div
                        padding="16px 0px 0px 0px"
                        display="flex"
                        flexDirection="column"
                        paddingLeft="20px"
                      >
                        <Div
                          fontFamily="'Open Sans',sans-serif"
                          fontWeight="400px"
                          fontSize="14px"
                          marginBottom="8px !important"
                        >
                          Cell Phone
                        </Div>
                        <Div display="flex">
                          <CustomSelect ref={cellRef}>
                            <SelectButton type='button' onClick={cellHandleClick}>
                              <SpanTag>
                                {selectedOptions1 ? selectedOptions1 : "(+1)"}
                              </SpanTag>
                              <Image
                                height="8px"
                                width="18px"
                                paddingLeft="7px"
                                src={DropdownIcon}
                              ></Image>
                            </SelectButton>
                            {isOpen1 && (
                              <OptionList>
                                {sortedCountryCode.map((option) => (
                                  <OptionItem
                                    key={option.country}
                                    onClick={() =>
                                      handleSelectOption1(option.number)
                                    }
                                  >
                                    {option.full_name} {option.number}
                                  </OptionItem>
                                ))}
                              </OptionList>
                            )}
                          </CustomSelect>
                          <Input
                            id="cellphone"
                            border="1px solid #cbd5e0"
                            fontSize="14px"
                            style={
                              checkPhoneCell
                                ? {
                                    border: "1px solid red",
                                    borderRadius: "5px",
                                  }
                                : {}
                            }
                            borderRadius="4px"
                            padding="10px 0 10px 12px"
                            value={details.cellPhone}
                            onChange={changeCode}
                            width="65%"
                            maxLength={10}
                          ></Input>
                        </Div>
                        {checkPhoneCell && (
                          <ErrorMessage>PhoneCell Already Exist</ErrorMessage>
                        )}
                      </Div>
                    </Div>

                    <Div display="flex" paddingTop="20px">
                      {/* <Checkbox>
                      <CheckboxInput
                        id="checkbox"
                        type="checkbox"
                        checked={isChecked}
                        onChange={handleCheckboxChange}
                      />
                      <CheckboxCheckmark checked={isChecked} />
                    </Checkbox> */}
                      <Div position="relative">
                        <TogggleCheck
                          checked={isChecked}
                          type="checkbox"
                          onChange={handleCheckboxChange}
                          disabled={staff}
                        />
                        <CheckBoxLabel htmlFor="checkbox" />
                      </Div>
                      <Div
                        fontFamily="'Open Sans',sans-serif"
                        fontWeight="400px"
                        fontSize="14px"
                        marginBottom="8px !important"
                        paddingLeft="10px"
                      >
                        Check for Provider
                      </Div>
                    </Div>
                    {isChecked && (
                      <>
                        <Div display="flex">
                          <Div
                            padding="16px 0px 0px 0px"
                            display="flex"
                            flexDirection="column"
                          >
                            <Div
                              fontFamily="'Open Sans',sans-serif"
                              fontWeight="400px"
                              fontSize="14px"
                              marginBottom="8px !important"
                            >
                              Federal Tax ID
                            </Div>
                            <Input
                              id="fedtax"
                              style={
                                Error.federalTaxErr
                                  ? {
                                      border: "1px solid red",
                                      borderRadius: "5px",
                                    }
                                  : {}
                              }
                              border="1px solid #cbd5e0"
                              fontSize="14px"
                              // color="#718096"
                              borderRadius="4px"
                              padding="10px 0 10px 12px"
                              value={details.federalTax}
                              onChange={changeCode}
                            ></Input>
                            {Error.federalTaxErr && (
                              <ErrorMessage>
                                Please enter the FederalTax
                              </ErrorMessage>
                            )}
                          </Div>

                          <Div
                            padding="16px 0px 0px 0px"
                            display="flex"
                            flexDirection="column"
                            paddingLeft="20px"
                          >
                            <Div
                              fontFamily="'Open Sans',sans-serif"
                              fontWeight="400px"
                              fontSize="14px"
                              marginBottom="8px !important"
                            >
                              DEA Number
                            </Div>
                            <Input
                              id="deanumber"
                              style={
                                Error.deaNumberErr
                                  ? {
                                      border: "1px solid red",
                                      borderRadius: "5px",
                                    }
                                  : {}
                              }
                              border="1px solid #cbd5e0"
                              fontSize="14px"
                              // color="#718096"
                              borderRadius="4px"
                              padding="10px 0 10px 12px"
                              value={details.deaNumber}
                              onChange={changeCode}
                            ></Input>
                            {Error.deaNumberErr && (
                              <ErrorMessage>
                                Please enter the DEA Number
                              </ErrorMessage>
                            )}
                          </Div>
                        </Div>

                        <Div display="flex">
                          <Div
                            padding="16px 0px 0px 0px"
                            display="flex"
                            flexDirection="column"
                          >
                            <Div
                              fontFamily="'Open Sans',sans-serif"
                              fontWeight="400px"
                              fontSize="14px"
                              marginBottom="8px !important"
                            >
                              Job Description
                            </Div>
                            <Input
                              id="jobdesc"
                              border="1px solid #cbd5e0"
                              fontSize="14px"
                              borderRadius="4px"
                              padding="10px 0 10px 12px"
                              value={details.jobDesc}
                              onChange={changeCode}
                            ></Input>
                          </Div>

                          <Div
                            padding="16px 0px 0px 0px"
                            display="flex"
                            flexDirection="column"
                            paddingLeft="20px"
                          >
                            <Div
                              fontFamily="'Open Sans',sans-serif"
                              fontWeight="400px"
                              fontSize="14px"
                              marginBottom="8px !important"
                            >
                              Taxonomy
                            </Div>
                            <Input
                              id="tax"
                              style={
                                Error.taxanomyErr
                                  ? {
                                      border: "1px solid red",
                                      borderRadius: "5px",
                                    }
                                  : {}
                              }
                              border="1px solid #cbd5e0"
                              fontSize="14px"
                              //color="#718096"
                              borderRadius="4px"
                              padding="10px 0 10px 12px"
                              value={details.taxanomy}
                              onChange={changeCode}
                            ></Input>
                            {Error.taxanomyErr && (
                              <ErrorMessage>Please enter the Tax</ErrorMessage>
                            )}
                          </Div>
                        </Div>

                        <Div display="flex">
                          <Div
                            padding="16px 0px 0px 0px"
                            display="flex"
                            flexDirection="column"
                          >
                            <Div
                              fontFamily="'Open Sans',sans-serif"
                              fontWeight="400px"
                              fontSize="14px"
                              marginBottom="8px !important"
                            >
                              UPIN
                            </Div>
                            <Input
                              id="upin"
                              style={
                                Error.upinErr
                                  ? {
                                      border: "1px solid red",
                                      borderRadius: "5px",
                                    }
                                  : {}
                              }
                              border="1px solid #cbd5e0"
                              fontSize="14px"
                              //color="#718096"
                              borderRadius="4px"
                              padding="10px 0 10px 12px"
                              value={details.upin}
                              onChange={changeCode}
                            ></Input>
                            {Error.upinErr && (
                              <ErrorMessage>Please enter the UPIN</ErrorMessage>
                            )}
                          </Div>

                          <Div
                            padding="16px 0px 0px 0px"
                            display="flex"
                            flexDirection="column"
                            paddingLeft="20px"
                          >
                            <Div
                              fontFamily="'Open Sans',sans-serif"
                              fontWeight="400px"
                              fontSize="14px"
                              marginBottom="8px !important"
                            >
                              NPI
                            </Div>
                            <Input
                              id="npi"
                              style={
                                Error.npiErr
                                  ? {
                                      border: "1px solid red",
                                      borderRadius: "5px",
                                    }
                                  : {}
                              }
                              border="1px solid #cbd5e0"
                              fontSize="14px"
                              //color="#718096"
                              borderRadius="4px"
                              padding="10px 0 10px 12px"
                              value={details.npi}
                              onChange={changeCode}
                            ></Input>
                            {Error.npiErr && (
                              <ErrorMessage>Please enter the NPI</ErrorMessage>
                            )}
                          </Div>
                        </Div>

                        <Div paddingTop="20px">
                          <DropdownContainer ref={dropdownRef3}>
                            <Label>Speciality</Label>
                            <DropdownButton
                              type="button"
                              id="dropspecial"
                              disabled
                              style={{ cursor: "not-allowed" }}
                              width="390px"
                              onClick={() => {
                                toggleDropdown("specialityDrop");
                              }}
                            >
                              <Ptag
                                style={{
                                  maxWidth: "120px",
                                  textOverflow: "ellipsis",
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  paddingRight: "20px !important",
                                  marginLeft: " 0 !important",
                                  color: "rgba(54, 57, 59, 0.55)",
                                  float: "left",
                                }}
                              >
                                {/* {details.speciality || "Select Speciality"} */}
                                {"Select Speciality"}
                              </Ptag>

                              <DropdownImage
                                marginLeft="0px"
                                marginTop="0px"
                                top="16px"
                                right="20px"
                                src={dropdownDownicon}
                              ></DropdownImage>
                            </DropdownButton>

                            {isSpecialityDropdownOpen && (
                              <DropdownList isOpen={isSpecialityDropdownOpen}>
                                {ProvList.map((option, index) => (
                                  <DropdownItem
                                    key={index}
                                    onClick={() =>
                                      handleSelect3(option.title, index)
                                    }
                                  >
                                    {option.title}
                                  </DropdownItem>
                                ))}
                              </DropdownList>
                            )}
                          </DropdownContainer>

                          {Error.codeTypeErr && (
                            <ErrorMessage>
                              Please select the Code Type
                            </ErrorMessage>
                          )}
                        </Div>
                        <div>
                          {LiceninputList !== undefined &&
                            LiceninputList.map((x, i) => (
                              <>
                                <Div display="flex">
                                  <Div
                                    padding="16px 0px 0px 0px"
                                    display="flex"
                                    flexDirection="column"
                                  >
                                    <DropdownContainer
                                      id="licensetype"
                                      ref={dropdownRefs1.current[i]}
                                    >
                                      <Label>License Type</Label>
                                      <DropdownButton
                                        type="button"
                                        width="190px"
                                        onClick={() => {
                                          setIsLiTypeDropdownOpenArray(
                                            (prevState) => {
                                              const newState = [...prevState];
                                              newState[i] = !newState[i];
                                              return newState;
                                            }
                                          );
                                        }}
                                        isOpen={isLiTypeDropdownOpenArray[i]}
                                        style={
                                          LiceninputListErr[i] !== undefined &&
                                          LiceninputListErr[i].licentypeErr
                                            ? {
                                                border: "1px solid red",
                                                borderRadius: "5px",
                                              }
                                            : {}
                                        }
                                      >
                                        <Ptag
                                          style={{
                                            maxWidth: "120px",
                                            textOverflow: "ellipsis",
                                            whiteSpace: "nowrap",
                                            overflow: "hidden",
                                            paddingRight: "20px !important",
                                            marginLeft: " 0 !important",
                                            color: x.licentype
                                              ? "black"
                                              : "rgba(54, 57, 59, 0.55)",
                                            float: "left",
                                          }}
                                        >
                                          {x.licentype || "Select License Type"}
                                        </Ptag>

                                        <DropdownImage
                                          marginLeft="0px"
                                          marginTop="0px"
                                          top="16px"
                                          right="20px"
                                          src={dropdownDownicon}
                                        ></DropdownImage>
                                      </DropdownButton>

                                      {isLiTypeDropdownOpenArray[i] && (
                                        <DropdownList
                                          isOpen={isLiTypeDropdownOpenArray[i]}
                                          style={{
                                            position: "absolute",
                                            top:
                                              i === LiceninputList.length - 1
                                                ? "-218px"
                                                : "70px",
                                          }}
                                        >
                                          {liTypeList.map((option, index) => (
                                            <DropdownItem
                                              name="licentype"
                                              key={index}
                                              value={option}
                                              onClick={() =>
                                                handleSelectDrop(
                                                  option,
                                                  i,
                                                  "licentype"
                                                )
                                              }
                                            >
                                              {option}
                                            </DropdownItem>
                                          ))}
                                        </DropdownList>
                                      )}
                                    </DropdownContainer>

                                    {LiceninputListErr[i] !== undefined &&
                                      LiceninputListErr[i].licentypeErr && (
                                        <ErrorMessage>
                                          Please Enter License Type
                                        </ErrorMessage>
                                      )}
                                  </Div>
                                  <Div
                                    padding="24px 0px 0px 0px"
                                    display="flex"
                                    flexDirection="column"
                                    paddingLeft="20px"
                                  >
                                    <Div
                                      fontFamily="'Open Sans',sans-serif"
                                      fontWeight="400px"
                                      fontSize="14px"
                                      marginBottom="8px !important"
                                    >
                                      License #
                                    </Div>
                                    <Input
                                      id="license"
                                      border="1px solid #cbd5e0"
                                      fontSize="14px"
                                      // color="#718096"
                                      borderRadius="4px"
                                      padding="10px 0 10px 12px"
                                      name="licensenum"
                                      style={
                                        LiceninputListErr[i] !== undefined &&
                                        (LiceninputListErr[i].licensenumErr ||
                                          (LicenseMatchedErr(i) && i > 0))
                                          ? {
                                              border: "1px solid red",
                                              borderRadius: "5px",
                                            }
                                          : {}
                                      }
                                      value={x.licensenum}
                                      onChange={(e) => changeLicenseCode(e, i)}
                                    ></Input>
                                    {LiceninputListErr[i] !== undefined &&
                                      LiceninputListErr[i].licensenumErr && (
                                        <ErrorMessage>
                                          Please Enter License Number
                                        </ErrorMessage>
                                      )}

                                    {LiceninputListErr[i] !== undefined &&
                                      LicenseMatchedErr(i) &&
                                      i > 0 && (
                                        <ErrorMessage>
                                          Please Enter Different License Number
                                        </ErrorMessage>
                                      )}
                                  </Div>
                                </Div>

                                <Div display="flex">
                                  <Div
                                    padding="16px 0px 0px 0px"
                                    display="flex"
                                    flexDirection="column"
                                  >
                                    <DropdownContainer
                                      id="licenseState"
                                      ref={dropdownRefs.current[i]}
                                    >
                                      <Label>License State</Label>
                                      <DropdownButton
                                        type="button"
                                        //id="licenseState"
                                        width="190px"
                                        onClick={(e) =>
                                          handleToggleDropdown(i, e)
                                        }
                                        isOpen={isLiStateDropdownOpenArray[i]}
                                        style={
                                          LiceninputListErr[i] !== undefined &&
                                          LiceninputListErr[i].licenseStateErr
                                            ? {
                                                border: "1px solid red",
                                                borderRadius: "5px",
                                                alignItems: "center",
                                              }
                                            : { alignItems: "center" }
                                        }
                                      >
                                        <Ptag
                                          style={{
                                            maxWidth: "120px",
                                            textOverflow: "ellipsis",
                                            whiteSpace: "nowrap",
                                            overflow: "hidden",
                                            paddingRight: "20px !important",
                                            marginLeft: " 0 !important",

                                            color: x.licenseState
                                              ? "black"
                                              : "rgba(54, 57, 59, 0.55)",
                                            float: "left",
                                          }}
                                        >
                                          {x.licenseState ||
                                            " Select License State "}
                                        </Ptag>

                                        <DropdownImage
                                          marginLeft="0px"
                                          marginTop="0px"
                                          top="16px"
                                          right="20px"
                                          src={dropdownDownicon}
                                        ></DropdownImage>
                                      </DropdownButton>
                                      {isLiStateDropdownOpenArray[i] && (
                                        <DropdownList
                                          style={{
                                            position: "absolute",
                                            top:
                                              i === LiceninputList.length - 1
                                                ? "-218px"
                                                : "70px",
                                          }}
                                          isOpen={isLiStateDropdownOpenArray[i]}
                                        >
                                          {options.map((group, index) => (
                                            <React.Fragment key={index}>
                                              <li
                                                style={{ fontWeight: "bold" }}
                                              >
                                                {group.label}
                                              </li>
                                              {group.values.map(
                                                (value, subIndex) => (
                                                  <DropdownItem
                                                    name="licenseState"
                                                    key={subIndex}
                                                    value={value}
                                                    onClick={() =>
                                                      handleSelectDrop(
                                                        value,
                                                        i,
                                                        "licenseState"
                                                      )
                                                    }
                                                  >
                                                    {value}
                                                  </DropdownItem>
                                                )
                                              )}
                                            </React.Fragment>
                                          ))}
                                        </DropdownList>
                                      )}
                                    </DropdownContainer>
                                    {LiceninputListErr[i] !== undefined &&
                                      LiceninputListErr[i].licenseStateErr && (
                                        <ErrorMessage>
                                          Please Select License State
                                        </ErrorMessage>
                                      )}
                                  </Div>

                                  <Div
                                    padding="16px 0px 0px 0px"
                                    display="flex"
                                    flexDirection="column"
                                    paddingLeft="20px"
                                    style={
                                      LiceninputListErr[i] !== undefined &&
                                      (LiceninputListErr[i].licenseExpErr ||
                                        LiceninputListErr[i].licenseExpCheckErr)
                                        ? { marginBottom: "9px" }
                                        : {}
                                    }
                                  >
                                    <Div
                                      fontFamily="'Open Sans',sans-serif"
                                      fontWeight="400px"
                                      fontSize="14px"
                                      marginBottom="8px !important"
                                    >
                                      License Expires
                                    </Div>

                                    <Div
                                      position="relative"
                                      className="viewdatepicker add-user"
                                      id={
                                        (i === LiceninputList.length - 1 ||
                                          i === LiceninputList.length - 2) &&
                                        `add-customdatepic-user`
                                      }
                                    >
                                      <Div
                                        style={{
                                          position: "relative",
                                          // zIndex: 1,
                                          paddingTop: "8px",
                                          width: "184px",
                                        }}
                                      >
                                        <CustomDatePicker
                                          id="CustomDatePicker"
                                          className="viewcustompicker"
                                          fontFamily="'Open Sans', sans-serif"
                                          width="184px"
                                          height="36px"
                                          style={{ marginTop: "9px" }}
                                          backgroundColour="#f7f7f7 !important"
                                          border={
                                            LiceninputListErr[i] !==
                                              undefined &&
                                            (LiceninputListErr[i]
                                              .licenseExpErr ||
                                              LiceninputListErr[i]
                                                .licenseExpCheckErr)
                                              ? "1px solid red !important"
                                              : "1px solid rgba(34,36,38,.15)"
                                          }
                                          borderRadius="4px"
                                          placeholder="YYYY-MM-DD"
                                          value={x.licenseExp}
                                          onChange={(e) => {
                                            let d = moment(
                                              e.target.value,
                                              "YYYY-MM-DD",
                                              true
                                            ).isValid();

                                            const name = "licenseExp";
                                            const list = [...LiceninputList];
                                            list[i][name] = e.target.value;
                                            setLiceninputList(list);
                                            const list1 = [
                                              ...LiceninputListErr,
                                            ];
                                            list1[i].licenseExpCheckErr = false;
                                            list1[i].licenseExpErr = false;
                                            setLiceninputListErr(list1);
                                            if (e.target.value === "") {
                                              const list = [...LiceninputList];
                                              list[i][name] = "";
                                              setLiceninputList(list);
                                            } else if (d) {
                                              const list = [...LiceninputList];
                                              list[i]["licenseExpCheck"] =
                                                e.target.value;
                                              setLiceninputList(list);

                                              let check = false;
                                              if (check) {
                                                const name = "licenseExp";
                                                const list = [
                                                  ...LiceninputList,
                                                ];
                                                list[i][name] = e.target.value;
                                                list[i]["licenseExpCheck"] =
                                                  e.target.value;
                                                setLiceninputList(list);
                                              } else {
                                                setValidDate(e.target.value);
                                              }
                                            }
                                            const currentDate = new Date();
                                            const year =
                                              currentDate.getFullYear();
                                            const month =
                                              currentDate.getMonth() + 1;
                                            const day = currentDate.getDate();
                                            let today = `${year}-${month}-${day}`;
                                            if (
                                              new Date(e.target.value) <
                                                new Date(today) ||
                                              !d
                                            ) {
                                              const list1 = [
                                                ...LiceninputListErr,
                                              ];
                                              list1[
                                                i
                                              ].licenseExpCheckErr = true;
                                              setLiceninputListErr(list1);
                                            } else {
                                              const list1 = [
                                                ...LiceninputListErr,
                                              ];
                                              list1[
                                                i
                                              ].licenseExpCheckErr = false;
                                              setLiceninputListErr(list1);

                                              const name = "licenseExp";
                                              const list = [...LiceninputList];
                                              list[i][name] = e.target.value;
                                              setLiceninputList(list);
                                            }
                                          }}
                                          onClick={(e) => handlepicker(e, i)}
                                          onKeyPress={(event) => {
                                            if (
                                              !/[0-9]/.test(event.key) ||
                                              event.target.value.length > 9
                                            ) {
                                              event.preventDefault();
                                            }
                                          }}
                                          onKeyDown={(e) => keyDownExpiry(e)}
                                          onResultSelect={(e) =>
                                            dateSelect(e, i)
                                          }
                                          showPicker={
                                            showpicker === i ? true : false
                                          }
                                          validDate={x.licenseExp}
                                          closePicker={closePicker}
                                          min={currentDate}
                                        />
                                        <Imagecalendar
                                          id="imgCal"
                                          top="15px"
                                          left="80%"
                                          onClick={(e) => handlepicker(e)}
                                          src={calendar_icon_custom}
                                        ></Imagecalendar>
                                      </Div>
                                    </Div>
                                    {LiceninputListErr[i] !== undefined &&
                                    LiceninputListErr[i].licenseExpErr ? (
                                      <ErrorMessage>
                                        Please Select License Expiry Date
                                      </ErrorMessage>
                                    ) : (
                                      LiceninputListErr[i] !== undefined &&
                                      LiceninputListErr[i]
                                        .licenseExpCheckErr && (
                                        <ErrorMessage>
                                          Please Select Proper Expiry Date
                                        </ErrorMessage>
                                      )
                                    )}
                                  </Div>

                                  <Div
                                    // position="absolute"
                                    right="0px"
                                    paddingTop="54px"
                                    paddingRight="8px"
                                    id="delete"
                                  >
                                    {LiceninputList.length !== 1 && (
                                      <FiTrash2
                                        style={{
                                          width: "20px",
                                          height: "20px",
                                          fill: "#ffffff",
                                          stroke: "#f24822",
                                          marginLeft: "20px",
                                          cursor: "pointer",
                                        }}
                                        onClick={() => handleRemoveClick(i)}
                                      />
                                    )}
                                  </Div>
                                </Div>
                                {i !== LiceninputList.length - 1 && (
                                  <p
                                    style={{
                                      marginTop: "20px",
                                      marginLeft: " -24px",
                                      marginRight: "-24px",
                                      borderTop: "1px solid #d8e0f0",
                                    }}
                                  />
                                )}
                              </>
                            ))}
                        </div>
                        <Div>
                          <Button
                            id="addlic"
                            width="160px"
                            height="36px"
                            background="#FFF"
                            borderRadius="4px"
                            //color="#2C7BE5"
                            border="0.50px #2C7BE5 solid"
                            cursor="pointer"
                            marginTop="24px"
                            onClick={(e) => handleAddClick(e)}
                            hoverBackColor="rgba(244, 246, 249, 0.75)"
                            activeBackgroundColor="rgba(44, 123, 229, 0.10)"
                            type="button"
                          >
                            + Add Licenses
                          </Button>
                        </Div>
                      </>
                    )}
                  </ModalBody>
                  <ModalFooter padding="1rem 25px">
                    <ButtonGroup
                      type="button"
                      fontSize="14px"
                      fontWeight="600"
                      borderRadius="4px"
                      border="1px solid #2C7BE5"
                      padding="9px 27px 8px 28px"
                      margin="0 16px 0 0px"
                      background="#FFF"
                      color="#2C7BE5"
                      cursor="pointer"
                      onClick={() => {
                        setOpen(false);
                        setSelectedProviderTitle("");
                        // handleEmptyDetails();
                      }}
                      hoverBackColor="rgba(244, 246, 249, 0.75)"
                      activeBackgroundColor="rgba(44, 123, 229, 0.10)"
                    >
                      <Span fontSize="14px" fontWeight="600">
                        Close
                      </Span>
                    </ButtonGroup>
                    <ButtonGroup
                      type="submit"
                      borderRadius="4px"
                      border="1px solid #2C7BE5"
                      padding="9px 34px 8px 34px"
                      background="#2C7BE5"
                      color="#FFFFFF"
                      cursor="pointer"
                      disabled={dis}
                      isDisabled={dis}
                      hoverBackColor="#005FB2"
                      activeBackgroundColor="rgba(44, 123, 229, 0.75)"
                    >
                      <Span
                        fontSize="14px"
                        fontWeight="600"
                        fontFamily="'Open Sans', sans-serif"
                      >
                        {id === 0 ? "Save" : "Update"}
                      </Span>
                    </ButtonGroup>
                  </ModalFooter>
                </Form>
              </div>
            </ModalDialog>
          </ModalContainer>
        </Modal>
      </Div>
      {showAlert && (
        <AlertModal show={showAlert} msg={modalMsg} status={status} />
      )}
    </>
  );
};

export default AddProvider;
