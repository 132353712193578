import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalContainer,
  ModalTitle,
  Modclosebtn,
  ButtonGroup,
  Div,
  Input,
  Span,
  SpanTag,
  ModalHeader,
  ModalBody,
  ModalFooter,
  SearchIconSVG,
  DropDownDiv,
  DropdownOptions,
  DropdownImage,
  SelectInput,
  Text,
} from "../../StyledComponents";
import {
  AllergyDropdown,
  AllergyOption,
} from "../../Patient/PatientDemo/styles";
import { TextArea } from "../styles";
import { FaTimes } from "react-icons/fa";
import { ModalDialog } from "../../StyledComponents/AddCard/Styles";
import { AiOutlineSearch } from "react-icons/ai";
import dropdownDownicon from "../../../Assets/images/dropdownDownicon.png";
import { ErrorMessage } from "../../Patient/BillandInsurance/styles";
import { useSelector, useDispatch } from "react-redux";
import {
  GetAllerUpdateList,
  GetAllerInsertList,
  clearAllergyData,
} from "../../../StateManagement/Reducers/HealthState";
import { GetDrugList } from "../../../StateManagement/Reducers/MedicalState";

const AddAllergy = (props) => {
  //-------------------------------------------UseState and Variables Start--------------------------------------------------------//
  const [screenSize, setScreenSize] = useState({ width: 0, height: 0 });
  const [Show_Val, setShow_Val] = useState(false);
  const [Show_notes, setShow_notes] = useState(false);
  const [DrugType, setDrugType] = useState("");
  const [ReacType, setReacType] = useState("");
  const [StaType, setStaType] = useState("");
  const [AllergyType, setAllergyType] = useState("");
  const [notes, setnotes] = useState("");
  const [SevType, setSevType] = useState("");
  const [AllergyTypeErr, setAllergyTypeErr] = useState(false);
  const [SevTypeErr, setSevTypeErr] = useState(false);
  const [ReacTypeErr, setReacTypeErr] = useState(false);
  const [StaTypeErr, setStaTypeErr] = useState(false);
  const [DrugTypeErr, setDrugTypeErr] = useState(false);
  const [ShowOptions, setShowOptions] = useState(false);
  const [ShowOptions1, setShowOptions1] = useState(false);
  const [ShowOptions2, setShowOptions2] = useState(false);
  const [ShowOptions3, setShowOptions3] = useState(false);
  const [Statuslabel, setStatuslabel] = useState("");
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [btnDisabled, setbtnDisabled] = useState(false);
  const [drumatchgerr, setDrugMatcherr] = useState(false);
  const [selectedValue, setSelectedValue] = useState("");
  const [disa, setDisa] = useState(false);

  const dispatch = useDispatch();
  //-------------------------------------------UseState and Variables End--------------------------------------------------------//
  // ------------------------------------------------- Functions Start-----------------------------------------------------------//

  const DropdownOptionList1 = [
    { label: "Acute Kidney failure", id: "1", Key: "Akf" },
    { label: "Anaphylaxis", id: "2", Key: "Anaphylaxis" },
    { label: "Athralgia ", id: "3", Key: "Athralgia" },
    { label: "Chills", id: "4", Key: "Chills" },
    { label: "Cough", id: "5", Key: "Cough" },
    { label: "Fever", id: "6", Key: "Fever" },
    { label: "Headache", id: "7", Key: "Headache" },
    { label: "Hives", id: "8", Key: "Hives" },
    { label: "Malaise/Fatigue", id: "9", Key: "Malaise" },
    { label: "Headache", id: "10", Key: "Headache" },
    { label: "Myalgia", id: "11", Key: "Myalgia" },
    { label: "Nasal Congestion", id: "13", Key: "Nasal" },
    { label: "Nausea", id: "14", Key: "Nausea" },
    {
      label: "Pain/soreness at injection site",
      id: "15",
      Key: "Pain/soreness at injection site",
    },
    { label: "Rash", id: "16", Key: "Rash" },
    { label: "Respiratory distress", id: "17", Key: "Respiratory distress" },
    { label: "Rhinorrhea", id: "18", Key: "Rhinorrhea" },
    {
      label: "Shortness of breath/difficulty breathing",
      id: "19",
      Key: "Shortness of breath/difficulty breathing",
    },
    { label: "Sore throat", id: "20", Key: "Sore throat" },
    { label: "Swelling", id: "21", Key: "Swelling" },
  ];
  const DropdownOptionList = [
    { label: "Specific Drug allergy", id: "1", Key: "Specific Drug allergy" },
    { label: "Drug Class allergy", id: "2", Key: "Drug Class allergy" },
    { label: "Non-Drug allergy", id: "3", Key: "Non-Drug allergy" },
    {
      label: "No Known Drug Allergies (NKDA)",
      id: "4",
      Key: "No Known Drug Allergies (NKDA)",
    },
  ];
  const DropdownOptionList2 = [
    { label: "Fatal", id: "1", Key: "Specific Drug allergy" },
    { label: "Severe", id: "2", Key: "Severe" },
    { label: "Moderate ", id: "3", Key: "Moderate" },
    { label: "Mild ", id: "4", Key: "MIld" },
    { label: "Unknown ", id: "5", Key: "Unknown" },
  ];
  const DropdownOptionList3 = [
    { label: "Active", id: "1", Key: "Active" },
    { label: "Inactive", id: "2", Key: "Inactive" },
    { label: "Resolved", id: "3", Key: "Resolved" },
  ];

  const allergyDetails = useSelector((state) => state.Health.EditList);
  const DrugList1 = useSelector((state) => state.Medical.DrugList);

  const handleClick1 = (e, indexVal) => {
    const exampleAttr = e.target.getAttribute("name");
    if (exampleAttr !== "Reaction_type") {
      setShowOptions1(false);
    }
  };
  const setAllergyTypeSelect = (r, indexVal) => {
    setShowOptions(false);
    if (r.label === "Specific Drug allergy") {
      setShow_Val(true);
      setShow_notes(true);

      setAllergyType(r.label);
    } else if (r.label === "Drug Class allergy") {
      setShow_Val(true);
      setShow_notes(true);
      setAllergyType(r.label);
    } else if (r.label === "Non-Drug allergy") {
      setShow_Val(true);
      setShow_notes(true);
      setAllergyType(r.label);
    } else if (r.label === "No Known Drug Allergies (NKDA)") {
      setAllergyType(r.label);
      setShow_Val(false);
      setShow_notes(true);
    }
    if (r.label !== "") {
      setAllergyTypeErr(false);
    }
  };

  const handleClick = (e) => {
    const exampleAttr = e.target.getAttribute("name");
    if (exampleAttr !== "Allergy_type") {
      setShowOptions(false);
    }
  };
  const setReacTypeSelect = (r) => {
    setReacType(r.label);
    setShowOptions1(false);
    if (r.label !== "") {
      setReacTypeErr(false);
    }
  };
  const setSevTypeSelect = (r) => {
    setSevType(r.label);
    setShowOptions2(false);
    if (r.label !== "") {
      setSevTypeErr(false);
    }
  };
  const handleDrug = (e) => {
    setLoading(true);
    let drugvalue = e.target.value;

    setDrugType(e.target.value);
    if (drugvalue.trim() !== "") {
      setDropdownOpen(true);
    } else {
      setDropdownOpen(false);
    }
    setDrugTypeErr(false);
    setDrugMatcherr(false);
  };

  const handleClick2 = (e) => {
    const exampleAttr = e.target.getAttribute("name");
    if (exampleAttr !== "Severity_type") {
      setShowOptions2(false);
    }
  };
  const setStaTypeSelect = (r) => {
    if (r.label === "Inactive") {
      setStatuslabel("0");
    } else if (r.label === "Active") {
      setStatuslabel("1");
    } else if (r.label === "Resolved") {
      setStatuslabel("2");
    }
    setStaType(r.label);
    setShowOptions3(false);
    if (r.label !== "") {
      setStaTypeErr(false);
    }
  };

  const HandleSave = () => {
    var err = 0;
    if (props.id === "0") {
      if (AllergyType === "") {
        setAllergyTypeErr(true);
        err = 2;
      }
      if (err > 0) {
        return false;
      }
   
      if (AllergyType === "No Known Drug Allergies (NKDA)") {
        setbtnDisabled(true);
        dispatch(
          GetAllerInsertList({
            pid: props.pidvalue,
            type: props.prblmvalue,
            title: AllergyType,
            subtype: AllergyType,
            reaction: "",
            activity: "1",
            severity_al: "",
            comments: notes,
            value: props.prblmvalue,
          })
        );
      } else if (AllergyType !== "No Known Drug Allergies (NKDA)") {
        if (ReacType === "") {
          setReacTypeErr(true);
          err = 2;
        }

        if (SevType === "") {
          setSevTypeErr(true);
          err = 2;
        }
        if (StaType === "") {
          setStaTypeErr(true);
          err = 2;
        }
        if (DrugType === "") {
          setDrugTypeErr(true);
          err = 2;
        } else if (DrugType !== selectedValue) {
          setDrugMatcherr(true);
        }
        if (err > 0) {
          return false;
        }
        setDisa(true);
        setbtnDisabled(true);
        dispatch(
          GetAllerInsertList({
            pid: props.pidvalue,
            type: props.prblmvalue,
            title: AllergyType,
            subtype: DrugType,
            reaction: ReacType,
            activity: Statuslabel,
            severity_al: SevType,
            comments: notes,
            value: props.prblmvalue,
          })
        );
      }
    } else {
      if (AllergyType === "No Known Drug Allergies (NKDA)") {
        setbtnDisabled(true);
        dispatch(
          GetAllerUpdateList({
            pid: props.pidvalue,
            id: props.id,
            type: props.prblmvalue,
            title: AllergyType,
            subtype: AllergyType,
            reaction: "",
            activity: "1",
            severity_al: "",
            comments: notes,
            value: props.prblmvalue,
          })
        );
      } else if (AllergyType !== "No Known Drug Allergies (NKDA)") {
        if (AllergyType === "") {
          setAllergyTypeErr(true);
          err = 1;
        }

        if (DrugType === "" || !DrugList1[1].includes(DrugType)) {
          setDrugTypeErr(true);
          err = 1;
        } else if (
          ReacType === "" ||
          ReacType === null ||
          ReacType === undefined
        ) {
          setReacTypeErr(true);
          err = 1;
        }

        if (err === 1) {
          return false;
        }
        setbtnDisabled(true);
        dispatch(
          GetAllerUpdateList({
            pid: props.pidvalue,
            id: props.id,
            type: props.prblmvalue,
            title: AllergyType,
            subtype: DrugType,
            reaction: ReacType,
            activity: Statuslabel,
            severity_al: SevType,
            comments: notes,
            value: props.prblmvalue,
          })
        );
      }
    }

    setDisa(false);
    handleClose();
  };
  const validateDrugType = () => {
    if (DrugType.trim() === "") {
      setDrugTypeErr(true);
    } else {
      setDrugTypeErr(false);
    }
  };

  const handleClose = () => {
    setbtnDisabled(false);
    setReacType("");
    setSevType("");
    setStaType("");
    setAllergyType("");
    setDrugType("");
    setnotes("");
    setSevTypeErr(false);
    setStaTypeErr(false);
    setReacTypeErr(false);
    setDrugTypeErr(false);
    setAllergyTypeErr(false);
    setSelectedValue("");
    setDrugMatcherr(false);
    setShow_Val(false);
    setShow_notes(false);
    props.close();
    dispatch(clearAllergyData(""));
  };
  const handlenotes = (e) => {
    setnotes(e.target.value);
  };
  const handleClick3 = (e) => {
    const exampleAttr = e.target.getAttribute("name");

    if (exampleAttr !== "status_type") {
      setShowOptions3(false);
    }
  };
  const handleContainerClick = (event) => {
    event.stopPropagation();
    if (ShowOptions1) {
      setShowOptions1(false);
    }
    if (ShowOptions2) {
      setShowOptions2(false);
    }
    if (ShowOptions3) {
      setShowOptions3(false);
    }
    if (ShowOptions) {
      setShowOptions(false);
    }
    if (dropdownOpen) {
      setDropdownOpen(false);
    }
  };
  const handleOptionClick = (value) => {
    setDrugType(value);
    setDropdownOpen(false);
    setSelectedValue(value);
  };
  const closeModal = () => {
    props.close();
    setShow_Val(false);
    setShow_notes(false);
  };
  // ------------------------------------------------- Functions End-----------------------------------------------------------//
  // ------------------------------ useEffect Start----------------------------------------------------------- //

  useEffect(() => {
    document.addEventListener("click", handleClick);
    document.addEventListener("click", handleClick1);
    document.addEventListener("click", handleClick2);
    document.addEventListener("click", handleClick3);
    return () => {
      document.removeEventListener("click", handleClick);
      document.removeEventListener("click", handleClick1);
      document.removeEventListener("click", handleClick2);
      document.removeEventListener("click", handleClick3);
    };
  }, []);
  useEffect(() => {
    const updateScreenSize = () => {
      setScreenSize({ width: window.innerWidth, height: window.innerHeight });
    };
    window.addEventListener("resize", updateScreenSize);
    updateScreenSize();
    return () => {
      window.removeEventListener("resize", updateScreenSize);
    };
  }, []);
  useEffect(() => {
    if (Object.keys(allergyDetails).length > 0 && props.id !== "0") {
      setAllergyType(allergyDetails.title);
      setDrugType(allergyDetails.subtype);
      setSelectedValue(allergyDetails.subtype);
      setReacType(allergyDetails.reaction);
      setSevType(allergyDetails.severity_al);
      setnotes(allergyDetails.comments);
      setStatuslabel(allergyDetails.activity);
      if (allergyDetails.activity === 0) {
        setStaType("Inactive");
      } else if (allergyDetails.activity === 1) {
        setStaType("Active");
      } else if (allergyDetails.activity === 2) {
        setStaType("Resolved");
      }

      if (allergyDetails.title === "No Known Drug Allergies (NKDA)") {
        setShow_Val(false);
        setShow_notes(true);
      } else {
        setShow_Val(true);
        setShow_notes(true);
      }
    } else {
      setReacType("");
      setSevType("");
      setStaType("");
      setAllergyType("");
      setDrugType("");
      setnotes("");
      setSevTypeErr(false);
      setStaTypeErr(false);
      setReacTypeErr(false);
      setDrugTypeErr(false);
      setAllergyTypeErr(false);
    }
  }, [allergyDetails]);
  useEffect(() => {
    dispatch(GetDrugList(DrugType));
  }, [DrugType]);
  useEffect(() => {
    if (DrugList1[1] !== null) {
      setLoading(false);
    }
  }, [DrugList1[1]]);
  // ------------------------------ useEffect End----------------------------------------------------------- //

  return (
    <>
      <Modal
        show={props.show}
        onClick={closeModal}
        justifyContent="center"
        overflowY="auto"
        style={{
          width: screenSize.width * 1.0,
          height: screenSize.height * 1.0,
        }}
      >
        <ModalContainer
          justifyContent="center"
          borderRadius="8px"
          onClick={handleContainerClick}
          position="relative"
          overflowX="revert"
          overflowY="revert"
        >
          <ModalDialog width="374px">
            <ModalHeader padding="26px 27px 16px 29px">
              <ModalTitle
                fontFamily="'Open Sans',sans-serif"
                fontWeight="600px"
                fontSize="16px"
                lineHight="22px"
                color="#2E2E2E"
                fontStyle="SemiBold"
              >
                {props.id === "0" ? "Add Allergy" : "Edit Allergy"}
              </ModalTitle>
              <Modclosebtn onClick={handleClose}>
                <FaTimes />
              </Modclosebtn>
            </ModalHeader>

            <ModalBody padding="16px 27px 16px 27px">
              <Div
                fontFamily="'Open Sans',sans-serif"
                fontWeight="400px"
                fontSize="14px"
                marginBottom="8px !important"
              >
                Allergy Type
                <SpanTag color="#B00020">*</SpanTag>
              </Div>
              <Div
                padding=" 0px 0px 16px 0px"
                dis="flex"
                alignItems="flex-start"
              >
                <Div onClick={() => setShowOptions(!ShowOptions)}>
                  <SelectInput
                    placeholder="'Select the Allergy Type'"
                    width="100%"
                    height="38px"
                    background="#FFFFFF"
                    fontSize="14px"
                    name="Allergy_type"
                    fontFamily="'Open Sans',sans-serif"
                    border="1px solid rgba(46, 46, 46, 0.25)"
                    borderRadius="4px"
                    color="#2E2E2E"
                    pl="10px"
                    margin="8px 0px 0px 0px"
                    padding="9px 12px"
                    style={
                      AllergyTypeErr
                        ? { border: "1px solid red", borderRadius: "5px" }
                        : {}
                    }
                  >
                    {AllergyType}
                  </SelectInput>
                  <DropdownImage
                    marginLeft="295px"
                    marginTop="-21px"
                    src={dropdownDownicon}
                  ></DropdownImage>
                </Div>
                {ShowOptions && DropdownOptionList.length > 0 ? (
                  <DropDownDiv
                    width="320px"
                    background="#FFFFFF"
                    border=" 1px solid rgba(46, 46, 46, 0.25)"
                    borderRadius="4px"
                  >
                    {DropdownOptionList.map((r, i) => {
                      return (
                        <DropdownOptions
                          key={i}
                          value={r.value}
                          padding="10px 12px 8px 12px"
                          onClick={() => setAllergyTypeSelect(r)}
                        >
                          {r.label}
                        </DropdownOptions>
                      );
                    })}
                  </DropDownDiv>
                ) : (
                  ""
                )}
                {AllergyTypeErr && (
                  <ErrorMessage>Please Select Allergy</ErrorMessage>
                )}
              </Div>
              {Show_Val ? (
                <>
                  <Div display="flex" justifyContent="space-between">
                    <Div display="flex" flexDirection="column" width="47.5%">
                      <Div
                        fontFamily="'Open Sans',sans-serif"
                        fontWeight="400px"
                        fontSize="14px"
                        marginBottom="8px !important"
                      >
                        Specific Drug
                        <SpanTag color="#B00020">*</SpanTag>
                      </Div>

                      <Div
                        display="flex"
                        position="relative"
                        alignItems="flex-start"
                      >
                        <Div display="flex" position="relative">
                          <SearchIconSVG>
                            <AiOutlineSearch color="rgba(46, 46, 46, 0.5)" />
                          </SearchIconSVG>

                          <Input
                            type="search"
                            name="search"
                            placeholder="Find Drug"
                            padding="10px"
                            border="1px solid rgba(46, 46, 46, 0.25)"
                            style={
                              DrugTypeErr || drumatchgerr
                                ? {
                                    border: "1px solid red",
                                    borderRadius: "5px",
                                  }
                                : {}
                            }
                            borderRadius="4px"
                            width="100%"
                            paddingLeft="24px"
                            fontFamily="Open Sans, sans-serif"
                            fontWeight="400"
                            fontSize="14px"
                            position="absolute"
                            value={DrugType}
                            onChange={handleDrug}
                            onBlur={validateDrugType}
                            autoComplete="off"
                          />
                          {dropdownOpen &&
                          (loading || DrugList1[1].length >= 0) ? (
                            <AllergyDropdown>
                              {loading ? (
                                <Text
                                  padding="10px 12px 8px 12px"
                                  fontSize="15px"
                                >
                                  Loading...
                                </Text>
                              ) : DrugList1[1].length > 0 ? (
                                DrugList1[1].map((option) => (
                                  <AllergyOption
                                    key={option}
                                    onClick={() => handleOptionClick(option)}
                                  >
                                    {option}
                                  </AllergyOption>
                                ))
                              ) : (
                                <Text
                                  padding="10px 12px 8px 12px"
                                  fontSize="15px"
                                >
                                  No results found
                                </Text>
                              )}
                            </AllergyDropdown>
                          ) : null}
                        </Div>
                      </Div>
                      {DrugTypeErr && (
                        <ErrorMessage>Please Select Drug</ErrorMessage>
                      )}
                      {drumatchgerr && (
                        <ErrorMessage>
                          Please Select Any One From DropDown
                        </ErrorMessage>
                      )}
                    </Div>
                    <Div display="flex" flexDirection="column" width="47.5%">
                      <Div
                        fontFamily="'Open Sans',sans-serif"
                        fontWeight="400px"
                        fontSize="14px"
                      >
                        Reaction
                        <SpanTag color="#B00020">*</SpanTag>
                      </Div>
                      <Div dis="flex" alignItems="flex-start">
                        <Div onClick={() => setShowOptions1(!ShowOptions1)}>
                          <SelectInput
                            placeholder="'Select '"
                            width="100%"
                            height="38px"
                            background="#FFFFFF"
                            fontSize="14px"
                            name="Reaction_type"
                            fontFamily="'Open Sans',sans-serif"
                            border="1px solid rgba(46, 46, 46, 0.25)"
                            borderRadius="4px"
                            color="#2E2E2E"
                            pl="10px"
                            margin="8px 0px 0px 0px"
                            padding="9px 12px"
                            style={
                              ReacTypeErr
                                ? {
                                    border: "1px solid red",
                                    borderRadius: "5px",
                                  }
                                : {}
                            }
                          >
                            <Div
                              whiteSpace="nowrap"
                              textOverflow="ellipsis"
                              overflow="hidden"
                              width="96%"
                            >
                              {ReacType}
                            </Div>
                          </SelectInput>
                          <DropdownImage
                            marginLeft="130px"
                            marginTop="-21px"
                            src={dropdownDownicon}
                          ></DropdownImage>
                        </Div>
                        {ShowOptions1 && DropdownOptionList1.length > 0 ? (
                          <DropDownDiv
                            width="320 px"
                            background="#FFFFFF"
                            border=" 1px solid rgba(46, 46, 46, 0.25)"
                            borderRadius="4px"
                            maxHeight="215px"
                            overflow="auto"
                          >
                            {DropdownOptionList1.map((r, i) => {
                              return (
                                <DropdownOptions
                                  key={i}
                                  value={r.value}
                                  padding="10px 12px 8px 12px"
                                  onClick={() => setReacTypeSelect(r)}
                                >
                                  {r.label}
                                </DropdownOptions>
                              );
                            })}
                          </DropDownDiv>
                        ) : (
                          ""
                        )}

                        {ReacTypeErr && (
                          <ErrorMessage>Please Select Reaction</ErrorMessage>
                        )}
                      </Div>
                    </Div>
                  </Div>
                  <Div display="flex" justifyContent="space-between">
                    <Div
                      padding="16px 0px 0px 0px"
                      display="flex"
                      flexDirection="column"
                      width="47.5%"
                    >
                      <Div
                        fontFamily="'Open Sans',sans-serif"
                        fontWeight="400px"
                        fontSize="14px"
                      >
                        Severity
                        <SpanTag color="#B00020">*</SpanTag>
                      </Div>

                      <Div dis="flex" alignItems="flex-start">
                        <Div onClick={() => setShowOptions2(!ShowOptions2)}>
                          <SelectInput
                            placeholder="'Select'"
                            width="100%"
                            height="38px"
                            background="#FFFFFF"
                            fontSize="14px"
                            name="Severity_type"
                            fontFamily="'Open Sans',sans-serif"
                            border="1px solid rgba(46, 46, 46, 0.25)"
                            borderRadius="4px"
                            color="#2E2E2E"
                            pl="10px"
                            margin="8px 0px 0px 0px"
                            padding="9px 12px"
                            style={
                              SevTypeErr
                                ? {
                                    border: "1px solid red",
                                    borderRadius: "5px",
                                  }
                                : {}
                            }
                          >
                            {SevType}
                          </SelectInput>
                          <DropdownImage
                            marginLeft="130px"
                            marginTop="-21px"
                            src={dropdownDownicon}
                          ></DropdownImage>
                        </Div>
                        {ShowOptions2 && DropdownOptionList2.length > 0 ? (
                          <DropDownDiv
                            width="155px"
                            background="#FFFFFF"
                            border=" 1px solid rgba(46, 46, 46, 0.25)"
                            borderRadius="4px"
                          >
                            {DropdownOptionList2.map((r, i) => {
                              return (
                                <DropdownOptions
                                  key={i}
                                  value={r.value}
                                  padding="10px 12px 8px 12px"
                                  onClick={() => setSevTypeSelect(r)}
                                >
                                  {r.label}
                                </DropdownOptions>
                              );
                            })}
                          </DropDownDiv>
                        ) : (
                          ""
                        )}

                        {SevTypeErr && (
                          <ErrorMessage>Please Select Severity</ErrorMessage>
                        )}
                      </Div>
                    </Div>
                    <Div
                      padding="16px 0px 0px 0px"
                      display="flex"
                      flexDirection="column"
                      width="47.5%"
                    >
                      <Div
                        fontFamily="'Open Sans',sans-serif"
                        fontWeight="400px"
                        fontSize="14px"
                      >
                        Status
                        <SpanTag color="#B00020">*</SpanTag>
                      </Div>

                      <Div dis="flex" alignItems="flex-start">
                        <Div onClick={() => setShowOptions3(!ShowOptions3)}>
                          <SelectInput
                            placeholder="'Select'"
                            width="100%"
                            height="38px"
                            background="#FFFFFF"
                            fontSize="14px"
                            name="status_type"
                            fontFamily="'Open Sans',sans-serif"
                            border="1px solid rgba(46, 46, 46, 0.25)"
                            borderRadius="4px"
                            color="#2E2E2E"
                            pl="10px"
                            margin="8px 0px 0px 0px"
                            padding="9px 12px"
                            style={
                              StaTypeErr
                                ? {
                                    border: "1px solid red",
                                    borderRadius: "5px",
                                  }
                                : {}
                            }
                          >
                            {StaType}
                          </SelectInput>
                          <DropdownImage
                            marginLeft="130px"
                            marginTop="-21px"
                            src={dropdownDownicon}
                          ></DropdownImage>
                        </Div>
                        {ShowOptions3 && DropdownOptionList3.length > 0 ? (
                          <DropDownDiv
                            width="155px"
                            background="#FFFFFF"
                            border=" 1px solid rgba(46, 46, 46, 0.25)"
                            borderRadius="4px"
                          >
                            {DropdownOptionList3.map((r, i) => {
                              return (
                                <DropdownOptions
                                  key={i}
                                  value={r.value}
                                  padding="10px 12px 8px 12px"
                                  onClick={() => setStaTypeSelect(r)}
                                >
                                  {r.label}
                                </DropdownOptions>
                              );
                            })}
                          </DropDownDiv>
                        ) : (
                          ""
                        )}

                        {StaTypeErr && (
                          <ErrorMessage>Please Select Status</ErrorMessage>
                        )}
                      </Div>
                    </Div>
                  </Div>
                </>
              ) : (
                ""
              )}

              {Show_notes && (
                <>
                  <Div
                    padding=" 16px 0px 8px 0px"
                    fontFamily="'Open Sans',sans-serif"
                    fontWeight="400px"
                    fontSize="14px"
                    marginBottom="8px !important"
                  >
                    Notes
                  </Div>
                  <TextArea
                    padding=" 0px 0px 16px 0px"
                    value={notes}
                    onChange={handlenotes}
                    resize="none"
                  ></TextArea>
                </>
              )}
            </ModalBody>
            <ModalFooter padding="1rem 25px">
              <ButtonGroup
                fontSize="14px"
                fontWeight="600"
                borderRadius="4px"
                border="1px solid #2C7BE5"
                padding="9px 27px 8px 28px"
                margin="0 16px 0 0px"
                background="#FFF"
                color="#2C7BE5"
                onClick={handleClose}
                cursor="pointer"
                hoverBackColor="rgba(244, 246, 249, 0.75)"
                activeBackgroundColor="rgba(44, 123, 229, 0.10)"
              >
                <Span fontSize="14px" fontWeight="600">
                  Close
                </Span>
              </ButtonGroup>
              <ButtonGroup
                type="submit"
                borderRadius="4px"
                border="1px solid #2C7BE5"
                padding="9px 34px 8px 34px"
                background="#2C7BE5"
                color="#FFFFFF"
                disabled={btnDisabled || disa}
                cursor="pointer"
                isDisabled={disa}
                hoverBackColor="#005FB2"
                activeBackgroundColor="rgba(44, 123, 229, 0.75)"
                onClick={() => HandleSave()}
              >
                <Span
                  fontSize="14px"
                  fontWeight="600"
                  fontFamily="'Open Sans', sans-serif"
                >
                  {props.id === "0" ? "Save" : "Update"}
                </Span>
              </ButtonGroup>
            </ModalFooter>
          </ModalDialog>
        </ModalContainer>
      </Modal>
    </>
  );
};

export default AddAllergy;
