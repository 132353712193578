import { takeLatest } from "redux-saga/effects";
import {
  GetPatDetails,
  GetSinglePatDetail,
  getPatientSearchList,
  GetPatEnrollment,
  GetPatEnrollmentUpdate,
  GetGenderList,
  GetRaceList,
  GetLanguageList,
  AddCardDetails,
  setEmptyAddcardResponse,
  setEmptyUpdatecardResponse,
  setEmptyDeletecardResponse,
  GetCardDetails,
  GetVitalsNotification,
  GetVitalsNotificationUpdate,
  RemoveCardDetail,
  EditCardDetails,
  GetMailVerify,
  GetPhoneVerify,
  setEmptyPatUpdateResponse,
  GetSSNVerify,
  GetFeeList,
  GetFeeListCollapse,
  GetFeeDelete,
  GetFeeCollapseDelete,
  setEmptyMailResponse,
  GetPatientEmail,
  GetUnenrollmentupdate,
  SetUnenrollresponse,
  GetPlanNameValidation,
  GetIcd10Codes,
  InsertTreatmentPlan,
  GetPlanDetails,
  getProviderSearchList,
  getExportStatus,
  getPatientImport,
} from "../../StateManagement/Reducers/PatientState";
import {
  getPatientList,
  PatientCheck,
  getSinglePatientList,
  patEnrollemtData,
  EnrollmentUpdate,
  GenderList,
  RaceList,
  LanguageList,
  AddCardDetailApi,
  setAddCardEmptyResponse,
  setUpdateCardEmptyResponse,
  setDeleteCardEmptyResponse,
  CardDetailsApi,
  RemoveCardDetailApi,
  EditCardDetailApi,
  MailVerifyApi,
  PhoneVerifyApi,
  setPatUpdateResEmpty,
  SSNVerifyApi,
  FeeSheetList,
  FeeSheetListCollapse,
  FeeSheetListDelete,
  FeeSheetDelete,
  PatientEmailDetail,
  setEmptyPtMailResponse,
  Unenrollupdate,
  setUnenrollEmptyResponse,
  planNameValidation,
  Icd10Worker,
  PostTreatmentPlan,
  PlanDetailWorker,
  ProviderCheck,
  VitalsNotificationDetailsApi,
  VitalsNotificationDetailsUpdateApi,
  exportPatient,
  PatientImport,
} from "./PatientSagaWorkers";

export function* PatientList() {
  yield takeLatest(GetPatDetails.type, getPatientList);
}
export function* SinglePatientDetail() {
  yield takeLatest(GetSinglePatDetail.type, getSinglePatientList);
}
export function* searchPatient() {
  yield takeLatest(getPatientSearchList.type, PatientCheck);
}
export function* PatientEnrollemt() {
  yield takeLatest(GetPatEnrollment.type, patEnrollemtData);
}
export function* PatientEnrollemtUpdate() {
  yield takeLatest(GetPatEnrollmentUpdate.type, EnrollmentUpdate);
}
export function* GenderLists() {
  yield takeLatest(GetGenderList.type, GenderList);
}
export function* RaceLists() {
  yield takeLatest(GetRaceList.type, RaceList);
}
export function* LanguageLists() {
  yield takeLatest(GetLanguageList.type, LanguageList);
}
export function* AddCardDetail() {
  yield takeLatest(AddCardDetails.type, AddCardDetailApi);
}

export function* AddCardEmptyingResponseSaga() {
  yield takeLatest(setEmptyAddcardResponse.type, setAddCardEmptyResponse);
}

export function* UpdateCardEmptyingResponseSaga() {
  yield takeLatest(setEmptyUpdatecardResponse.type, setUpdateCardEmptyResponse);
}

export function* DeleteCardEmptyingResponseSaga() {
  yield takeLatest(setEmptyDeletecardResponse.type, setDeleteCardEmptyResponse);
}

export function* CardDetails() {
  yield takeLatest(GetCardDetails.type, CardDetailsApi);
}

export function* VitalsNotificationDetails() {
  yield takeLatest(GetVitalsNotification.type, VitalsNotificationDetailsApi);
}
export function* VitalsNotificationDetailsUpdate() {
  yield takeLatest(
    GetVitalsNotificationUpdate.type,
    VitalsNotificationDetailsUpdateApi
  );
}

export function* RemoveCardDetails() {
  yield takeLatest(RemoveCardDetail.type, RemoveCardDetailApi);
}

export function* EditCardDetail() {
  yield takeLatest(EditCardDetails.type, EditCardDetailApi);
}

export function* MailVerify() {
  yield takeLatest(GetMailVerify.type, MailVerifyApi);
}

export function* PhoneVerify() {
  yield takeLatest(GetPhoneVerify.type, PhoneVerifyApi);
}

export function* EmptyingPatUpdateResponse() {
  yield takeLatest(setEmptyPatUpdateResponse.type, setPatUpdateResEmpty);
}
export function* SSNVerify() {
  yield takeLatest(GetSSNVerify.type, SSNVerifyApi);
}
export function* FeeSheet() {
  yield takeLatest(GetFeeList.type, FeeSheetList);
}

export function* FeeSheetCollapse() {
  yield takeLatest(GetFeeListCollapse.type, FeeSheetListCollapse);
}

export function* FeeSheetsListDelete() {
  yield takeLatest(GetFeeDelete.type, FeeSheetListDelete);
}

export function* FeeSheetCollapseDelete() {
  yield takeLatest(GetFeeCollapseDelete.type, FeeSheetDelete);
}
export function* PatientEmail() {
  yield takeLatest(GetPatientEmail.type, PatientEmailDetail);
}
export function* EmptyingMailResponseSaga() {
  yield takeLatest(setEmptyMailResponse.type, setEmptyPtMailResponse);
}
export function* UnenrollupdateSaga() {
  yield takeLatest(GetUnenrollmentupdate.type, Unenrollupdate);
}

export function* UnenrollResponseSaga() {
  yield takeLatest(SetUnenrollresponse.type, setUnenrollEmptyResponse);
}

export function* searchProvider() {
  yield takeLatest(getProviderSearchList.type, ProviderCheck);
}
export function* ExportPatientList() {
  yield takeLatest(getExportStatus.type, exportPatient);
}
export function* patientImportCsv() {
  yield takeLatest(getPatientImport.type, PatientImport);
}
