import React, { useEffect, useRef } from "react";
import {
  Button,
  Div,
  FormLabel,
  Image,
  Input,
  PageTitle,
  SearchIconSVG,
  Span,
  SpanTag,
  Table,
  TableBody,
  TableBodyCell,
  TableHead,
  TableHeadCell,
  Tablerow,
  Spinner,
  SpinnerWrapper,
} from "../StyledComponents";
import {
  AiOutlineFileAdd,
  AiOutlineMail,
  AiOutlineSearch,
} from "react-icons/ai";
import UpandDownArrow from "../../Assets/images/UpandDownArrow.png";
import AscendingArrow from "../../Assets/images/AscendingArrow.png";
import DescendingArrow from "../../Assets/images/DescendingArrow.png";
import { useState } from "react";
import { BsFillCameraVideoFill, BsTelephoneFill } from "react-icons/bs";
import { BiMessage } from "react-icons/bi";
import { RiArrowDropDownLine } from "react-icons/ri";
import Pagination from "../StyledComponents/Pagination/Pagination";
import { StyledIcon } from "../LeftNav/style";
import EmptyLive from "../../Assets/images/EmptyLive.png";
import {
  CheckBoxLabel,
  PaginDiv,
  TogggleCheck,
  DropDownVitals,
  LiveDiv,
  LiveTablerow,
  LiveTableBodyCell,
  LiveTableHeadCell,
  SpanTagLive,
} from "./styles";
import ChatPopup from "../Popup/ChatPopup/ChatPopup";
import { useDispatch, useSelector } from "react-redux";
import { GetVitalDetails } from "../../StateManagement/Reducers/LiveState";
import { Decrypt_Value, Encrypt_Value } from "../../MiddleWare/EncryptDecrypt";
import { CSVLink } from "react-csv";
import { useNavigate } from "react-router-dom";
import {
  GetEncounterSummary,
  GetEncounterId,
} from "../../StateManagement/Reducers/EncounterState";
import { GetSinglePatDetail } from "../../StateManagement/Reducers/PatientState";
import Notes from "../Patient/Encounter/Notes";
import AlertModal from "../StyledComponents/AlertModal/AlertModal";
import Logo from "../../Assets/images/Logo.png";

const LiveSheet = () => {
  // ----------------------------State and Variables ---------------------------------------------------///
  const vitalsRef = useRef(null);
  const [dropOpen, setDropOpen] = useState(false);
  const [selectedVitals, setSelectedVitals] = useState("");
  const [patSearchValue, setPatSearchValue] = useState("");
  const [chatDetails, setChatDetails] = useState(null);
  const Navigate = useNavigate();
  const [filteredHeader, setFilteredHeader] = useState([]);
  const [filteredVitalsDetail, setFilteredVitalsDetail] = useState([]);
  const [loading, setLoading] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);

  const [arrowImg, setArrowImg] = useState(() => {
    const initialImg = UpandDownArrow;
    const imgCount = 7;
    const initialImgState = {};

    for (let i = 1; i <= imgCount; i++) {
      initialImgState[`img${i}`] = initialImg;
    }

    return initialImgState;
  });
  const [chatShow, setChatShow] = useState(false);

  const provider_id =
    localStorage.getItem("user_id") &&
    Decrypt_Value(localStorage.getItem("user_id"), "vozorpm");

  const providerName = useSelector(
    (state) => state.Login.ProviderDetails["providerName"]
  );
  const providerImg = useSelector(
    (state) => state.Login.ProviderDetails["img_url"]
  );
  const dispatch = useDispatch();

  const header = [
    { label: "Patient Name", key: "patName" },
    {
      label: "Provider Name",
      key: "providerName",
    },

    { label: "Heart Rate(BPM)", key: "pulse" },
    { label: "Respiration Rate(RPM)", key: "RPM" },
    { label: "Blood Pressure(mm Hg)", key: "bloodpressure" },
    { label: "Oxygen(%)", key: "oxygen_saturation" },
    { label: "Glucose(mg/dl)", key: "BloodGlucoseLevel" },
  ];
  const [initialShow, setInitialShow] = useState(false);
  const [currentEid, setCurrentEid] = useState("");
  const [currentPid, setCurrentPid] = useState("");
  const [encountDetails, setEncounterDetails] = useState([]);
  const [show, setShow] = useState(false);
  const TakeNote = useSelector((state) => state.Encounter.NotesTake);
  const [sort, setSort] = useState("");
  const [modalMsg, setModalMsg] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [status, setStatus] = useState("");
  const permissions =
    localStorage.getItem("permissions") &&
    Decrypt_Value(localStorage.getItem("permissions"), "vozorpm");
  // ----------------------------State and Variables ---------------------------------------------------///

  //-------------------------Functions Start-------------------------------------------------------------//
  const handleMessage = (details) => {
    if (!permissions.includes("message_access")) {
      setModalMsg("Permission Denied");
      setStatus("Failed");
      setShowAlert(true);
      setTimeout(() => {
        setShowAlert(false);
        setStatus("");
        setModalMsg("");
      }, 1500);
    } else {
      setChatShow((prevState) => !prevState);
      setChatDetails(details);
    }
  };

  const handleCloseChat = () => {
    setChatShow(false);
  };

  const handleDropDownClick = (event) => {
    event.stopPropagation();
  };
  const vitalsDetail = useSelector((state) => state.Live.VitalsDetails);

  const handlePatientSearch = (event) => {
    let value = event.target.value;
    setSearch(true);

    setPatSearchValue(value);
    setArrowImg({
      ...arrowImg,
      ...resetImagesToInitialState(),
    });
  };

  const resetImagesToInitialState = () => {
    const initialImgState = {};

    for (let i = 1; i <= 7; i++) {
      initialImgState[`img${i}`] = UpandDownArrow;
    }

    return initialImgState;
  };
  const hanldeArrowImg = (index, columnName) => {
    let sortOrder = "";
    setColumnName(columnName);

    if (arrowImg[`img${index}`] === UpandDownArrow) {
      setArrowImg({
        ...arrowImg,

        ...resetImagesToInitialState(),
        [`img${index}`]: AscendingArrow,
      });
      sortOrder = "asc";
      setSort("asc");
    } else if (arrowImg[`img${index}`] === AscendingArrow) {
      setArrowImg({
        ...arrowImg,

        ...resetImagesToInitialState(),
        [`img${index}`]: DescendingArrow,
      });
      sortOrder = "desc";
      setSort("desc");
    } else if (arrowImg[`img${index}`] === DescendingArrow) {
      setArrowImg({
        ...arrowImg,

        ...resetImagesToInitialState(),
        [`img${index}`]: UpandDownArrow,
      });
      setSort("");
    }

    dispatch(
      GetVitalDetails({
        provider_id: "",
        column_name: columnName,
        sort_order: sortOrder,
        search_key: patSearchValue,
        page: currentPage,
        pageSize: 10,
      })
    );
  };

  const [currentPage, setCurrentPage] = useState(vitalsDetail?.currentPage);
  const [currentPosts, setCurrentPost] = useState([]);
  const [howManyPages, setHowManyPages] = useState(0);
  const [columnName, setColumnName] = useState("");
  const [search, setSearch] = useState(false);
  const [vitalsOption, setVitalsOption] = useState([
    { id: "1", text: "Heart Rate", value: "HeartRate", checked: false },
    {
      id: "2",
      text: "Respiration Rate",
      value: "RespirationRate",
      checked: false,
    },
    { id: "3", text: "Blood Pressure", value: "BloodPressure", checked: false },
    { id: "4", text: "Oxygen", value: "Oxygen", checked: false },
    { id: "5", text: "Glucose", value: "Glucose", checked: false },
  ]);
  const [vitalsSelected, setVitalsSelected] = useState(false);
  const [shownColumns, shownUnshownColumns] = useState({
    HeartRate: true,
    RespirationRate: true,
    BloodPressure: true,
    Oxygen: true,
    Glucose: true,
  });

  const VitalsSelection = (e, row) => {
    let a = [...vitalsOption];
    a.map((r) => {
      if (r.id === row.id) {
        r.checked = e.target.checked;
      }
    });
    const allCheckedFalse = a.every((option) => option.checked === false);
    if (allCheckedFalse) {
      setVitalsSelected(false);
    } else {
      setVitalsSelected(true);
    }
    shownUnshownColumns({
      ...shownColumns,
      [row.value]: !e.target.checked,
    });

    let count = 0;
    let vital = "";
    a.map((row) => {
      if (row.checked === true) {
        count++;
        vital = row.text;
      }
    });
    if (count > 1) {
      setSelectedVitals(`${count}  vitals`);
    } else {
      setSelectedVitals(vital);
    }
    setVitalsOption(a);
  };

  const handleNoteAdd = (details, eid) => {
    dispatch(GetSinglePatDetail(details.pid));
    setCurrentPid(details.pid);
    setCurrentEid(details.recent_encounter);

    setInitialShow(true);
    setEncounterDetails(details);
  };

  const handleClose = () => {
    setEncounterDetails([]);
    setShow(false);
  };
  // -------------------------Functions End-------------------------------------------------------------//
  // -----------------------useEffect Start-------------------------------------------------------------//
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
    setTimeout(() => {
      setDataLoaded(true);
    }, 1000);
  }, []);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (vitalsRef.current && !vitalsRef.current.contains(event.target)) {
        if (dropOpen === true) {
          setDropOpen(false);
        }
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [dropOpen]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setCurrentPage(1);
      if (search) {
        dispatch(
          GetVitalDetails({
            provider_id: provider_id,
            column_name: "",
            sort_order: "",
            search_key: patSearchValue,
            page: 1,
            pageSize: 10,
          })
        );
      }
    }, 300);

    return () => clearTimeout(delayDebounceFn);
  }, [patSearchValue]);

  useEffect(() => {
    if (!search) {
      if (currentPage !== undefined) {
        dispatch(
          GetVitalDetails({
            provider_id: provider_id,
            column_name: columnName,
            sort_order: sort,
            search_key: patSearchValue,
            page: currentPage,
            pageSize: 10,
          })
        );
      }
    }
  }, [currentPage]);

  useEffect(() => {
    if (vitalsDetail) {
      setCurrentPost(vitalsDetail.data ?? []);
      setHowManyPages(vitalsDetail.totalPages ?? 0);
      setSearch(false);
    }
  }, [vitalsDetail]);

  useEffect(() => {
    const startIndex = 2; // Start index of "Heart Rate" in header array
    const newFilteredHeader = header.filter((item, index) => {
      if (index < 2) {
        return true; // Include "Patient Name" and "Provider Name"
      } else {
        const correspondingOption = vitalsOption[index - startIndex];
        return correspondingOption && correspondingOption.checked;
      }
    });

    const filteredIndexes = newFilteredHeader.map((item) =>
      header.indexOf(item)
    );

    // const newFilteredVitalsDetail = currentPosts.map((patientData) => {
    //   const filteredPatientData = filteredIndexes.map(
    //     (index) => patientData[index + startIndex]
    //   );
    //   return {
    //     ...patientData,
    //     vitalData: filteredPatientData,
    //   };
    // });

    const allFalse = vitalsOption.every((option) => !option.checked);
    const allTrue = vitalsOption.every((option) => option.checked);

    if (allFalse || allTrue || newFilteredHeader.length === 0) {
      setFilteredHeader(header); // Set original header
      // setFilteredVitalsDetail(vitalsDetail); // Set original vitalsDetail
    } else {
      setFilteredHeader(newFilteredHeader); // Set filtered header
      // setFilteredVitalsDetail(newFilteredVitalsDetail); // Set filtered vitalsDetail
    }
  }, [vitalsOption, vitalsDetail]);

  useEffect(() => {
    if (initialShow) {
      if (encountDetails.length != 0) setShow(true);
      else setShow(false);
    }
  }, [encountDetails]);

  useEffect(() => {
    if (TakeNote === "success") {
      localStorage.setItem("encounter", Encrypt_Value(currentEid, "vozorpm"));
      dispatch(GetEncounterId(currentEid));
      dispatch(GetEncounterSummary(currentEid));
      setTimeout(() => {
        Navigate("/Encounter/" + currentPid, {
          state: {
            eid: currentEid,
          },
        });
      }, 1000);
    }
  }, [TakeNote]);
  useEffect(() => {}, []);

  // -------------------------useEffect End-------------------------------------------------------------//
  return (
    <>
      {loading ? (
        <SpinnerWrapper>
          <Spinner></Spinner>
          <Image width="40px" height="40px" position="absolute" src={Logo} />
        </SpinnerWrapper>
      ) : (
        <div onClick={() => setDropOpen(false)}>
          <Div padding="0px 0px 16px 0px">
            <PageTitle
              textAlign="left"
              fontFamily="'Open Sans', sans-serif"
              fontWeight="600"
              fontSize="18px"
              lineHeight="24px"
              color="#2E2E2E"
            >
              Live
            </PageTitle>
          </Div>
          <LiveDiv background="#FFFFFF" className="livediv">
            <Div display="flex" padding="20px" justifyContent="space-between">
              <Div position="relative" display="flex" width="53.3%">
                <SearchIconSVG>
                  <AiOutlineSearch color="rgba(46, 46, 46, 0.5)" />
                </SearchIconSVG>

                <Input
                  type="search"
                  name="search"
                  placeholder="Search for Patient"
                  padding="10px"
                  border="1px solid rgba(46, 46, 46, 0.25)"
                  borderRadius="4px"
                  width="70%"
                  height="38px"
                  paddingLeft="28px"
                  color="rgba(46, 46, 46, 0.5)"
                  fontFamily="'Open Sans', sans-serif"
                  fontWeight="400"
                  fontSize="14px"
                  position="absolute"
                  value={patSearchValue}
                  onChange={handlePatientSearch}
                  className={patSearchValue ? "hide-icon" : ""}
                  autoComplete
                />
                <Div width="55%" display="block">
                  <Div
                    height="38px"
                    border="1px solid rgba(46, 46, 46, 0.25)"
                    width="100%"
                    margin="0px 0px 0px 20px"
                    borderRadius="4px"
                    display="flex"
                    alignItems="center"
                    onClick={(e) => {
                      e.stopPropagation();
                      setDropOpen(!dropOpen);
                    }}
                  >
                    <Input
                      // type="search"
                      name="search"
                      placeholder="Select Vitals"
                      padding="10px"
                      width="90%"
                      paddingLeft="28px"
                      border="none"
                      height="20px"
                      color="rgba(46, 46, 46, 1)"
                      fontFamily="'Open Sans', sans-serif"
                      fontWeight="400"
                      fontSize="14px"
                      autoComplete
                      value={selectedVitals}
                      readOnly
                    />
                    <StyledIcon
                      fontSize="29px"
                      color="rgba(46, 46, 46, 0.25)"
                      display="flex"
                    >
                      <RiArrowDropDownLine />
                    </StyledIcon>
                  </Div>
                  <Div
                    width="43.4%"
                    border="1px solid rgba(46, 46, 46, 0.25)"
                    position="absolute"
                    top="37px"
                    zIndex="1000"
                    background="#ffff"
                    borderRadius="4px"
                    margin="0px 0px 0px 20px"
                    display={dropOpen ? "block" : "none"}
                    onClick={handleDropDownClick}
                    ref={vitalsRef}
                  >
                    {vitalsOption.map((row, i) => {
                      return (
                        <Div display="flex">
                          <Div position="relative" margin="11px 12px 0px 12px">
                            <TogggleCheck
                              checked={row.checked}
                              type="checkbox"
                              onChange={(e) => VitalsSelection(e, row)}
                            />
                            <CheckBoxLabel htmlFor="checkbox" />
                          </Div>
                          <DropDownVitals
                            // ref={vitalsRef}
                            name={row.text}
                            key={row.id}
                            display="flex"
                            value={row.value}
                            padding="10px 12px 8px 0px"
                          >
                            <Span
                              fontSize="14px"
                              fontFamily="'Open Sans', sans-serif"
                              fontWeight="400"
                              lineHeight="19px"
                            >
                              {row.text}
                            </Span>
                          </DropDownVitals>
                        </Div>
                      );
                    })}
                  </Div>
                </Div>
              </Div>
              <Div>
                <CSVLink
                  data={currentPosts}
                  headers={filteredHeader}
                  filename="vitals_list"
                >
                  <Button
                    cursor="pointer"
                    color="#2C7BE5"
                    border="1px solid #2C7BE5"
                    borderRadius="4px"
                    background="#FFF"
                    fontFamily="Open Sans, sans-serif"
                    fontWeight="600"
                    fontSize="14px"
                    padding="10px 17px"
                    height="36px"
                    marginRight="20px"
                    display="flex"
                    alignItems="center"
                    hoverBackColor="rgba(244, 246, 249, 0.75)"
                    activeBackgroundColor="rgba(44, 123, 229, 0.10)"
                  >
                    Export csv
                  </Button>
                </CSVLink>
              </Div>
            </Div>
            <Table>
              <TableHead background="#F1F5F8">
                <LiveTablerow
                  color="#2C7BE5"
                  fontFamily="'Open Sans', sans-serif"
                  fontWeight="400"
                  fontSize="14px"
                  className="liveth"
                >
                  <LiveTableHeadCell width="10%" className="livethcell">
                    <SpanTag display="flex" width="113px">
                      Patient Name
                      <Image
                        color="#2C7BE5"
                        marginLeft="4px"
                        cursor="pointer"
                        onClick={() => hanldeArrowImg(1, "patName")}
                        src={arrowImg.img1}
                        alt="arrow"
                      />
                    </SpanTag>
                  </LiveTableHeadCell>
                  <LiveTableHeadCell width="8%">
                    <SpanTag display="flex">
                      Provider
                      <Image
                        color="#2C7BE5"
                        marginLeft="4px"
                        cursor="pointer"
                        onClick={() => hanldeArrowImg(2, "providerName")}
                        src={arrowImg.img2}
                        alt="arrow"
                      />
                    </SpanTag>
                  </LiveTableHeadCell>
                  <LiveTableHeadCell
                    width="8%"
                    display={
                      vitalsSelected &&
                      shownColumns.HeartRate === true &&
                      "none"
                    }
                  >
                    <SpanTag display="flex">
                      Heart
                      <Image
                        color="#2C7BE5"
                        marginLeft="4px"
                        cursor="pointer"
                        onClick={() => hanldeArrowImg(3, "pulse")}
                        src={arrowImg.img3}
                        alt="arrow"
                      />
                    </SpanTag>
                  </LiveTableHeadCell>
                  <LiveTableHeadCell
                    width="8%"
                    display={
                      vitalsSelected &&
                      shownColumns.RespirationRate === true &&
                      "none"
                    }
                  >
                    <SpanTag display="flex">
                      Respiration
                      <Image
                        color="#2C7BE5"
                        marginLeft="4px"
                        cursor="pointer"
                        onClick={() => hanldeArrowImg(4, "respiration")}
                        src={arrowImg.img4}
                        alt="arrow"
                      />
                    </SpanTag>
                  </LiveTableHeadCell>
                  <LiveTableHeadCell
                    width="8%"
                    display={
                      vitalsSelected &&
                      shownColumns.BloodPressure === true &&
                      "none"
                    }
                  >
                    <SpanTag display="flex">
                      BP
                      <Image
                        color="#2C7BE5"
                        marginLeft="4px"
                        cursor="pointer"
                        onClick={() => hanldeArrowImg(5, "bloodpressure")}
                        src={arrowImg.img5}
                        alt="arrow"
                      />
                    </SpanTag>
                  </LiveTableHeadCell>
                  <LiveTableHeadCell
                    width="8%"
                    display={
                      vitalsSelected && shownColumns.Oxygen === true && "none"
                    }
                  >
                    <SpanTag display="flex">
                      Oxygen
                      <Image
                        color="#2C7BE5"
                        marginLeft="4px"
                        cursor="pointer"
                        onClick={() => hanldeArrowImg(6, "oxygen_saturation")}
                        src={arrowImg.img6}
                        alt="arrow"
                      />
                    </SpanTag>
                  </LiveTableHeadCell>
                  <LiveTableHeadCell
                    width="8%"
                    display={
                      vitalsSelected && shownColumns.Glucose === true && "none"
                    }
                  >
                    <SpanTag display="flex">
                      Glucose
                      <Image
                        color="#2C7BE5"
                        marginLeft="4px"
                        cursor="pointer"
                        onClick={() => hanldeArrowImg(7, "BloodGlucoseLevel")}
                        src={arrowImg.img7}
                        alt="arrow"
                      />
                    </SpanTag>
                  </LiveTableHeadCell>
                  <LiveTableHeadCell width="15%">
                    <SpanTag display="flex">
                      Action
                      <Image
                        color="#2C7BE5"
                        marginLeft="4px"
                        cursor="pointer"
                      />
                    </SpanTag>
                  </LiveTableHeadCell>
                </LiveTablerow>
              </TableHead>
              <TableBody>
                {currentPosts.length !== 0 ? (
                  currentPosts.map((details, index) => (
                    <LiveTablerow
                      key={index}
                      borderBottom="1px solid #E2E8ED"
                      fontFamily="'Open Sans', sans-serif"
                      fontWeight="400"
                      fontSize="14px"
                      className="livetd"
                    >
                      <LiveTableBodyCell
                        color="black"
                        width="10%"
                        className="livetdcell"
                      >
                        {details.patName}
                      </LiveTableBodyCell>
                      <LiveTableBodyCell width="8%">
                        {details.providerName}
                      </LiveTableBodyCell>
                      <LiveTableBodyCell
                        width="8%"
                        display={
                          vitalsSelected &&
                          shownColumns.HeartRate === true &&
                          "none"
                        }
                        color={
                          details.pulse
                            ? parseInt(details.pulse) >= 60 &&
                              parseInt(details.pulse) <= 100
                              ? "#2e2e2e"
                              : parseInt(details.pulse) >= 50 &&
                                parseInt(details.pulse) <= 59
                              ? "#FFC700"
                              : parseInt(details.pulse) >= 101 &&
                                parseInt(details.pulse) <= 120
                              ? "#FFC700"
                              : parseInt(details.pulse) < 50 ||
                                parseInt(details.pulse) > 120
                              ? "#F24822"
                              : "#2e2e2e"
                            : "#2e2e2e"
                        }
                      >
                        {details.pulse && details.pulse !== ""
                          ? `${details.pulse}BPM`
                          : "-"}
                      </LiveTableBodyCell>
                      <LiveTableBodyCell
                        width="8%"
                        display={
                          vitalsSelected &&
                          shownColumns.RespirationRate === true &&
                          "none"
                        }
                        color={
                          details.RPM
                            ? parseInt(details.RPM) >= 12 &&
                              parseInt(details.RPM) <= 20
                              ? "#2e2e2e"
                              : parseInt(details.RPM) >= 9 &&
                                parseInt(details.RPM) <= 11
                              ? "#FFC700"
                              : parseInt(details.RPM) >= 21 &&
                                parseInt(details.RPM) <= 23
                              ? "#FFC700"
                              : parseInt(details.RPM) < 9 ||
                                parseInt(details.RPM) > 23
                              ? "#F24822"
                              : "#F24822"
                            : "#2e2e2e"
                        }
                      >
                        {details.RPM && details.RPM !== ""
                          ? `${details.RPM}RPM`
                          : "-"}
                      </LiveTableBodyCell>
                      <LiveTableBodyCell
                        width="8%"
                        display={
                          vitalsSelected &&
                          shownColumns.BloodPressure === true &&
                          "none"
                        }
                        color={
                          details.bps_1 && details.bps_2
                            ? parseInt(details.bps_1) >= 130 ||
                              parseInt(details.bps_2) >= 90
                              ? "#F24822" // Critical
                              : (parseInt(details.bps_1) >= 121 &&
                                  parseInt(details.bps_1) <= 129) ||
                                (parseInt(details.bps_2) >= 81 &&
                                  parseInt(details.bps_2) <= 89)
                              ? "#FFC700" // Abnormal
                              : parseInt(details.bps_1) >= 90 &&
                                parseInt(details.bps_1) <= 120 &&
                                parseInt(details.bps_2) >= 60 &&
                                parseInt(details.bps_2) <= 80
                              ? "#2e2e2e" // Normal
                              : "#2e2e2e"
                            : "#2e2e2e"
                        }
                      >
                        {details.bps_1 &&
                        details.bps_2 &&
                        details.bps_1 !== "" &&
                        details.bps_2 !== ""
                          ? `${details.bps_1}/${details.bps_2}mm Hg`
                          : "-"}
                      </LiveTableBodyCell>

                      <LiveTableBodyCell
                        width="8%"
                        display={
                          vitalsSelected &&
                          shownColumns.Oxygen === true &&
                          "none"
                        }
                        color={
                          details.oxygen_saturation
                            ? parseInt(details.oxygen_saturation) <= 90 ||
                              parseInt(details.oxygen_saturation) > 100
                              ? "#F24822" // Critical
                              : parseInt(details.oxygen_saturation) >= 95 &&
                                parseInt(details.oxygen_saturation) <= 100
                              ? "#2e2e2e"
                              : parseInt(details.oxygen_saturation) >= 91 ||
                                parseInt(details.oxygen_saturation) <= 94
                              ? "#FFC700"
                              : "#2e2e2e"
                            : "#2e2e2e"
                        }
                      >
                        {details.oxygen_saturation
                          ? `${details.oxygen_saturation} %`
                          : "-"}
                      </LiveTableBodyCell>
                      <LiveTableBodyCell
                        width="8%"
                        display={
                          vitalsSelected &&
                          shownColumns.Glucose === true &&
                          "none"
                        }
                      >
                        <SpanTag
                          color={
                            details.BloodGlucoseLevel
                              ? parseInt(details.BloodGlucoseLevel) >= 120 &&
                                parseInt(details.BloodGlucoseLevel) <= 140
                                ? "#2e2e2e"
                                : parseInt(details.BloodGlucoseLevel) >= 141 &&
                                  parseInt(details.BloodGlucoseLevel) <= 180
                                ? "#FFC700"
                                : parseInt(details.BloodGlucoseLevel) > 180
                                ? "#F24822"
                                : "#F24822"
                              : "#2e2e2e"
                          }
                        >
                          {details.BloodGlucoseLevel &&
                          details.BloodGlucoseLevel !== ""
                            ? `${details.BloodGlucoseLevel} mg/dl`
                            : "-"}
                        </SpanTag>
                      </LiveTableBodyCell>
                      <LiveTableBodyCell width="15%">
                        {
                          <SpanTagLive
                            gap="14px"
                            display="flex"
                            cursor="pointer"
                          >
                            <AiOutlineFileAdd
                              size={16}
                              onClick={() => handleNoteAdd(details)}
                            />
                            <BsTelephoneFill size={16} />
                            <BsFillCameraVideoFill size={16} />
                            <AiOutlineMail size={16} />
                            <BiMessage
                              size={16}
                              onClick={() => handleMessage(details)}
                            />
                          </SpanTagLive>
                        }
                      </LiveTableBodyCell>
                    </LiveTablerow>
                  ))
                ) : dataLoaded && currentPosts.length === 0 ? (
                  <Tablerow>
                    <TableBodyCell
                      textAlign="center"
                      colSpan={8}
                      padding="20px"
                    >
                      <Div padding="20px">
                        <Image
                          height="208px"
                          width="229px"
                          src={EmptyLive}
                        ></Image>
                      </Div>
                      <FormLabel
                        fontSize="18px"
                        color="#000000"
                        fontFamily="'Open Sans', sans-serif"
                        lineHeight="25px"
                        fontWeight="400"
                      >
                        No readings has been recorded to show in the live screen
                      </FormLabel>
                    </TableBodyCell>
                  </Tablerow>
                ) : (
                  <SpinnerWrapper>
                    <Spinner></Spinner>
                    <Image
                      width="40px"
                      height="40px"
                      position="absolute"
                      src={Logo}
                    />
                  </SpinnerWrapper>
                )}
              </TableBody>
            </Table>
            <PaginDiv
              hidePagination={currentPosts.length === 0 || howManyPages === 1}
            >
              {currentPosts.length === 0 || howManyPages === 1 ? (
                ""
              ) : (
                <Pagination
                  pages={howManyPages}
                  setCurrentPage={setCurrentPage}
                />
              )}
            </PaginDiv>
            {chatShow && (
              <ChatPopup
                patpic={chatDetails && chatDetails.img_url}
                pid={chatDetails && chatDetails.pid}
                patName={chatDetails && chatDetails.patName}
                Provider_Id={provider_id}
                profileimage={providerImg}
                providerName={providerName}
                handleCloseChat={handleCloseChat}
                chatShow={chatShow}
              />
            )}
          </LiveDiv>
          {show && (
            <>
              <Notes
                encountDetails={encountDetails}
                show={show}
                close={handleClose}
                clickFrom={"live"}
              ></Notes>
            </>
          )}
          {showAlert && (
            <AlertModal show={showAlert} status={status} msg={modalMsg} />
          )}
        </div>
      )}
    </>
  );
};
export default LiveSheet;
