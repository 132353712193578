import React, { useState, useEffect } from "react";
import RadioProgress from "./RadioProgress";
import {
  PageSubTitle,
  Container,
  Text,
  Div,
  Input,
  RedStar,
  SpinnerWrapper,
  Spinner,
  Image,
} from "../../StyledComponents";
import { PatientSubTitle, Button, LabelVal } from "./Styles";
import LeftNav from "../../LeftNav";
import TopNav from "../../TopNav";
import CheckEmail from "../../Popup/CheckEmail/CheckEmail";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  GetPatEnrollmentUpdate,
  GetPatientEmail,
  setEmptyMailResponse,
} from "../../../StateManagement/Reducers/PatientState";
import Logo from "../../../Assets/images/Logo.png";
import AlertModal from "../../StyledComponents/AlertModal/AlertModal";
const PatientPortalSetup = () => {
  //-------------------------------------------State and Variables Start-------------------------------------------------------- //

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showCheckEmail, setShowCheckEmail] = useState(false);
  const [modalMsg, setModalMsg] = useState("");
  const [ModalAlerShow, setModalAlerShow] = useState(false);
  const [status, setStatus] = useState("");
  const [disable, setdisable] = useState(false);
  const params = useParams();
  const [enableDone, setEnbaleDone] = useState(false);
  const [Loading, setLoading]=useState(true)
  const patId = params.pid;

  const SingPatDetails = useSelector((state) => state.Patient.SinglePatient);
  const Emailresponse = useSelector((state) => state.Patient.PatientEmail);
 

  const patientmail = SingPatDetails.email;
  //-------------------------------------------State and Variables End-------------------------------------------------------- //

  // ------------------------------------------ Functions Start--------------------------------------------------------- //

  const pageToConsent = () => {
    navigate(`/PatientConsent/${patId}`);
    window.scrollTo(0, 0);
  };
  let AccessMailUrl;

  if (window.location.href.includes("localhost"))
    AccessMailUrl = "http://localhost:8000/InstantSignIn?source=email";
  else {
    let firstDot = window.location.hostname.split(".");
    if (firstDot[0] !== "ehr" && firstDot[0] !== "ehr1")
      AccessMailUrl = `https://${firstDot[0]}.rpm.clientx.me/InstantSignIn?source=email"`;
    else AccessMailUrl = "https://clientx.me/InstantSignIn?source=email";
  }

  const pagetoDemographcis = () => {
    let verifyskip = localStorage.getItem("skipverify");
    dispatch(
      GetPatEnrollmentUpdate({
        id: patId,
        status: verifyskip === "true" ? "7" : "3",
      })
    );
    setModalAlerShow(false);
    if (verifyskip === "true") {
      navigate("/patient");
    } else {
      navigate(`/PatientDemo/${patId}`);
    }
  };

  const sendEmail = () => {
    setdisable(true);
    dispatch(
      GetPatientEmail({
        pid: patId,
        mailid: patientmail,
        MailUrl: AccessMailUrl,
      })
    );
  };

  const closePopup = () => {
    setShowCheckEmail(false);
  };
  // ------------------------------------------ Functions End--------------------------------------------------------- //
  // ------------------------------ useEffect Start----------------------------------------------------------- //

  useEffect(() => {
    if (Emailresponse !== "") {
      if (Emailresponse.includes("success")) {
        setModalAlerShow(true);
        setModalMsg("Email sent Successfully");
        setStatus("success");
        setEnbaleDone(true);
        setTimeout(() => {
          setModalAlerShow(false);
          setModalMsg("");
          setTimeout(() => {
            setShowCheckEmail(true);
          }, 500);
        }, 1500);
      }
      setdisable(false);
      dispatch(setEmptyMailResponse());
    }
    if (Emailresponse !== "") {
      if (Emailresponse.includes("email does not exist")) {
        setModalAlerShow(true);
        setModalMsg("Email does not exist");
        setStatus("retry");

        setTimeout(() => {
          setModalAlerShow(false);
          setModalMsg("");
          setShowCheckEmail(false);
        }, 1500);
      }
      setdisable(false);
      dispatch(setEmptyMailResponse());
    }
    if (Emailresponse !== "") {
      if (Emailresponse.includes("Invalid mail address")) {
        setModalAlerShow(true);
        setModalMsg(
          "The given email ID is invalid. Please check the email ID before entering or enter valid email ID"
        );
        setStatus("retry");

        setTimeout(() => {
          setModalAlerShow(false);
          setModalMsg("");
          setShowCheckEmail(false);
        }, 1500);
      }
      setdisable(false);
      dispatch(setEmptyMailResponse());
    }
  }, [Emailresponse]);

  useEffect(()=>{
    setTimeout(() => {
      setLoading(false)
    }, 500);
  },[])
  // ------------------------------ useEffect End----------------------------------------------------------- //

  return Loading ? (
    <SpinnerWrapper>
      <Spinner></Spinner>
      <Image width="40px" height="40px" position="absolute" src={Logo} />
    </SpinnerWrapper>
  ) : (
    <Container backgroundColor="#F1F5F8">
      <TopNav />
      <LeftNav />
      <Div marginLeft="56px" className="responsive-right">
        <Div padding="24px" backgroundColor="#F1F5F8">
          <Container>
            <PageSubTitle color="#2C7BE5">Patient Enrollment</PageSubTitle>
            <Div
              backgroundColor="#ffffff"
              borderRadius="8px"
              marginTop="24px"
              height="500px"
              // className="consentDivAlign"
            >
              <RadioProgress page={4} />
              <PatientSubTitle margin="108px 20px 20px 29px" fontSize="18px">
                Patient Portal Setup
              </PatientSubTitle>
              <Div display="flex" padding="15px 0 0 37px" visibility="hidden">
                <Div display="inline-grid" padding="0 48px 0 0">
                  <LabelVal>
                    City<RedStar>*</RedStar>
                  </LabelVal>
                  <Input padding="0 0 0 10px" width="232px" height="36px" />
                </Div>
                <Div display="inline-grid" padding="0 48px 0 0">
                  <LabelVal>
                    State<RedStar>*</RedStar>
                  </LabelVal>
                  <Input padding="0 0 0 10px" width="232px" height="36px" />
                </Div>
                <Div display="inline-grid" padding="0 48px 0 0">
                  <LabelVal>
                    Zipcode<RedStar>*</RedStar>
                  </LabelVal>
                  <Input padding="0 0 0 10px" width="232px" height="36px" />
                </Div>
              </Div>
              <Text
                marginLeft="30px"
                fontSize="16px"
                color="rgba(0, 0, 0, 0.5)"
                marginTop="-63px"
                lineHeight="24px"
              >
                The Patient Portal is a place where provider can find you online
                and gives patients and provider a secure way to request and
                reschedule appointments, view live patient data obtained from
                the RPM devices, and even view and pay invoices.
              </Text>
              <Div margin="48px 0 0 0" textAlign="center">
                <Button
                  bgcolor="#fff"
                  color="#2c7be5"
                  border="1px solid #2c7be5"
                  hoverBackColor="rgba(244, 246, 249, 0.75)"
                  activeBackgroundColor="rgba(44, 123, 229, 0.10)"
                  disabled={disable}
                  cursor={disable ? "not-allowed" : "pointer"}
                  onClick={sendEmail}
                >
                  Send Setup Email
                </Button>
              </Div>
              <Div
                display="flex"
                justifyContent="space-between"
                margin="80px 60px 0 60px"
              >
                <Button
                  padding="8px 32px"
                  hoverBackColor="#005FB2"
                  activeBackgroundColor="rgba(44, 123, 229, 0.75)"
                  onClick={pageToConsent}
                >
                  Previous
                </Button>
                <Container>
                  <Button
                    title={!enableDone ? "press setup mail button" : ""}
                    padding="10px 45px"
                    hoverBackColor="#005FB2"
                    activeBackgroundColor="rgba(44, 123, 229, 0.75)"
                    disabled={!enableDone}
                    onClick={pagetoDemographcis}
                  >
                    Done
                  </Button>
                </Container>
              </Div>
            </Div>
          </Container>
        </Div>
      </Div>
      {showCheckEmail && (
        <CheckEmail
          onClose={closePopup}
          email={patientmail}
          pid={patId}
          mailurl={AccessMailUrl}
          disable1={disable}
        />
      )}
      {ModalAlerShow && (
        <AlertModal show={ModalAlerShow} msg={modalMsg} status={status} />
      )}
    </Container>
  );
};
export default PatientPortalSetup;
