import React, { useState, memo, useEffect } from "react";
import {
  Modal,
  ModalContainer,
  ModalTitle,
  Div,
  Input,
  Span,
  ModalHeader,
  ModalFooter,
  Button,
  Label,
  PageSubTitle,
} from "../../../StyledComponents/index";
import { ErrorMessage } from "../../../Patient/BillandInsurance/styles";
import { ModalDialog } from "../../../StyledComponents/AddCard/Styles";
import { CheckBoxLabel, ModalBodyRoles, TogggleCheck } from "../Styles";
import { CloseIcon } from "../Styles";
import permissionList from "../Permissions/PermissionsData";
import {
  GetCustomizeRole,
  GetEditRoleInsert,
  GetInsertRole,
  GetRoleDelete,
  SetEditRoleInsert,
  SetInsertRole,
  SetRoleDelete,
  SetRolesEdit,
} from "../../../../StateManagement/Reducers/SettingState";
import { useDispatch, useSelector } from "react-redux";
import { Decrypt_Value } from "../../../../MiddleWare/EncryptDecrypt";
import AlertModal from "../../../StyledComponents/AlertModal/AlertModal";

const AddRoles = (props) => {
  //-----------------------------State and  Variables Start----------------------------------------------- //
  const dispatch = useDispatch();
  const user_id =
    localStorage.getItem("user_id") &&
    Decrypt_Value(localStorage.getItem("user_id"), "vozorpm");
  const [nameserr, setnameerr] = useState(false);
  const [descriptionserr, setdescriptionerr] = useState(false);
  const [rolename, setRoleName] = useState("");
  const [description, setDescription] = useState("");
  const [dis, setDis] = useState(false);
  const tempData = {
    dashboard_access: false,
    pat_enroll: false,
    pat_update: false,
    export_tool: false,
    device_assign: false,
    encounter_add: false,
    encounter_visit: false,
    encounter_sumry: false,
    feesheet: false,
    live_access: false,
    device_inventory: false,
    treat_plan: false,
    profile_edit: false,
    message_access: false,
    notifics: false,
    enc_lock: false,
    notes_lock: false,
    user_add: false,
    service_access: false,
    docs_access: false,
    security_access: false,
    vitals_access: false,
    graph_access: false,
  };

  const [chkBoxData, setChkBoxData] = useState(tempData);
  const permissionval = useSelector((state) => state.Setting.RolesEdit);
  const InsertRole = useSelector((state) => state.Setting.InsertRole);
  const EditRole = useSelector((state) => state.Setting.EditRoleInsert);

  const GroupId = useSelector((state) => state.Setting.GroupId);
  const [ModalAlerShow, setModalAlerShow] = useState(false);
  const [modalMsg, setModalMsg] = useState("");
  const [status, setStatus] = useState("");
  const [accountType, setAccountType] = useState(3);
  const [trial, setTrial] = useState(true);
  const [checkName, setCheckName] = useState("");
  const [nameChanged, setNameChanged] = useState(false);
  var defaultRoles = [
    "Administrators",
    "Care Coordinator",
    "Staff",
    "Provider",
  ];
  const ProvWithRole = useSelector((state) => state.Setting.ProvWithRole);
  //-----------------------------State and  Variables End----------------------------------------------- //
  //-----------------------------Function Start------------------------------------------------- //

  const NameChange = (e) => {
    setRoleName(e.target.value);
    if (props.acl_id !== 0) {
      setNameChanged(true);
    }
    setnameerr(false);
  };
  const DescriptionChange = (e) => {
    setDescription(e.target.value);
    setdescriptionerr(false);
  };

  const HandleSaveProvider = () => {
    setDis(true);
    let err = 0;
    if (
      (defaultRoles.includes(rolename) && props.acl_id === 0) ||
      (defaultRoles.includes(rolename) && nameChanged)
    ) {
      setnameerr(true);
      setStatus("failure");
      setModalMsg("This role name is already used for default roles");
      setModalAlerShow(true);
      setTimeout(() => {
        setModalAlerShow(false);
        setModalMsg("");
      }, 1500);
      err = 2;
    }

    if (rolename === "") {
      setnameerr(true);
      err = 2;
    }

    if (description === "") {
      setdescriptionerr(true);
      err = 2;
    }
    if (err > 0) {
      setDis(false);
      return false;
    }

    var permis = {
      dashboard_access: chkBoxData["dashboard_access"]
        ? "dashboard_access"
        : "",
      pat_enroll: chkBoxData["pat_enroll"] ? "pat_enroll" : "",
      pat_update: chkBoxData["pat_update"] ? "pat_update" : "",
      export_tool: chkBoxData["export_tool"] ? "export_tool" : "",
      device_assign: chkBoxData["device_assign"] ? "device_assign" : "",
      encounter_add: chkBoxData["encounter_add"] ? "encounter_add" : "",
      encounter_visit: chkBoxData["encounter_visit"] ? "encounter_visit" : "",
      encounter_sumry: chkBoxData["encounter_sumry"] ? "encounter_sumry" : "",
      feesheet: chkBoxData["feesheet"] ? "feesheet" : "",
      live_access: chkBoxData["live_access"] ? "live_access" : "",
      device_inventory: chkBoxData["device_inventory"]
        ? "device_inventory"
        : "",
      treat_plan: chkBoxData["treat_plan"] ? "treat_plan" : "",
      profile_edit: chkBoxData["profile_edit"] ? "profile_edit" : "",
      message_access: chkBoxData["message_access"] ? "message_access" : "",
      notifics: chkBoxData["notifics"] ? "notifics" : "",
      permission_access: chkBoxData["permission_access"]
        ? "permission_access"
        : "",
      enc_lock: chkBoxData["enc_lock"] ? "enc_lock" : "",
      notes_lock: chkBoxData["notes_lock"] ? "notes_lock" : "",
      user_add: chkBoxData["user_add"] ? "user_add" : "",
      service_access: chkBoxData["service_access"] ? "service_access" : "",
      docs_access: chkBoxData["docs_access"] ? "docs_access" : "",
      security_access: chkBoxData["security_access"] ? "security_access" : "",
      vitals_access: chkBoxData["vitals_access"] ? "vitals_access" : "",
      graph_access: chkBoxData["graph_access"] ? "graph_access" : "",
    };
    const articles = {
      fname: rolename,
      desp: description,
      permis: permis,
      uid: user_id,
      account_type: accountType,
      role_name: rolename,
      trial: trial,
      owner_id: null,
    };

    if (props.aro_enable === true) {
      dispatch(GetCustomizeRole({ aro_id: props.aro_id, articles: articles }));
    } else {
      if (props.acl_id === 0) {
        dispatch(GetInsertRole(articles));
      } else {
        if (
          defaultRoles.includes(rolename) &&
          !GroupId[1].includes(props.group_id)
        ) {
          dispatch(GetInsertRole(articles));
        } else {
          dispatch(
            GetEditRoleInsert({ acl_id: props.acl_id, articles: articles })
          );
        }
      }
    }
  };

  const handleClose = () => {
    props.close();
    setdescriptionerr(false);
    setnameerr(false);
    setRoleName("");
    setDescription("");
    dispatch(SetRolesEdit([]));
    setChkBoxData(tempData);
    setNameChanged(false);
    setDis(false);
  };
  const handleDelete = (id, grpid) => {
    const found = ProvWithRole.find((obj) => {
      return obj.group_id === grpid;
    });
    if (!found) {
      if (
        window.confirm(
          "Are you sure you want to delete this role from the database?"
        )
      ) {
        dispatch(GetRoleDelete({ id: id, grpid: grpid }));
        handleClose()
      }
    } else {
      window.alert("Role is being used and cannot be deleted");
    }
  };
  const handleContainerClick = (e) => {
    e.stopPropagation();
  };

  const chkBoxChgVal = (name) => {
    setChkBoxData({ ...chkBoxData, [name]: !chkBoxData[name] });
  };

  //-----------------------------Function End------------------------------------------------- //
  // ------------------------------ UseEffect Start-----------------------------------------------------------//

  useEffect(() => {
    if (permissionval.length > 0) {
      setRoleName(permissionval[0].name);
      setCheckName(permissionval[0].name);
      setDescription(permissionval[0].note);
      if (permissionval[1].length > 0) {
        permissionval[1].forEach((item) => {
          const { value } = item;
          chkBoxData[value] = true;
        });
      }
    }
  }, [permissionval]);

  useEffect(() => {
    if (InsertRole === "success" || EditRole === "success") {
      setStatus("success");
      if (InsertRole === "success") setModalMsg("Role Inserted Successfully");
      else if (EditRole === "success") setModalMsg("Role Updated Successfully");
      setModalAlerShow(true);
      setTimeout(() => {
        setModalAlerShow(false);
        setModalMsg("");
        dispatch(SetInsertRole(""));
        dispatch(SetEditRoleInsert(""));
        dispatch(SetRoleDelete(""));
        handleClose();
      }, 1500);
    }
    if (
      InsertRole === "Name is already exists" ||
      EditRole === "Name is already exists"
    ) {
      setStatus("failure");
      setModalMsg("Name is already exists");
      setModalAlerShow(true);
      setTimeout(() => {
        setModalAlerShow(false);
        setModalMsg("");
        dispatch(SetInsertRole(""));
        dispatch(SetEditRoleInsert(""));
        dispatch(SetRoleDelete(""));
        setDis(false);
      }, 1500);
    }
  }, [InsertRole, EditRole]);
  // ------------------------------ UseEffect End-----------------------------------------------------------//
  return (
    <>
      <Modal show={props.show} justifyContent="center" onClick={handleClose}>
        <ModalContainer
          position="relative"
          justifyContent="center"
          borderRadius="8px"
          onClick={handleContainerClick}
        >
          <ModalDialog width="505px">
            <ModalHeader padding="12px 0px 8px 23px">
              <ModalTitle
                fontFamily="'Open Sans',sans-serif"
                fontWeight="600"
                fontSize="18px"
                lineHight="normal"
                color="#2E2E2E"
              >
                {props.acl_id === 0 ? "New Role" : "Edit Role"}
              </ModalTitle>

              <Button
                border="none"
                marginLeft="361px"
                marginTop="9px"
                background="white"
                cursor="pointer"
                onClick={handleClose}
              >
                <CloseIcon height="24px" width="24px" color="#2E2E2E" />
              </Button>
            </ModalHeader>
            <ModalBodyRoles
              padding="0px 20px 15px 20px"
              maxHeight="450px"
              overflow="auto"
            >
              <Div margin="16px 0px 0px 0px">
                <Label
                  size="14px"
                  lineHeight="normal"
                  weight="400"
                  fontFamily="'Open Sans',sans-serif"
                >
                  Name
                </Label>
                <Input
                  placeholder="Name of the Role"
                  width="100%"
                  height="38px"
                  name="status_View"
                  background="#FFFFFF"
                  fontSize="14px"
                  fontFamily="'Open Sans',sans-serif"
                  border="1px solid rgba(46, 46, 46, 0.25)"
                  borderRadius="4px"
                  color="#2E2E2E"
                  pl="10px"
                  paddingLeft="10px"
                  paddingTop="2px !important"
                  margin="8px 0px 0px 0px"
                  value={rolename}
                  onChange={NameChange}
                  style={
                    nameserr
                      ? { border: "1px solid red", borderRadius: "5px" }
                      : {}
                  }
                  readOnly={
                    (String(accountType) === "0" && trial === false) ||
                    String(accountType) === "1" ||
                    (props.acl_id !== 0 && defaultRoles.includes(checkName))
                  }
                ></Input>
                {nameserr && <ErrorMessage>Enter a valid name</ErrorMessage>}
              </Div>

              <Div margin="16px 0px 0px 0px">
                <Label
                  size="14px"
                  lineHeight="normal"
                  weight="400"
                  fontFamily="'Open Sans',sans-serif"
                >
                  Description
                </Label>
                <Input
                  placeholder="Description of the role"
                  width="100%"
                  height="38px"
                  name="status_View"
                  background="#FFFFFF"
                  fontSize="14px"
                  fontFamily="'Open Sans',sans-serif"
                  border="1px solid rgba(46, 46, 46, 0.25)"
                  borderRadius="4px"
                  color="#2E2E2E"
                  pl="10px"
                  paddingTop="2px !important"
                  paddingLeft="10px"
                  margin="8px 0px 0px 0px"
                  value={description}
                  onChange={DescriptionChange}
                  style={
                    descriptionserr
                      ? { border: "1px solid red", borderRadius: "5px" }
                      : {}
                  }
                ></Input>
                {descriptionserr && (
                  <ErrorMessage> Enter a valid description</ErrorMessage>
                )}
              </Div>

              <PageSubTitle
                fontFamily="'Open Sans',sans-serif"
                fontWeight="400"
                fontSize="18px"
                lineHight="normal"
                color="#2E2E2E"
                marginTop="16px"
              >
                Permissions
              </PageSubTitle>
              {permissionList.map((ele, i) => {
                return (
                  <Div
                    borderBottom="1px solid rgba(46, 46, 46, 0.25)"
                    marginTop="26px"
                    flexCenterBetween
                    width="100%"
                    height="25px"
                    key={i}
                  >
                    <Div fontSize="14px" color="#2E2E2E">
                      {ele.permission}
                    </Div>
                    <Div position="relative">
                      <TogggleCheck
                        checked={chkBoxData[ele.key]}
                        type="checkbox"
                        onClick={(e) => {
                          chkBoxChgVal(ele.key);
                        }}
                      />
                      <CheckBoxLabel htmlFor="checkbox" />
                    </Div>
                  </Div>
                );
              })}
            </ModalBodyRoles>

            <ModalFooter display="flex" padding="16px 6px 15px 0px">
              <Button
                fontSize="14px"
                fontWeight="600"
                borderRadius="4px"
                border="1px solid red"
                padding="9px 27px 8px 28px"
                background="#FFFFFF"
                color="red"
                marginLeft="20px"
                cursor="pointer"
                onClick={() =>
                  props.items?.disable ||
                  defaultRoles.includes(props.items?.name) ||
                  props.items?.group_id === "11"
                    ? ""
                    : handleDelete(props.items?.acl_id_m, props.items?.group_id)
                }
                hoverBackColor="rgba(244, 246, 249, 0.75)"
                activeBackgroundColor="rgba(44, 123, 229, 0.10)"
                style={{
                  display:
                    props.items?.disable ||
                    defaultRoles.includes(props.items?.name) ||
                    props.items?.group_id === "11"
                      ? "none"
                      : "block",
                }}
              >
                <Span fontSize="14px" fontWeight="600">
                  Delete
                </Span>
              </Button>

              <Button
                fontSize="14px"
                fontWeight="600"
                borderRadius="4px"
                border="1px solid #2C7BE5"
                padding="9px 27px 8px 28px"
                background="#FFFFFF"
                color="#2C7BE5"
                marginLeft="auto"
                marginRight="16px"
                cursor="pointer"
                onClick={handleClose}
                hoverBackColor="rgba(244, 246, 249, 0.75)"
                activeBackgroundColor="rgba(44, 123, 229, 0.10)"
              >
                <Span fontSize="14px" fontWeight="600">
                  Cancel
                </Span>
              </Button>
              <Button
                type="submit"
                borderRadius="4px"
                border="1px solid #2C7BE5"
                padding="9px 34px 8px 34px"
                background="#2C7BE5"
                color="#FFFFFF"
                marginRight="16px"
                cursor="pointer"
                onClick={HandleSaveProvider}
                disabled={dis}
                isDisabled={dis}
                hoverBackColor="#005FB2"
                activeBackgroundColor="rgba(44, 123, 229, 0.75)"
              >
                <Span
                  fontSize="14px"
                  fontWeight="600"
                  fontFamily="'Open Sans', sans-serif"
                >
                  Save
                </Span>
              </Button>
            </ModalFooter>
          </ModalDialog>
        </ModalContainer>
      </Modal>
      {ModalAlerShow && (
        <AlertModal show={ModalAlerShow} msg={modalMsg} status={status} />
      )}
    </>
  );
};

export default memo(AddRoles);
