import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import RadioProgress from "./RadioProgress";
import {
  PageSubTitle,
  Container,
  RedStar,
  Text,
  Div,
  Input,
  SpinnerWrapper,
  Image,
  Spinner,
} from "../../StyledComponents";
import Logo from "../../../Assets/images/Logo.png";

import { LabelVal, Button, Div1 } from "./Styles";
import { PatientSubTitle, UL, LI, CheckBox, SpanVal } from "./Styles";
import LeftNav from "../../LeftNav";
import TopNav from "../../TopNav";
import {
  GetPatEnrollmentUpdate,
  GetSinglePatDetail,
} from "../../../StateManagement/Reducers/PatientState";
import moment from "moment";
const PatientConsent = () => {
  //-------------------------------------------State and Variables Start-------------------------------------------------------- //

  const navigate = useNavigate();
  const params = useParams();
  const patId = params.pid;
  const dispatch = useDispatch();

  const [checkBxOne, setCheckBxOne] = useState(false);
  const [checkBxTwo, setCheckBxTwo] = useState(false);
  const [Spinn, setSpinn] = useState(true);
  const currentDate = moment().format("D.M.YYYY");
  //-------------------------------------------State and Variables End-------------------------------------------------------- //

  // ------------------------------------------ Functions Start--------------------------------------------------------- //

  const checkBxOneFunc = () => {
    if (checkBxOne) {
      setCheckBxOne(false);
    } else {
      setCheckBxOne(true);
    }
  };
  const checkBxTwoFunc = () => {
    if (checkBxTwo) {
      setCheckBxTwo(false);
    } else {
      setCheckBxTwo(true);
    }
  };

  const pageToEligibility = () => {
    navigate(`/PatientEligibility/${patId}`);
    window.scrollTo(0, 0);
  };

  const pageToPortalSetup = () => {
    let verifyskip = localStorage.getItem("skipverify");

    dispatch(
      GetPatEnrollmentUpdate({
        id: patId,
        status: verifyskip === "true" ? "6" : "2",
      })
    );
    navigate(`/PatientPortalSetup/${patId}`);
  };

  // ----------------------------------------- Functions End--------------------------------------------------------- //

  // ----------------------------------------- useEffect Start--------------------------------------------------------- //

  useEffect(() => {
    dispatch(GetSinglePatDetail(patId));
    setTimeout(() => {
      setSpinn(false);
    }, 500);
  }, []);

  // ----------------------------------------- useEffect End--------------------------------------------------------- //

  return Spinn ? (
    <SpinnerWrapper>
      <Spinner></Spinner>
      <Image width="40px" height="40px" position="absolute" src={Logo} />
    </SpinnerWrapper>
  ) : (
    <Container backgroundColor="#F1F5F8">
      <TopNav />
      <LeftNav />
      <Div marginLeft="56px" className="responsive-right">
        <Div padding="24px" backgroundColor="#F1F5F8">
          <Container>
            <PageSubTitle color="#2C7BE5">Patient Enrollment</PageSubTitle>
            <Div1
              backgroundColor="#ffffff"
              borderRadius="8px"
              height="867px"
              className="resipad consentDivAlign "
              marginTop="24px"
            >
              <RadioProgress page={3} />
              <PatientSubTitle margin="108px 20px 20px 29px" fontSize="18px">
                Patient Consent
              </PatientSubTitle>
              <Div display="flex" padding="15px 0 0 37px" visibility="hidden">
                <Div display="inline-grid" padding="0 48px 0 0">
                  <LabelVal>
                    City<RedStar>*</RedStar>
                  </LabelVal>
                  <Input padding="0 0 0 10px" width="232px" height="36px" />
                </Div>
                <Div display="inline-grid" padding="0 48px 0 0">
                  <LabelVal>
                    State<RedStar>*</RedStar>
                  </LabelVal>
                  <Input padding="0 0 0 10px" width="232px" height="36px" />
                </Div>
                <Div display="inline-grid" padding="0 48px 0 0">
                  <LabelVal>
                    Zipcode<RedStar>*</RedStar>
                  </LabelVal>
                  <Input padding="0 0 0 10px" width="232px" height="36px" />
                </Div>
              </Div>
              <Div margin="-63px 40px 0 33px">
                <Text fontSize="14px">
                  John Doe, 20/10/1988, hereby provide my consent to participate
                  in the Remote Patient Monitoring (RPM) program under the
                  following terms and conditions:
                </Text>
                <UL>
                  <LI>
                    I acknowledge that I have received the RPM device(s) for my
                    personal use in this program.
                  </LI>
                  <LI>
                    I understand and agree that the RPM device(s) provided to me
                    are entirely assigned to me, and I am the only authorized
                    user. I will use the equipment strictly for my personal
                    health monitoring and will not use it for any other purpose.
                  </LI>
                  <LI>
                    I acknowledge that I can participate in the RPM program with
                    only one medical provider at a time.
                  </LI>
                  <LI>
                    I understand that I am responsible for any applicable
                    co-payment for RPM services, as per the guidelines of
                    Medicare Part B service.
                  </LI>
                  <LI>
                    The equipment will not be tampered with by me and
                    acknowledge that I am liable for any associated fees
                    resulting from the misuse of the RPM device(s).
                  </LI>
                  <LI>
                    I understand that the collected data will be reviewed as
                    part of routine care and that the RPM program is not an
                    emergency response unit. In the case of immediate medical
                    emergencies, I will call 911. Any unusual changes in values
                    or symptoms should be reported to my healthcare provider or
                    the assigned answering services promptly.
                  </LI>
                  <LI>
                    I acknowledge that my data will be transmitted
                    electronically in a safe and secure manner to protect my
                    privacy and confidentiality.
                  </LI>
                  <LI>
                    This consent remains valid as long as I possess the RPM
                    equipment/device.
                  </LI>
                  <LI>
                    I have the right to withdraw my consent and discontinue my
                    participation in the RPM program at any time. I can do so by
                    returning the device(s) to the office or by contacting the
                    healthcare provider's office directly.
                  </LI>
                  <LI>
                    I will make my best effort to collect my data daily or as
                    instructed by my healthcare provider.
                  </LI>
                  <LI>
                    I understand that I may be contacted by phone as needed to
                    review and discuss my results and progress in the RPM
                    program.
                  </LI>
                </UL>
                <Text fontSize="14px">
                  I have read and comprehended the information presented above
                  and hereby provide my consent to engage in the Remote Patient
                  Monitoring initiative as described.
                </Text>
              </Div>
              <Div margin="26px 0 0 35px">
                <CheckBox
                  margin="2px 0 0 0"
                  onChange={checkBxOneFunc}
                  checked={checkBxOne}
                ></CheckBox>
                <Text fontSize="14px" paddingLeft="23px" fontWeight="600">
                  Patient provided verbal consent to access data
                  <RedStar>*</RedStar>
                </Text>
                <CheckBox
                  margin="18px 0 0 0"
                  onChange={checkBxTwoFunc}
                  checked={checkBxTwo}
                ></CheckBox>
                <Div display="flex" justifyContent="space-between">
                  <Text
                    fontSize="14px"
                    paddingLeft="10px"
                    fontWeight="600"
                    marginTop="16px"
                  >
                    Patient provided verbal consent to program enrollment
                    <RedStar>*</RedStar>
                  </Text>
                  <Div margin="15px 150px 0 0" display="flex">
                    <Text marginLeft="15px" className="flexWrapDt">
                      Date :
                    </Text>
                    <SpanVal className="flexWrapSpan">{currentDate}</SpanVal>
                  </Div>
                </Div>
              </Div>
              <Div>
                <Div
                  display="flex"
                  justifyContent="space-between"
                  margin="80px 60px 0 60px"
                >
                  <Button
                    padding="8px 32px"
                    hoverBackColor="#005FB2"
                    activeBackgroundColor="rgba(44, 123, 229, 0.75)"
                    onClick={pageToEligibility}
                  >
                    Previous
                  </Button>

                  <Container>
                    <Button
                      padding="10px 45px"
                      disabled={!checkBxOne || !checkBxTwo}
                      title={
                        !checkBxOne || !checkBxTwo
                          ? "please accept all terms and conditions"
                          : ""
                      }
                      hoverBackColor="#005FB2"
                      activeBackgroundColor="rgba(44, 123, 229, 0.75)"
                      onClick={pageToPortalSetup}
                    >
                      Next
                    </Button>
                  </Container>
                </Div>
              </Div>
            </Div1>
          </Container>
        </Div>
      </Div>
    </Container>
  );
};
export default PatientConsent;
